import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Collapse,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import { isMobile, isTablet } from 'react-device-detect';
import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import _ from 'lodash';
import Header from './Header';
import { useData } from '../StateProvider';
import { Link, withRouter } from 'react-router-dom';
import './sidebar.scss';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: { display: 'none' },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: theme.palette.primary.main,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) - 1,
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  toolbar: {
    background: '#ccccd2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0),
  },
  menuIcon: {
    width: 22,
  },
  drawerIcon: {
    width: 18,
  },
  heading: {
    fontWeight: 'normal',
    marginLeft: theme.spacing(2),
    borderBottom: '1px solid #eaeaea',
  },
  nested: {
    background: '#f5f5f5',
    paddingLeft: theme.spacing(4),

    '&:hover': {
      background: '#c9cbcc',
    },
  },
  content: {
    flexGrow: 1,
    width: 'calc(100% - 65px)',
    padding: theme.spacing(2),
    background: '#ff00ff',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  parentIcon: {
    minWidth: 0,
    paddingRight: '6px',
  },
  parentMenu: {
    background: '#ffffff',
  },
  parentBox: {
    padding: 0,
  },
}));

const SideBar = ({ toggleDrawer, setToggleDrawer, location }) => {
  const sidebarState = JSON.parse(localStorage.getItem('sidebarState')) || {};
  const classes = useStyles();
  const {
    state: { user },
  }: any = useData();
  const [open, setOpen] = useState(sidebarState);
  const pathnames = location.pathname.split('/').filter((x) => x);
  const [emailOpen, setEmailOpen] = useState(false);
  const [fullScreen, setFullScreen] = useState(isMobile || isTablet);
  const handleToggleDrawer = () => {
    setToggleDrawer(!toggleDrawer);
  };

  const listItems = () => {
    if (user) {
      const sections = [];
      user.role.sideBar.forEach((item) => {
        if (!sections.includes(item.sectionName)) {
          sections.push(item.sectionName);
        }
      });

      return sections.map((section) => {
        const lists = user.role.sideBar.filter(
          (list) => list.sectionName === section,
        );
        const items = lists.map((item) => item);
        return { section, items };
      });
    }
  };

  const handleCollapse = (section) => {
    let tempdata = { ...open };
    tempdata[section] = !tempdata[section] || false;
    setOpen(tempdata);
    localStorage.setItem('sidebarState', JSON.stringify(tempdata));
  };

  const handleCloseEmail = () => {
    setEmailOpen(false);
  };

  const handleCreateEmail = () => {
    setEmailOpen(true);
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <Header toggleDrawer={handleToggleDrawer} />
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: toggleDrawer,
            [classes.drawerClose]: !toggleDrawer,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: toggleDrawer,
              [classes.drawerClose]: !toggleDrawer,
            }),
          }}>
          <Toolbar />
          <div className={classes.toolbar}>
            <IconButton onClick={handleToggleDrawer}>
              {toggleDrawer ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>

          <div
            className={clsx(classes.drawerContainer, {
              [classes.hide]: !toggleDrawer,
            })}>
            <List className={classes.parentBox}>
              {user &&
                listItems().map((listItem, i) => {
                  return (
                    <React.Fragment key={i}>
                      <ListItem
                        className={classes.parentMenu}
                        button
                        onClick={() => handleCollapse(listItem.section)}>
                        <ListItemText primary={listItem.section} />
                        {open[listItem.section] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={open[listItem.section]}
                        timeout='auto'
                        unmountOnExit>
                        <List component='div' disablePadding>
                          {listItem.items.map((item, j) => (
                            <Link
                              key={j}
                              to={`/${_.kebabCase(_.lowerCase(item.name))}`}>
                              <ListItem
                                button
                                selected={pathnames.includes(
                                  _.kebabCase(_.lowerCase(item.name)),
                                )}
                                className={`${classes.nested} custom-link`}>
                                <ListItemText
                                  className='link-text'
                                  primary={item.name}
                                />
                              </ListItem>
                            </Link>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  );
                })}
            </List>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default withRouter(SideBar);
