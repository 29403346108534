import { useContext } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { theme } from './constants/AppConfig';
import { useData } from './StateProvider';
import {
  ForgetPassword,
  Login,
  PasswordSetup,
  ResetPassword,
} from './Pages/Auth';
import { SERVICES } from './routes/common';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './Pages/Dashboard';
import Brands from './Pages/Brands';
import Users from './Pages/Users';
import ErrorBoundries from './components/ErrorBoundries';
import GlobalRoles from './Pages/Roles';
import Services from './Pages/Services/index';
import { Profile } from './routes/auth';
import UserProfile from './Pages/Profile/index';
import CreateRoles from './Pages/Roles/CreateRoles';
import FormBuilder from './Pages/FormBuilder';
import CreateFormBuilder from './Pages/FormBuilder/CreateFormBuilder';
import MasterResource from './Pages/MasterResource';
import CreateMasterResource from './Pages/MasterResource/CreateMasterBuilder';
import Project from './Pages/Project';
import Board from './Pages/Project/Board';
import Roadmap from './Pages/Project/Roadmap';
import Activity from './Pages/Activity';
import BrandDetailPage from './Pages/Brands/BrandDetailPage';
import NotFound from './Pages/NotFound';
import { brandDetailPage } from './routes/brand';
import UserDetailsPage from './Pages/Users/UserDetailsPage/index';
import UpdateRoles from './Pages/Roles/UpdateRoles';
import Admins from './Pages/Admins';
import routes from './components/Helpers/Routes';
import CustomToast from './components/Helpers/CustomToast';
import { CustomToastContext } from './StateProvider/CustomToastContext/CustomToastContext';
import EcommerceSetup from './Pages/EcommerceSetup';
import BulkEmail from './Pages/BulkEmail';
import MasterPdfTemplate from './Pages/MasterPdfTemplate';
import CreatePdfTemplateBuilder from './Pages/MasterPdfTemplate/CreatePdfTemplateBuilder';
import BrandBackup from './Pages/brandBackup';

function App() {
  const toast = useContext(CustomToastContext);
  const location = useLocation();

  const {
    state: { user },
  }: any = useData();

  const conditionalRedirect = (Comp, location) => {
    return !user ? (
      <Comp />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    );
  };

  return (
    <ErrorBoundries>
      <ThemeProvider theme={theme}>
        <AnimatePresence initial={false} exitBeforeEnter>
          <Switch location={location} key={location.key}>
            <Route
              exact
              path={routes.login.path}
              render={({ location }) => conditionalRedirect(Login, location)}
            />
            <Route
              exact
              path={routes.createPassword.path}
              render={({ location }) =>
                conditionalRedirect(PasswordSetup, location)
              }
            />
            <Route
              exact
              path={routes.forgetPassword.path}
              render={({ location }) =>
                conditionalRedirect(ForgetPassword, location)
              }
            />
            <Route
              exact
              path={routes.resetPassword.path}
              render={({ location }) =>
                conditionalRedirect(ResetPassword, location)
              }
            />
            <PrivateRoute exact path='/'>
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute exact path={routes.admins.path}>
              <Admins />
            </PrivateRoute>
            <PrivateRoute exact path={routes.brand.path}>
              <Brands />
            </PrivateRoute>
            <PrivateRoute exact path={`${routes.users.path}/:id`}>
              <UserDetailsPage />
            </PrivateRoute>
            <PrivateRoute exact path={`${routes.users.path}`}>
              <Users />
            </PrivateRoute>
            <PrivateRoute exact path={`${brandDetailPage.path}/:id`}>
              <BrandDetailPage />
            </PrivateRoute>
            <PrivateRoute exact path={`${routes.globalRoles.path}`}>
              <GlobalRoles />
            </PrivateRoute>
            <PrivateRoute exact path={SERVICES.path}>
              <Services />
            </PrivateRoute>
            <PrivateRoute exact path={Profile.path}>
              <UserProfile />
            </PrivateRoute>
            <PrivateRoute exact path={`${routes.globalRoles.path}/new`}>
              <CreateRoles />
            </PrivateRoute>
            <PrivateRoute exact path={`${routes.globalRoles.path}/:id`}>
              <UpdateRoles />
            </PrivateRoute>
            <PrivateRoute exact path={routes.formBuilder.path}>
              <FormBuilder />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`${routes.formBuilder.path}${routes.formBuilderResource.path}`}>
              <CreateFormBuilder />
            </PrivateRoute>
            <PrivateRoute exact path={routes.masterResource.path}>
              <MasterResource />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`${routes.masterResource.path}/:resourceId`}>
              <CreateMasterResource />
            </PrivateRoute>
            <PrivateRoute exact path={routes.masterPdfTemplate.path}>
              <MasterPdfTemplate />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`${routes.masterPdfTemplate.path}/:resourceId`}>
              <CreatePdfTemplateBuilder />
            </PrivateRoute>
            <PrivateRoute exact path={`${routes.project.path}`}>
              <Project />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`${routes.project.path}${routes.projectBoard.path}`}>
              <Board />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`${routes.project.path}${routes.projectRoadmap.path}`}>
              <Roadmap />
            </PrivateRoute>
            <PrivateRoute exact path={`${routes.activity.path}`}>
              <Activity />
            </PrivateRoute>
            <PrivateRoute exact path={`${routes.ecommerceSetup.path}`}>
              <EcommerceSetup />
            </PrivateRoute>
            <PrivateRoute exact path={`${routes.bulkEmail.path}`}>
              <BulkEmail />
            </PrivateRoute>
            <PrivateRoute exact path={`${routes.brandBackup.path}`}>
              <BrandBackup />
            </PrivateRoute>
            {/* {This will be always at the bottom} */}
            <Route component={NotFound} />
          </Switch>
        </AnimatePresence>

        {toast?.toastConfig?.open && (
          <CustomToast
            type={toast.toastConfig.type}
            message={toast.toastConfig.message}
            open={toast.toastConfig.open}
            close={() => {
              toast.setToastConfig({ open: false });
            }}
          />
        )}
      </ThemeProvider>
    </ErrorBoundries>
  );
}

export default App;
