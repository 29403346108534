import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from './StateProvider';
import { CustomToastProvider } from './StateProvider/CustomToastContext/CustomToastContext';
import './styles/common-styles.scss';
import './styles/material-component.scss';
import { init } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { MsalProvider } from "@azure/msal-react";
import AzureInstance from "./AzureInstance";
import {version} from "../package.json"

if (process.env.REACT_APP_ENV !== 'local') {
  init({
    environment: process.env.REACT_APP_ENV,
    release: version,
    dsn: "https://6ca7702c20b8484b93173a55877a25ec@o718098.ingest.sentry.io/5780589",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider>
        <CustomToastProvider>
          <MsalProvider instance={AzureInstance}>
            <App />
          </MsalProvider>
        </CustomToastProvider>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
