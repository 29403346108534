import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import axiosInstance from '../../axios/axiosInstance';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import CustomDialogContent from '../../components/CustomDialog/CustomDialogContent';
import Loader from '../../components/Loader';
import SearchBox from '../../components/Helpers/SearchBox';
import CustomDialogFooter from '../../components/CustomDialog/CustomDialogFooter';
import routes from '../../components/Helpers/Routes';

const AssignBrandsDialog = ({
  onSuccess,
  handleClose,
  resourceId,
}) => {

  const history = useHistory();
  const toastConfig = useContext(CustomToastContext);
  const [brands, setBrands] = useState([]);
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [isAssigning, setAssigning] = useState(false);
  const [search, setSearch] = useState('');
  const [brandsConst, setBrandsConst] = useState([]);

  useEffect(() => {
    setLoadingBrands(true);
    axiosInstance().get(`/sa-brand`).then(({ data: { data } }) => {
      setBrands(data.map((obj) => ({ ...obj, isChecked: false })));
      setBrandsConst(data.map((obj) => ({ ...obj, isChecked: false })));
      setLoadingBrands(false);
    })
      .catch((error) => {
        setLoadingBrands(false);
        toastConfig.setToastConfig(error);
      });
  }, []);

  const handleAssignBrands = async () => {
    if (selectedBrands.length) {
      setAssigning(true);
      const dataObj = {
        resourceId: resourceId,
        brands: selectedBrands,
      };

      await axiosInstance().put(`/master-resource/sync`, dataObj).then(() => {
        setAssigning(false);
        toastConfig.setToastConfig({
          message: 'Resource Updated Successfully',
          type: 'success',
          open: true,
        });
        onSuccess();
      })
        .catch((error) => {
          setAssigning(false);
          toastConfig.setToastConfig(error);
        });
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    let result = [];
    result = brandsConst.filter((data) => {
      return (
        data.companyName.toLowerCase().search(value.toLowerCase()) !== -1 ||
        data.address.toLowerCase().search(value.toLowerCase()) !== -1
      );
    });
    setBrands(result);
  };

  return (
    <Dialog fullWidth open={true} onClose={handleClose} aria-labelledby='assign-brand-dialog'>
      <CustomDialogHeader title='Applied resource changes to brands' onClose={handleClose} />
      <CustomDialogContent>
        {loadingBrands ? (
          <Loader text='Loading Brands' />
        ) : brandsConst.length ? (
          <>
            <List style={{ padding: 0 }}>
              <ListItem divider>
                <Grid container>
                  <Grid item xs={12} md={6} sm={6} className='d-flex align-items-center gap-1'>
                    <FormControl component='fieldset'>
                      <FormControlLabel
                        value='top'
                        className='m-0'
                        control={
                          <Checkbox
                            edge='start'
                            onChange={(e) => {
                              brands.forEach((brand) => (brand.isChecked = e.target.checked));
                              setSelectedBrands(brands.filter((r) => r.isChecked).map((obj) => obj._id));
                            }}
                            checked={brands.every((x) => x.isChecked)}
                            inputProps={{
                              'aria-labelledby': `checkbox-list-label-select-all`,
                            }}
                          />
                        }
                        label='Select all brands'
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} className='d-flex align-items-center gap-1'>
                    <SearchBox
                      onSearch={handleSearch}
                      searchbox='terms_header_search_bar'
                      width='300px'
                      value={search}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              {brands?.map((user) => (
                <ListItem divider key={user._id}>
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      onChange={(e) => {
                        user.isChecked = e.target.checked;
                        setSelectedBrands(brands.filter((r) => r.isChecked).map((obj) => obj._id));
                      }}
                      checked={user.isChecked}
                      inputProps={{
                        'aria-labelledby': `checkbox-list-label-${user._id}`,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={user.companyName} />
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <Typography>No Brands to show.</Typography>
        )}
      </CustomDialogContent>
      <CustomDialogFooter>
        <Button
          disabled={isAssigning}
          onClick={() => {
            handleClose();
            history.push(routes.masterResource.path);
          }}
          color='primary'
          size='small'>
          Cancel
        </Button>
        <Button
          disabled={!selectedBrands.length || isAssigning}
          onClick={() => {
            handleAssignBrands();
          }}
          color='primary'
          size='small'
          variant='contained'>
          {isAssigning ? <CircularProgress size={22} /> : 'Save'}
        </Button>
      </CustomDialogFooter>
    </Dialog>
  );
};

export default AssignBrandsDialog;
