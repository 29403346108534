import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Loader from '../Loader';
import CustomDialogHeader from '../CustomDialog/CustomDialogHeader';

const AlertDialog = (props) => {
  const { message, open, onYes, onNo } = props;
  const [showLoading, setShowLoading] = useState(false);

  const onSuccess = () => {
    setShowLoading(true);
    onYes();
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      className='custom-alert-dialog'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      {showLoading ? (
        <DialogContent>
          <Loader text='Please wait...' />
        </DialogContent>
      ) : (
        <>
          <CustomDialogHeader title='Are you sure ?' />

          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {message}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                onNo();
              }}
              color='primary'
              variant='outlined'>
              No
            </Button>
            <Button
              onClick={() => {
                onSuccess();
              }}
              color='primary'
              variant='outlined'
              autoFocus>
              Yes
            </Button>
          </DialogActions>
        </>
      )}
      {/* {
                !disableActionButton && <CustomDialogHeader title="Are you sure ?" />
            }
            <DialogContent>
                {
                    !disableActionButton ? <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText> : <Loader text="Please wait..." />
                }
            </DialogContent>
            {
                !disableActionButton && <DialogActions>
                    <Button onClick={() => { close(false) }} color="primary" variant="outlined">
                        No
                </Button>
                    <Button onClick={() => { close(true) }} color="primary" variant="outlined" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            } */}
    </Dialog>
  );
};

export default AlertDialog;
