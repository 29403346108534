import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CreateProject } from './CreateProject';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';
import {
  GetProjects,
  DeleteProject,
  ToggleFavouriteProject,
} from '../../axios/project';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DataGridCustomToolbar from './../../components/Helpers/DataGridCustomToolbar';

const Project = () => {
  const [projects, setProjects] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [projectId, setProjectId] = React.useState(null);
  const [loading, setLoading] = useState(true);

  const handleOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setProjectId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setLoading(true);
    await GetProjects()
      .then(({ data }) => {
        setProjects(data);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const handleDelete = () => {
    DeleteProject(projectId)
      .then(({ data }) => {
        setAnchorEl(null);
        setProjectId(null);
        fetchProjects();
      })
      .catch((err) => {});
  };

  const handleToggleFavourite = (projectId, isFavourite) => {
    let data: any = {};
    data.projectId = projectId;
    data.isFavourite = !isFavourite;
    ToggleFavouriteProject(data)
      .then(({ data }) => {
        //fetchProjects()
      })
      .catch((err) => {});
  };

  const columns = [
    {
      field: 'isFavourite',
      headerName: ' ',
      width: 50,
      renderCell: (params) => (
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={params.row.isFavourite}
              onChange={() =>
                handleToggleFavourite(params.row.id, params.row.isFavourite)
              }
              icon={<StarBorderIcon />}
              checkedIcon={<StarIcon />}
              name='checkedH'
            />
          }
          label=''
        />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Link to={'/project/board?projectId=' + params.row.id}>
          {' '}
          {params.row.name}
        </Link>
      ),
    },
    {
      field: 'id',
      headerName: ' ',
      sortable: false,
      hideSortIcons: true,
      width: 100,
      // align: 'right',
      renderCell: (params) => (
        <IconButton onClick={(e) => handleOpen(e, params.row.id)}>
          <MoreVertIcon fontSize='inherit' />
        </IconButton>
      ),
    },
  ];

  return (
    <Layout>
      <Box mt={3} p={2} bgcolor='white'>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant='h6'>Projects</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent='flex-end'>
            <Button
              variant='contained'
              onClick={() => setOpen(true)}
              size='small'
              color='primary'>
              Create Project
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} p={2} bgcolor='white'>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            components={{
              Toolbar: DataGridCustomToolbar,
            }}
            loading={loading}
            rows={projects}
            columns={columns.map((column) => ({
              ...column,
              disableClickEventBubbling: true,
            }))}
            pageSize={10}
            pagination
            density='compact'
          />
        </div>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Box>
      {open && (
        <CreateProject
          open={open}
          setOpen={setOpen}
          fetchProjects={fetchProjects}
        />
      )}
    </Layout>
  );
};

export default Project;
