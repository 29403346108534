import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import CustomDialogHeader from '../CustomDialog/CustomDialogHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
}));

export default function ConfirmationDialogRaw(props) {
  const classes = useStyles();

  const { onClose, onOk, open, message, setIsDeleting } = props;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={open}
      classes={{
        paper: classes.paper,
      }}
      id='confirmation-dialog'
      keepMounted>
      <CustomDialogHeader title='Confirm'></CustomDialogHeader>

      <DialogContent dividers>
        {message ? <Typography>{message} ? </Typography> : null}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onClose}
          disabled={setIsDeleting}
          color='primary'
          variant='outlined'>
          Cancel
        </Button>
        {setIsDeleting ? (
          <CircularProgress />
        ) : (
          <Button
            disabled={setIsDeleting}
            onClick={onOk}
            color='primary'
            variant='outlined'>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

// ConfirmationDialogRaw.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     open: PropTypes.bool.isRequired,
//     message: PropTypes.string.isRequired
// };
