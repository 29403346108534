import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  AssignmentReturnedOutlined,
  ExpandMore,
  Delete,
  Add,
} from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import { Autocomplete } from '@material-ui/lab';
import { Link, useLocation } from 'react-router-dom';
import { FaUserSecret } from 'react-icons/fa';
import moment from 'moment';

import Layout from '../../components/Layout';
import Container from '../../components/Container';
import BrandHeader from '../../components/BrandHeader';
import SearchBox from '../../components/Helpers/SearchBox';
import ConfirmationDialog from '../../components/Helpers/ConfirmationDialog';
import NoDataCell from '../../components/Helpers/NoDataCell';
import AssignUserDialog from './AssignUsers';
import UnAssignUserDialog from './UnAssignUsers';
import DataGridCustomToolbar from './../../components/Helpers/DataGridCustomToolbar';
import routes from './../../components/Helpers/Routes';
import CustomBreadCrumbs from './../../components/CustomBreadCrumbs';
import CreateRoleDialog from './CreateRoles';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import axiosInstance from '../../axios/axiosInstance';
import { getSearchQuery } from '../../services/util';
import { PERMISSION } from '../../constants/Roles';
import './index.scss';

const permissionArray = [PERMISSION.superAdmin, PERMISSION.brandAdmin];
let brandTimeout: ReturnType<typeof setTimeout>;

const GlobalRoles = () => {
  const { state } = useLocation();
  const toastConfig = useContext(CustomToastContext);

  const [loadingBrands, setLoadingRoles] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState(state ? state : null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [brandSelectList, setBrandSelectList] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [checkAllRoles, setCheckAllRoles] = useState(false);
  const [query, setQuery] = useState({ page: 0, limit: 25 });
  const [renderCount, setRenderCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [searchVal, setSearchVal] = useState('');
  const [deleteRec, setDeleteRec] = useState(undefined);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [usersDialogOpen, setUsersDialogOpen] = useState(false);
  const [isUserUnassign, setIsUserUnassign] = useState(false);
  const [selectedBrandUsers, setSelectedBrandUsers] = useState([]);
  const [brandUsers, setBrandUsers] = useState([]);
  const [loadingBrandUsers, setLoadingBrandUsers] = useState(false);
  const [showCreateRoleDialog, setShowCreateRoleDialog] = useState(false);
  const [isAssigning, setAssigning] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [unAssignRec, setUnAssignRec] = useState(undefined);

  useEffect(() => {
    let millisec = Object.keys(searchVal).length > 0 ? 600 : 5;
    if (brandTimeout) {
      clearTimeout(brandTimeout);
    }
    brandTimeout = setTimeout(() => {
      getAllRoles();
    }, millisec);
    // eslint-disable-next-line
  }, [searchVal, selectedBrand]);

  useEffect(() => {
    if (renderCount > 0) {
      getAllRoles();
    } else setRenderCount((preCount) => preCount + 1);
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    getBrandList();
    // eslint-disable-next-line
  }, []);

  const getAllRoles = (id = null) => {
    let searchParams: any = { ...query };
    setLoadingRoles(true);
    searchParams = searchVal
      ? { ...searchParams, search: searchVal }
      : searchParams;
    const api = getSearchQuery(
      selectedBrand ? `/sa-role?filterById=[{"field": "brand", "term": "${selectedBrand?.id}"}]` : `/sa-role`,
      searchParams,
    );
    axiosInstance()
      .get(api)
      .then(({ data }) => {
        getRowsData(data.data);
        setRowCount(data.count);
        setCheckAllRoles(false);
        setLoadingRoles(false);
      })
      .catch((err) => {
        setLoadingRoles(false);
      });
  };
  const getRowsData = (data) => {
    let rows = [];
    if (data) {
      rows = data.map((role) => ({
        id: role._id,
        isChecked: false,
        ...role,
        createdBy: role.createdBy,
        updatedBy: role.updatedBy,
        brandName: role.brand?.optionLabel,
      }));
    }
    setDataRows(rows);
  };

  const getBrandList = () => {
    axiosInstance()
      .get('/sa-brand?select=companyName&limit=100')
      .then(({ data: { data } }) => {
        const list = data.map((item) => ({
          id: item._id,
          name: item.companyName,
        }));
        setBrandSelectList(list);
      });
  };

  const columns = [
    {
      field: 'isChecked',
      headerName: 'Checkbox',
      renderHeader: () => (
        <Checkbox
          color='primary'
          checked={checkAllRoles}
          onChange={(ev) => {
            setCheckAllRoles(ev.target.checked);
            const gridData = dataRows;
            gridData.map((d) => {
              d.isChecked = ev.target.checked;
              return d;
            });
            setDataRows([...gridData]);
          }}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          color='primary'
          checked={params.value}
          onChange={(ev) => {
            const gridData = dataRows;
            const indexOfRecord = gridData.findIndex(
              (d) => d.id === params.row.id,
            );
            gridData[indexOfRecord].isChecked = ev.target.checked;

            setDataRows([...gridData]);

            const checkedRecords = gridData.filter((d) => d.isChecked === true);

            if (checkedRecords.length === gridData.length) {
              setCheckAllRoles(true);
            } else {
              setCheckAllRoles(false);
            }
          }}
        />
      ),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      width: 75,
    },
    {
      field: 'name',
      headerName: 'Role Name ',
      width: 350,
      renderCell: (params) => (
        <Link className='role-name-Link' to={`/global-roles/${params.row.id}`}>
          {params?.row?.name ? params.row.name : <NoDataCell />}
        </Link>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 500,
      renderCell: (params) => (
        <Tooltip title={params?.value}>
          <span style={{ maxWidth: '300px' }}>
            {params?.value ?? <NoDataCell />}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'brandName',
      headerName: 'Brand Name',
      width: 250,
      renderCell: (params) => <>{params?.value ?? <NoDataCell />}</>,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value && params.value.user ? (
          <h5 className='createBy'>
            {params.value.user.firstName}
            <span className='createdAtTime' title={params.value.date}>
              {moment(params.value.date.slice(0, 10)).format('MMM Do, YYYY')}
            </span>
          </h5>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value && params.value.user ? (
          <h5 className='updateBy'>
            {params.value.user.firstName}
            <span title={params.value.date} className='updatedAtTime'>
              {moment(params.value.date.slice(0, 10)).format('MMM Do, YYYY')}
            </span>
          </h5>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <span title='Delete'>
            <IconButton
              aria-label='Delete'
              onClick={() => {
                setShowConfirmBox(true);
                setDeleteRec(params.row);
              }}
              disabled={permissionArray.indexOf(params?.row?.permission) >= 0}>
              <Delete
                fontSize='small'
                color={
                  permissionArray.indexOf(params?.row?.permission) >= 0
                    ? 'disabled'
                    : 'error'
                }
              />
            </IconButton>
          </span>
          <Tooltip title='Un-Assign Users'>
            <IconButton
              aria-label='Un-Assign Users'
              onClick={() => openUnassignUserDialog(params)}>
              <AssignmentReturnedOutlined fontSize='small' color='action' />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const openActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActions = () => {
    setAnchorEl(null);
  };

  const handleSearch = (e) => {
    if (query.page !== 0) {
      setQuery((prevState) => ({ ...prevState, page: 0 }));
    }
    setSearchVal(e.target.value);
    if (selectedBrand) {
      setSelectedBrand(null);
    }
  };

  const handlePage = (params) => {
    if (query.page !== params.page) {
      setQuery((prevState) => ({ ...prevState, page: params.page }));
    }
  };
  const handlePageSize = (params) => {
    if (params.pageSize !== query.limit) {
      setQuery({ page: 0, limit: params.pageSize });
    }
  };
  const handleSortModelChange = (params) => {
    if (params?.sortModel && params.sortModel.length > 0) {
      let temp = { ...params.sortModel[0] };
      if (temp.field === 'status') {
        temp.field = 'blocked';
      }
      setQuery((prevState) => ({
        ...prevState,
        page: 0,
        sortBy: temp.field,
        orderBy: temp.sort,
      }));
    }
  };

  const handleDeleteRole = async () => {
    setLoadingRoles(true);
    let selectedRoles = [];
    if (deleteRec?.id) {
      selectedRoles = [deleteRec.id];
    } else {
      dataRows.forEach((obj) => {
        if (obj.isChecked) selectedRoles.push(obj.id);
      });
    }
    try {
      let { data } = await axiosInstance().put('/sa-role/remove', {
        ids: selectedRoles,
      });
      setLoadingRoles(false);
      if (deleteRec?.id) setDeleteRec(undefined);
      toastConfig.setToastConfig({
        message: data.message,
        type: 'success',
        open: true,
      });
      getAllRoles();
    } catch (error) {
      setLoadingRoles(false);
      toastConfig.setToastConfig(error);
    }
    setShowConfirmBox(false);
  };

  const onFilterChange = React.useCallback((params) => {
    if (
      params.filterModel.items[0].value ||
      params.filterModel.items[0].value === ''
    ) {
      const deepFilter = JSON.stringify([{ field: params.filterModel.items[0].columnField, term: params.filterModel.items[0].value }])
      setQuery((prevState) => ({
        ...prevState,
        deepFilter
      }));
    }
    else {
      setQuery({ page: 0, limit: 25 });
    }
  }, []);

  const addNewRoles = () => {
    if (!selectedBrand) {
      toastConfig.setToastConfig({
        message: 'Brand must be selected',
        type: 'warning',
        open: true,
      });
    } else {
      setShowCreateRoleDialog(true);
    }
  };

  const handleChangeBrand = (_, newVal) => {
    setSelectedBrand(newVal);
    if (searchVal) {
      setSearchVal('');
    }
  };

  const handleOpenDialog = () => {
    if (!selectedBrand) {
      toastConfig.setToastConfig({
        message: 'Brand must be selected',
        type: 'warning',
        open: true,
      });
    } else {
      getSelectedBrandUsers();
      setUsersDialogOpen(true);
    }
    closeActions();
  };

  const handleCloseDialog = () => {
    setUsersDialogOpen(false);
    setSelectedBrandUsers([]);
  };

  const getSelectedBrandUsers = async () => {
    if (selectedBrand) {
      setLoadingBrandUsers(true);

      try {
        const {
          data: { data },
        } = await axiosInstance().get(
          `/sa-user?filterById=[{"field": "brand", "term": "${selectedBrand.id}"}]`);
        let selectedRoles = [];
        dataRows.forEach((obj) => {
          if (obj.isChecked) selectedRoles.push(obj.id);
        });
        selectedRoles.length === 1 ?
          setBrandUsers(data.filter(r => !r.role.some(item => item?._id === selectedRoles[0])))
          : setBrandUsers(data);
        setLoadingBrandUsers(false);
      } catch (error) {
        toastConfig.setToastConfig(error);

        setLoadingBrandUsers(false);
      }
    }
  };

  const handleUserSelection = (e, id) => {
    let tempSelectedUsers = [...selectedBrandUsers];
    let curIndex = tempSelectedUsers.indexOf(id);
    if (e.target.checked) {
      if (curIndex < 0) tempSelectedUsers = [...tempSelectedUsers, id];
    } else if (curIndex >= 0) {
      tempSelectedUsers.splice(curIndex, 1);
    }
    setSelectedBrandUsers(tempSelectedUsers);
  };

  const handleAssignRoles = async () => {
    let selectedRoles = [];
    dataRows.forEach((obj) => {
      if (obj.isChecked) selectedRoles.push(obj.id);
    });
    if (selectedRoles.length && selectedBrandUsers.length) {
      setAssigning(true);
      try {
        const dataObj = {
          users: selectedBrandUsers,
          roles: selectedRoles,
        };
        await axiosInstance().put(`/sa-user/add-role`, dataObj);
        toastConfig.setToastConfig({
          message: 'Roles assigned successfully',
          type: 'success',
          open: true,
        });
        setAssigning(false);
        handleCloseDialog();
        getAllRoles();
      } catch (error) {
        setAssigning(false);
        toastConfig.setToastConfig(error);
      }
    }
  };

  const openUnassignUserDialog = async (obj) => {
    setIsUserUnassign(true);
    if (obj?.row?._id) {
      setUnAssignRec(obj.row);
      try {
        const {
          data: { data },
        } = await axiosInstance().get(`/sa-user?filterById=[{"field": "role", "term": "${obj.row?._id}"}]`);
        setSelectedUsers(data.map((o) => ({ ...o, isChecked: true })));
      } catch (error) {
        toastConfig.setToastConfig(error);
      }
    } else {
      let tData = dataRows.find((obj) => obj.isChecked);
      if (tData) {
        try {
          const {
            data: { data },
          } = await axiosInstance().get(`/sa-user?filterById=[{"field": "role", "term": "${tData.id}"}]`);
          setSelectedUsers(data.map((o) => ({ ...o, isChecked: true })));
        } catch (error) {
          toastConfig.setToastConfig(error);
        }
      }
    }
    closeActions();
  };
  const handleCloseUnAssign = () => {
    setIsUserUnassign(false);
    setSelectedUsers([]);
  };
  const handleUserDeSelect = (user) => {
    let data = selectedUsers.map((obj) => {
      if (user._id === obj._id) {
        user.isChecked = user.isChecked ? false : true;
      }
      return obj;
    });
    setSelectedUsers(data);
  };
  const handleUnassignRole = () => {
    setLoadingBrandUsers(true);
    let users = [];
    let req: any
    selectedUsers.forEach((o) => {
      if (!o.isChecked) users.push(o._id);
    });

    if (unAssignRec?._id) {
      req = {
        users: users,
        role: unAssignRec._id
      };
    } else {
      let tData = dataRows.find((obj) => obj.isChecked);
      if (tData) {
        req = {
          users: users,
          role: tData._id
        }
      }
    }
    axiosInstance()
      .put('/sa-user/un-assign-users', req)
      .then(({ data }) => {
        setIsUserUnassign(false);
        setSelectedUsers([]);
        getAllRoles();
        setLoadingBrandUsers(false);
        toastConfig.setToastConfig({
          message: 'Roles Un Assigned successfully',
          type: 'success',
          open: true,
        });
      })
      .catch((err) => {
        setLoadingBrandUsers(false);
        toastConfig.setToastConfig(err);
      });
  };
  const handleCloseCreateRole = () => {
    setShowCreateRoleDialog(false);
    getAllRoles();
  };

  const disableDelete = dataRows.some(
    (o) => o.isChecked && permissionArray.indexOf(o?.permission) >= 0,
  );

  return (
    <>
      {usersDialogOpen ? (
        <AssignUserDialog
          isOpen={usersDialogOpen}
          onClose={handleCloseDialog}
          brandUsers={brandUsers}
          loading={loadingBrandUsers}
          onUserSelection={handleUserSelection}
          onAssignRoles={handleAssignRoles}
          selectedBrandUsers={selectedBrandUsers}
          isAssigning={isAssigning}
        />
      ) : null}

      {isUserUnassign ? (
        <UnAssignUserDialog
          isOpen={isUserUnassign}
          onClose={handleCloseUnAssign}
          selectedUsers={selectedUsers}
          loading={loadingBrandUsers}
          onUserSelection={handleUserDeSelect}
          onUnAssignRole={handleUnassignRole}
        />
      ) : null}

      <Layout>
        {showCreateRoleDialog ? (
          <CreateRoleDialog
            isOpen={showCreateRoleDialog}
            onClose={() => setShowCreateRoleDialog(false)}
            name={selectedBrand?.name}
            brandId={selectedBrand?.id}
            onOk={handleCloseCreateRole}
          />
        ) : null}
        <Box component='div'>
          <CustomBreadCrumbs routes={[routes.globalRoles]} />
          <Container>
            <div className='header-panel'>
              <BrandHeader
                // totalRole={rowCount}
                heading='Roles'
                total={rowCount}
                icon={<FaUserSecret className='brandHeaderLogo' />}>
                <div className='globaluser-header-inner-container'>
                  <SearchBox
                    onSearch={handleSearch}
                    searchbox='globaluser-searchbar'
                    value={searchVal}
                    width='300px'
                  />
                  <Autocomplete
                    // fullWidth
                    size='small'
                    className='globaluser-select-brand'
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'top',
                    }}
                    value={selectedBrand}
                    onChange={handleChangeBrand}
                    options={brandSelectList}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(opt, val) =>
                      opt && val && opt.name === val.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Select Brand'
                        variant='outlined'
                      />
                    )}
                  />
                  <Tooltip
                    title={
                      selectedBrand
                        ? ""
                        : 'Please select a Brand'
                    }>
                    <span>
                      <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        disabled={selectedBrand ? false : true}
                        className='globaluser-addbtn'
                        onClick={addNewRoles}
                        startIcon={<Add />}>
                        Add
                  </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      !dataRows.some((d) => d.isChecked)
                        ?'Please select a Role' 
                        : ''
                    }>
                    <span>
                      <Button
                        // disabled={Boolean(!selectedBrand)}
                        disabled={!dataRows.some((d) => d.isChecked)}
                        variant='outlined'
                        size='small'
                        className='globaluser-actionbtn'
                        color='primary'
                        onClick={openActions}
                        aria-controls='action-menu'>
                        Actions <ExpandMore />
                      </Button>
                    </span>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    id='action-menu'
                    open={Boolean(anchorEl)}
                    onClose={closeActions}>
                    <MenuItem
                      disabled={disableDelete}
                      onClick={() => {
                        closeActions();
                        setShowConfirmBox(true);
                      }}>
                      Delete
                    </MenuItem>
                    <Tooltip
                      title={
                        Boolean(!selectedBrand)
                          ? 'Please select a Brand'
                          : ""
                      }>
                      <span>
                        <MenuItem
                          disabled={Boolean(!selectedBrand)} onClick={handleOpenDialog}>Assign Users
                          </MenuItem>
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={
                        dataRows.filter((o) => o.isChecked).length === 1
                          ? ""
                          : 'Please select only one Role'
                      }>
                      <span>
                        <MenuItem
                          disabled={dataRows.filter((o) => o.isChecked).length !== 1}
                          onClick={openUnassignUserDialog}>
                          Un-Assign Users
                    </MenuItem>
                      </span>
                    </Tooltip>
                  </Menu>
                </div>
              </BrandHeader>
            </div>

            <div className='listing-grid '>
              <DataGrid
                components={{
                  Toolbar: DataGridCustomToolbar,
                }}
                columnBuffer={2}
                loading={loadingBrands}
                rows={loadingBrands ? [] : dataRows}
                columns={columns}
                disableSelectionOnClick
                disableMultipleSelection
                paginationMode='server'
                pagination
                onPageChange={handlePage}
                onPageSizeChange={handlePageSize}
                pageSize={query.limit}
                page={query.page}
                rowCount={rowCount}
                rowsPerPageOptions={[25, 50, 75]}
                onSortModelChange={handleSortModelChange}
                density='compact'
                filterMode='server'
                onFilterModelChange={onFilterChange}
              />
            </div>

            {showConfirmBox ? (
              <ConfirmationDialog
                open={showConfirmBox}
                message={
                  deleteRec?.id
                    ? `Are you sure you want to delete  role ${deleteRec?.name ?? ''
                    }`
                    : `Are you sure you want to delete these roles`
                }
                onClose={() => {
                  setShowConfirmBox(false);
                  if (deleteRec?.id) setDeleteRec(undefined);
                }}
                onOk={handleDeleteRole}
              />
            ) : null}
          </Container>
        </Box>
      </Layout>
    </>
  );
};

export default GlobalRoles;
