import { useState, useEffect, Fragment, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from '../../components/Layout';
import {
  GetBrandResourceData,
  UpdateBrandResourceData,
} from '../../axios/formbuilder';
import { Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import queryString from 'query-string';
import CustomBreadCrumbs from './../../components/CustomBreadCrumbs';
import routes from './../../components/Helpers/Routes';
import { FormBuilder } from '../../components/FormBuilder';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import TextField from '@material-ui/core/TextField';
import { camelCase } from "lodash";

const CreateFormBuilder = (props) => {
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);

  const { setToastConfig } = useContext(CustomToastContext);
  const { brandId, resource } = parsed;
  const [section, setSection] = useState(null);
  const [brandName, setBrandName] = useState('');
  const [deleteField, setDeleteField] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [resourceLabel, setResourceLabel] = useState("");

  useEffect(() => {
    fetchBrandResourceData();
    // eslint-disable-next-line
  }, []);

  const fetchBrandResourceData = async () => {
    await GetBrandResourceData(brandId, resource)
      .then(({ data }) => {
        setSection(data.section);
        setBrandName(data.brandName);
        setResourceLabel(data.resourceLabel)
      })
      .catch((err) => {
        setToastConfig(err);
      });
  };

  const handleSave = () => {
    if (resourceLabel === "") {
      alert("Please enter resource label")
      return;
    }
    let data = [];
    let order = 0;
    section.forEach((_section) => {
      _section.field.forEach((_field) => {
        let _field_data = _field;
        _field_data._id = _field_data._id.toString();
        _field_data.sectionName = _section.sectionName;
        if (!isNaN(_field._id)) {
          _field_data.fieldName = camelCase(_field.fieldLabel);
        }
        _field_data.order = ++order;
        data.push(_field_data);
      });
    });

    let sendData: any = {};
    sendData.brandId = brandId;
    sendData.resource = resource;
    sendData.field = data;
    sendData.deleteField = deleteField;
    sendData.resourceLabel = resourceLabel;
    setIsUpdating(true);
    UpdateBrandResourceData(sendData)
      .then(({ data }) => {
        setIsUpdating(false);
        history.push({ pathname: '/form-builder' });
      })
      .catch((err) => {
        setIsUpdating(false);
      });
  };
  return (
    <Layout>
      <CustomBreadCrumbs routes={[routes.formBuilder, { title: resource }]} />
      {!section ? (
        <Loader text='' />
      ) : (
        <Fragment>
          <Box p={2} pb={0} bgcolor='white'>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Typography variant='caption'>Brand </Typography>
                <Typography variant='body1'>{brandName}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant='caption'>Resource</Typography>
                <Typography variant='body1'>{resource}</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Resource Label"
                  required={true}
                  name="name"
                  fullWidth
                  margin="dense"
                  value={resourceLabel}
                  onChange={(e) => {
                    setResourceLabel(e.target.value.trimStart())
                  }}
                />
              </Grid>
              <Grid item xs={6} container justifyContent='flex-end'>
                <Box>
                  <Button
                    disabled={isUpdating}
                    color='primary'
                    size='small'
                    onClick={handleSave}
                    variant='contained'>
                    Save
                    {isUpdating && <CircularProgress size={24} />}
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    color='primary'
                    variant='contained'
                    size='small'
                    onClick={() =>
                      history.push({
                        pathname: '/form-builder',
                      })
                    }>
                    Close
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <FormBuilder
              section={section}
              setSection={setSection}
              deleteField={deleteField}
              setDeleteField={setDeleteField}
              isCustomField={false}
              extraFields={[]}
              module="form-builder-master"
              resource=""
            />
          </Box>
        </Fragment>
      )}
    </Layout>
  );
};

export default CreateFormBuilder;
