import React, { useContext, useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Layout from '../../components/Layout';
import CustomBreadCrumbs from '../../components/CustomBreadCrumbs';
import routes from '../../components/Helpers/Routes';
import axiosInstance from '../../axios/axiosInstance';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EcommerceSetup = () => {

    const [allCategory, setAllCategory] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const { setToastConfig } = useContext(CustomToastContext);

    useEffect(() => {
        fetchProductCategory();
        fetchTopProductCategory();
        // eslint-disable-next-line
    }, []);

    const fetchProductCategory = async () => {
        axiosInstance()
            .get(`product-category/all`)
            .then(({ data: { data } }) => {
                setAllCategory(data.map((d) => ({ id: d._id, name: d.name })))
            })
            .catch((err) => {
                setToastConfig(err);
            });
    };

    const fetchTopProductCategory = async () => {
        axiosInstance()
            .get(`admin-ecommerce/top-Categories`)
            .then(({ data: { data } }) => {
                setSelectedCategories(data.map((d) => ({ id: d._id, name: d.name })))
            })
            .catch((err) => {
                setToastConfig(err);
            });
    };

    const handleSaveCategory = async () => {
        const updatedData = {
            "_id": "6128d27e9ce4b9092042b552",
            "topHeaderCategories": selectedCategories.map((d) => ({ _id: d.id }))
        };
        axiosInstance()
            .put('/admin-ecommerce/set-categories', updatedData)
            .then(() => {
                setToastConfig({
                    message: 'Successfully saved',
                    type: 'success',
                    open: true,
                });
            })
            .catch((err) => {
                setToastConfig(err);
            });
    };

    return (
        <Layout>
            <CustomBreadCrumbs routes={[routes.ecommerceSetup]} />
            <Grid container spacing={2}>
                <Grid item className='w-100'>
                    {allCategory.length !== 0 && <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={allCategory}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        value={selectedCategories}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selectedCategories.some(d => option.id === d.id)}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                        style={{ width: 500 }}
                        onChange={(e, val) => {
                            val.length > 10 ? setSelectedCategories(val.splice(1, 10)) : setSelectedCategories(val);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Select category" placeholder="Category" />
                        )}
                    />}
                </Grid>
                <Grid item >
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        // onClick={handleViewAllUser}
                        onClick={handleSaveCategory}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Layout>
    );
}
export default EcommerceSetup;