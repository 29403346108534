import { useState, useContext, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import axiosInstance from '../../axios/axiosInstance';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import { Formik, Form } from 'formik';
import { object, string, boolean } from 'yup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useParams, useHistory } from 'react-router-dom';
import routes from '../../components/Helpers/Routes';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TinyMce from './../../components/TinyMCE/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomBreadCrumbs from '../../components/CustomBreadCrumbs';
import { Autocomplete } from '@material-ui/lab';
import ConfirmCancelDialog from '../../components/ConfirmCancelDialog';
import { Add, Cancel, Delete } from '@material-ui/icons';
import CustomTable from './CustomTable/customTable';
import Layout from '../../components/Layout';
const defaultProductColumns = 7;

export const quoteBuilder = {
  qbResource: 'quoteBuilder',
  qbApi: '/quote-builder',
};

const PdfTemplateSchema = object().shape({
  name: string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long')
    .required('PDF template Name  is required'),
  resource: string().required('Resource is required'),
  showPageNumberInFooter: boolean(),
});

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    overflowY: 'scroll',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
  tinyMCEContainer: {
    width: '725px',
  },
  headingLabel: {
    marginBottom: '7px',
  },
}));

export default function NewCreateQuotePdfTemplate() {
  const { qbApi } = quoteBuilder;
  const { resourceId } = useParams();
  const history = useHistory();
  const [details, setDetails] = useState({
    header: '',
    footer: '',
    aboveTable: '',
    belowTable: '',
  });
  const [initialValues, setInitialValues] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingAndPreview, setIsUpdatingAndPreview] = useState(false);
  const classes = useStyles();
  const toastConfig = useContext(CustomToastContext);
  const [isClone] = useState(history.location?.state?.isClone ? true : false);
  const [quoteData] = useState(history.location?.state?.quoteData);
  const [version] = useState(history.location?.state?.version);

  const [ownerCollaboratorData, setOwnerCollaboratorData] = useState([]);
  const [ownerCollaboratorDataConst, setOwnerCollaboratorDataConst] = useState(
    [],
  );
  const [hasPermissionToUpdate, setHasPermissionToUpdate] = useState(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isLandscapChecked, setIsLandscapChecked] = useState(false);
  const [isBreakCrumbPath, setIsBreakCrumbPath] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const [table, setTable] = useState([]);
  const [resource, setResource] = useState([]);

  const [variables, setVariables] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const onBackButtonEvent = (e) => {
    if (hasPermissionToUpdate) {
      e.preventDefault();
      window.history.pushState(null, null, window.location?.pathname);
      setShowConfirmDialog(true);
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location?.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  async function fetchCustomField() {
    const data = await axiosInstance().get(`/master-resource`);
    setResource(data?.data?.data);
  }

  async function fetchFieldData(resource) {
    const fields = await axiosInstance().get(`/master-resource/${resource}`);
    const datas = fields?.data?.data?.fields?.map((field) => {
      return field?.fieldName;
    });
    setVariables([...datas]);
    return datas;
  }

  async function fetchFieldDataByName(resource) {
    const fields = await axiosInstance().get(
      `/master-resource/find-by-name/${resource}`,
    );
    const datas = fields?.data?.data?.fields?.map((field) => {
      return field?.fieldName;
    });
    setVariables([...datas]);
    console.log(datas);
    return datas;
  }

  useEffect(() => {
    fetchCustomField();
    if (resourceId && resourceId !== '0') {
      let tempPdfTemplate = quoteData?.versions[version]?.pdfTemplate;
      if (tempPdfTemplate) {
        setIsLandscapChecked(tempPdfTemplate.landscape);
        setInitialValues({
          landscape: tempPdfTemplate.landscape,
          productColumns: tempPdfTemplate.productColumns,
          name: tempPdfTemplate.name,
          showPageNumberInFooter: tempPdfTemplate.pageNumberInFooter,
          header: tempPdfTemplate.header,
          footer: tempPdfTemplate.footer,
          aboveTable: tempPdfTemplate.aboveTable,
          belowTable: tempPdfTemplate.belowTable,
          resource: tempPdfTemplate.resource,
        });
        setDetails({
          header: tempPdfTemplate.header,
          footer: tempPdfTemplate.footer,
          aboveTable: tempPdfTemplate.aboveTable,
          belowTable: tempPdfTemplate.belowTable,
        });
      } else {
        (async () => {
          try {
            const res = await axiosInstance().get(
              `/master-pdf-template/${resourceId}`,
            );
            const {
              data: { data },
            } = res;
            setIsLandscapChecked(data?.landscape);
            setInitialValues({
              landscape: data?.landscape,
              productColumns: data?.productColumns,
              name: !isClone ? data?.name : '',
              showPageNumberInFooter: data?.pageNumberInFooter,
              header: data?.header,
              footer: data?.footer,
              aboveTable: data?.aboveTable,
              belowTable: data?.belowTable,
              resource: data?.resource,
            });
            await fetchFieldDataByName(data?.resource);

            const tableData = data?.tables?.map(async (d) => {
              const fetchedFieldData = await fetchFieldDataByName(
                d.resourceName,
              );
              const fetchedFormatedFilter = fetchedFieldData?.map(
                (data, idx) => {
                  return {
                    label: data,
                    name: data,
                  };
                },
              );
              return {
                ...d,
                fieldOptions: fetchedFormatedFilter ?? [],
              };
            });
            const allTableData = await Promise.all(tableData);
            if (allTableData.length) {
              setTable(allTableData);
            }

            // setTable(
            //   data?.tables.map((d) => {
            //     return { ...d, fieldOptions: [] };
            //   }),
            // );
            setDetails({
              header: data?.header,
              footer: data?.footer,
              aboveTable: data?.aboveTable,
              belowTable: data?.belowTable,
            });
          } catch (e) {
            toastConfig.setToastConfig(e);
          }
        })();
      }
    } else {
      setInitialValues({
        landscape: false,
        productColumns: defaultProductColumns,
        name: '',
        showPageNumberInFooter: false,
        header: '',
        footer: '',
        aboveTable: '',
        belowTable: '',
        resource: '',
      });
    }
    fetchUser();
  }, [resourceId]);

  const fetchUser = () => {
    axiosInstance()
      .get(`/user`)
      .then(({ data: { data } }) => {
        setOwnerCollaboratorData(data);
        setOwnerCollaboratorDataConst(data);
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
      });
  };

  const previewPdfTemplate = (templateId) => {
    toastConfig.setToastConfig({
      hideDuration: null,
      open: true,
      type: 'info',
      message: `Downloading preview file, Please wait...`,
    });

    axiosInstance()
      .get(`${qbApi}/getdummy/${templateId}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        setIsPreview(false);
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: 'File downloaded Successfully',
        });

        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
        setIsPreview(false);
      });
  };

  const handleSubmit = (values) => {
    if (isPreview === true) {
      setIsUpdatingAndPreview(true);
    } else {
      setIsUpdating(true);
    }

    if (resourceId === '0' || isClone === true) {
      axiosInstance()
        .post('/master-pdf-template', {
          ...details,
          name: values.name,
          pageNumberInFooter: values.showPageNumberInFooter,
          resource: values?.resource,
          tables: table?.map((item) => {
            return {
              resourceName: item.resourceName,
              columns: item.columns,
            };
          }),
          landscape: values?.landscape,
          productColumns: parseInt(values?.productColumns),
        })
        .then(({ data: { data } }) => {
          if (isPreview === true) {
            previewPdfTemplate(data._id);
            setIsUpdatingAndPreview(false);
            history.push(`${routes.masterPdfTemplate.path}/${data._id}`);
          } else {
            history.push({
              pathname: isBreakCrumbPath
                ? isBreakCrumbPath
                : routes.masterPdfTemplate.path,
            });
            setIsUpdating(false);
          }
        })
        .catch((error) => {
          setIsUpdating(false);
          setIsUpdatingAndPreview(false);
          toastConfig.setToastConfig(error);
        });
    } else {
      // let api = quoteData
      //   ? `/quote-builder/pdf-template/${quoteData._id}/${version}`
      //   : '/master-pdf-template';
      let api = '/master-pdf-template';
      axiosInstance()
        .put(api, {
          _id: resourceId,
          ...details,
          name: values.name,
          pageNumberInFooter: values.showPageNumberInFooter,
          resource: values?.resource,
          tables: table?.map((item) => {
            return {
              resourceName: item.resourceName,
              columns: item.columns,
            };
          }),
          landscape: values?.landscape,
          productColumns: parseInt(values?.productColumns),
        })
        .then(({ data: { data } }) => {
          if (isPreview === true) {
            previewPdfTemplate(data._id);
            setIsUpdatingAndPreview(false);
            if (quoteData?._id) {
              history.push(`/quotes/detail/${quoteData?._id}`, {
                versionNumber: `${version}`,
                tabValue: 1,
              });
            } else {
              history.push(`${routes.masterPdfTemplate.path}/${data._id}`);
            }
          } else {
            if (quoteData?._id) {
              history.push(`/quotes/detail/${quoteData?._id}`, {
                versionNumber: `${version}`,
                tabValue: 1,
              });
            } else {
              history.push({
                pathname: isBreakCrumbPath
                  ? isBreakCrumbPath
                  : routes.masterPdfTemplate.path,
              });
            }
            setIsUpdating(false);
          }
        })
        .catch((error) => {
          setIsUpdating(false);
          setIsUpdatingAndPreview(false);
          toastConfig.setToastConfig(error);
        });
    }
  };

  return (
    <Layout>
      <div className={classes.root}>
        <Grid container className='headerbox'>
          <Grid item md={4} sm={11} xs={10}>
            <CustomBreadCrumbs
              routes={[
                {
                  title: routes.masterPdfTemplate.title,
                  path: routes.masterPdfTemplate.path,
                },
                {
                  title:
                    resourceId === '0'
                      ? 'New'
                      : isClone === true
                      ? 'Clone'
                      : initialValues && initialValues.name,
                },
              ]}
            />
          </Grid>
        </Grid>
        <div className={`main-container ${classes.mainContainer}`}>
          <Paper className={classes.paper}>
            {initialValues ? (
              <Formik
                innerRef={(ref) => ref && setFormValues(ref.values)}
                initialValues={initialValues}
                validationSchema={PdfTemplateSchema}
                onSubmit={handleSubmit}>
                {({ submitForm, touched, errors, setFieldValue, values }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          disabled={
                            !isClone &&
                            (!hasPermissionToUpdate || Boolean(quoteData?._id))
                          }
                          variant='outlined'
                          type='text'
                          label='PDF Template Name'
                          required={true}
                          name='name'
                          fullWidth
                          margin='dense'
                          value={values['name']}
                          error={touched['name'] && Boolean(errors['name'])}
                          helperText={touched['name'] && errors['name']}
                          onChange={(e) =>
                            setFieldValue('name', e.target.value.trimStart())
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {
                          <Autocomplete
                            disabled={!isClone && !hasPermissionToUpdate}
                            getOptionLabel={(resource: any) =>
                              resource ? resource?.label : ''
                            }
                            value={
                              resource?.filter(
                                (d) => d.name === values['resource'],
                              ).length
                                ? resource?.filter(
                                    (d) => d.name === values['resource'],
                                  )[0]
                                : ''
                            }
                            options={resource}
                            onChange={async (e, val) => {
                              setFieldValue(
                                'resource',
                                val.name ? val.name : '',
                              );
                              await fetchFieldData(val?._id);
                            }}
                            renderOption={(option) => option.label || ''}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required={true}
                                margin='dense'
                                name='resource'
                                label='Resource'
                                variant='outlined'
                                error={
                                  touched['resource'] &&
                                  Boolean(errors['resource'])
                                }
                                helperText={
                                  touched['resource'] && errors['resource']
                                }
                                fullWidth
                              />
                            )}
                          />
                        }
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={`${classes.saveButtonContainer} gap-2`}>
                        <Button
                          disabled={
                            isUpdating || (!isClone && !hasPermissionToUpdate)
                          }
                          size='small'
                          color='primary'
                          onClick={submitForm}
                          variant='contained'
                          endIcon={
                            isUpdating && (
                              <CircularProgress color='inherit' size={18} />
                            )
                          }>
                          Save
                        </Button>

                        {!quoteData && (
                          <Button
                            disabled={
                              !isClone &&
                              (isUpdatingAndPreview || !hasPermissionToUpdate)
                            }
                            size='small'
                            color='primary'
                            onClick={() => {
                              setIsPreview(true);
                              submitForm();
                            }}
                            variant='contained'
                            endIcon={
                              isUpdatingAndPreview && (
                                <CircularProgress color='inherit' size={18} />
                              )
                            }>
                            Save & Preview
                          </Button>
                        )}

                        <Button
                          size='small'
                          color='primary'
                          variant='contained'
                          onClick={() => {
                            if (quoteData?._id) {
                              history.push(`/quotes/detail/${quoteData?._id}`, {
                                versionNumber: `${version}`,
                                tabValue: 1,
                              });
                            } else {
                              history.push({
                                pathname: isBreakCrumbPath
                                  ? isBreakCrumbPath
                                  : routes.masterPdfTemplate.path,
                              });
                            }
                          }}>
                          Close
                        </Button>
                      </Grid>
                      {showConfirmDialog ? (
                        <ConfirmCancelDialog
                          close={() => setShowConfirmDialog(false)}
                          open={showConfirmDialog}
                          onSave={() => {
                            setShowConfirmDialog(false);
                            submitForm();
                          }}
                          onClose={() => {
                            //  This condition is to check either user is redirected from quote details screen or not
                            if (history.location?.state?.redirectTo) {
                              if (isBreakCrumbPath) {
                                history.push({
                                  pathname: routes.masterPdfTemplate.path,
                                });
                                setIsBreakCrumbPath('');
                              } else {
                                history.push(
                                  history.location?.state?.redirectTo,
                                );
                              }
                            } else {
                              setShowConfirmDialog(false);
                              history.push({
                                pathname: isBreakCrumbPath
                                  ? isBreakCrumbPath
                                  : routes.masterPdfTemplate.path,
                              });
                              setIsBreakCrumbPath('');
                            }
                          }}
                        />
                      ) : null}
                    </Grid>
                    <Box width={'100%'} marginTop={2} marginBottom={2}>
                      <hr />
                    </Box>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={3} style={{ textAlign: 'left' }}>
                        <FormControlLabel
                          disabled={!isClone && !hasPermissionToUpdate}
                          value={values['showPageNumberInFooter']}
                          control={
                            <Checkbox
                              name='showPageNumberInFooter'
                              checked={values['showPageNumberInFooter']}
                              onChange={(e) => {
                                setFieldValue(
                                  'showPageNumberInFooter',
                                  e.target.checked,
                                );
                              }}
                              color='primary'
                            />
                          }
                          label='Show page number in footer'
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} style={{ textAlign: 'left' }}>
                        <FormControlLabel
                          disabled={!isClone && !hasPermissionToUpdate}
                          value={values['landscape']}
                          control={
                            <Checkbox
                              name='landscape'
                              checked={values['landscape']}
                              onChange={(e) => {
                                setIsLandscapChecked(e.target.checked);
                                setFieldValue('landscape', e.target.checked);
                              }}
                              color='primary'
                            />
                          }
                          label='Landscape'
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} style={{ textAlign: 'left' }}>
                        <TextField
                          name='productColumns'
                          label='No. of Product Columns'
                          value={values['productColumns']}
                          type='number'
                          fullWidth
                          variant='outlined'
                          size='small'
                          InputProps={{ inputProps: { min: 5, max: 20 } }}
                          onChange={(e) => {
                            setFieldValue('productColumns', e.target.value);
                          }}
                          onBlur={(e) => {
                            const val = parseInt(e.target.value);
                            if (!(val >= 5 && val <= 20)) {
                              setFieldValue(
                                'productColumns',
                                defaultProductColumns.toString(),
                              );
                            }
                          }}
                          helperText='Value must be between 5 to 20'
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            ) : null}
            <Grid item xs={12} className='mt-4'>
              <Box className={classes.tinyMCEContainer}>
                <Typography
                  className={classes.headingLabel}
                  variant='h5'
                  component='h5'>
                  Header
                </Typography>
                <TinyMce
                  disabledEditor={!hasPermissionToUpdate}
                  id='header'
                  onChange={(value) => {
                    setDetails((prevState) => ({
                      ...prevState,
                      header: value,
                    }));
                  }}
                  width={isLandscapChecked ? 793 : 725}
                  height={300}
                  initialValue={initialValues?.header}
                  imageOrFileUploadCompletePercentage={(completePercentage) =>
                    null
                  }
                  showVariableDropdown={true}
                  variables={variables}
                  isCheckHeight={true}
                />
              </Box>
            </Grid>

            <Grid item xs={12} className='mt-4'>
              <Box className={classes.tinyMCEContainer}>
                <Typography
                  className={classes.headingLabel}
                  variant='h5'
                  component='h5'>
                  Above Table
                </Typography>
                <TinyMce
                  disabledEditor={!hasPermissionToUpdate}
                  id='aboveTable'
                  onChange={(value) => {
                    setDetails((prevState) => ({
                      ...prevState,
                      aboveTable: value,
                    }));
                  }}
                  width={isLandscapChecked ? 793 : 725}
                  height={400}
                  initialValue={initialValues?.aboveTable}
                  imageOrFileUploadCompletePercentage={(completePercentage) =>
                    null
                  }
                  variables={variables}
                  showVariableDropdown={true}
                />
              </Box>
            </Grid>
            <Grid item xs={12} className='mt-4'>
              <Box className={classes.tinyMCEContainer}>
                <Typography
                  className={classes.headingLabel}
                  variant='h5'
                  component='h5'>
                  Below Table
                </Typography>
                <TinyMce
                  disabledEditor={!hasPermissionToUpdate}
                  id='belowTable'
                  onChange={(value) => {
                    setDetails((prevState) => ({
                      ...prevState,
                      belowTable: value,
                    }));
                  }}
                  width={isLandscapChecked ? 793 : 725}
                  height={400}
                  initialValue={initialValues?.belowTable}
                  imageOrFileUploadCompletePercentage={(completePercentage) =>
                    null
                  }
                  variables={variables}
                  showVariableDropdown={true}
                />
              </Box>
            </Grid>

            <Grid item xs={12} className='mt-4'>
              <Box className={classes.tinyMCEContainer}>
                <Typography
                  className={classes.headingLabel}
                  variant='h5'
                  component='h5'>
                  Footer
                </Typography>
                <TinyMce
                  disabledEditor={!hasPermissionToUpdate}
                  id='footer'
                  onChange={(value) => {
                    setDetails((prevState) => ({
                      ...prevState,
                      footer: value,
                    }));
                  }}
                  width={isLandscapChecked ? 793 : 725}
                  height={300}
                  initialValue={initialValues?.footer}
                  imageOrFileUploadCompletePercentage={(completePercentage) =>
                    null
                  }
                  showVariableDropdown={true}
                  variables={variables}
                  isCheckHeight={true}
                />
              </Box>
            </Grid>
            <CustomTable
              resource={resource}
              classes={classes}
              table={table}
              setTable={setTable}
            />
          </Paper>
        </div>
      </div>
    </Layout>
  );
}
