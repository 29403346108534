import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography, IconButton } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import moment from 'moment';

import UsersTab from './UsersTab';
import Container from '../../../components/Container';
import Layout from '../../../components/Layout';
import CustomHeader from '../../../components/DetailsPageHeader';
import BoxWithBorder from '../../../components/BoxWithBorder';
import { brandListing } from '../../../routes/brand';
import ConfirmationDialog from '../../../components/Helpers/ConfirmationDialog';
import DetailsPage from '../../../components/Shared/DetailsPage';
import { getErrorMessage } from '../../../services/util';
import CommonSkeleton from '../../../components/Helpers/CommonSkeleton';
import GlobalRoles from './GlobalRoles';
import CreateUser from '../../Users/CreateUser';
import UpdateDetailsDialog from '../../../components/Shared/UpdateDetailsDialog';
import routes from './../../../components/Helpers/Routes';
import CustomBreadCrumbs from './../../../components/CustomBreadCrumbs';
import CreateRoleDialog from '../../../Pages/Roles/CreateRoles';
import DeleteButton from './../../../components/Helpers/DeleteButton';
import { CustomToastContext } from '../../../StateProvider/CustomToastContext/CustomToastContext';
import axiosInstance from '../../../axios/axiosInstance';
import './account.scss';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    borderRadius: '10px',
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'block',
  },
}))(MuiAccordionDetails);

const BrandDetails = () => {
  const history = useHistory();
  const { setToastConfig } = useContext(CustomToastContext);
  const [headingLbl, setHeadingLbl] = useState('');
  const [usersData, setUsersData] = useState([]);
  const [gloabalRoles, setGloabalRoles] = useState([]);
  const [currentGloabalRoles, setCurrentGloabalRoles] = useState([]);
  const [currentUsersData, setCurrentUsersData] = useState([]);
  const [isUpdating, setUpdating] = useState(false);
  const [brandData, setBrandData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [brandFields, setBrandFields] = useState([]);
  const [mainPoints, setMainPoints] = useState({});
  const [deleteUserRec, setDeleteUserRec] = useState(undefined);
  const [customizedRoutes, setCustomizedRoutes] = useState([]);
  const [roleDeleteRec, setRoleDeleteRec] = useState(undefined);
  const [expanded, setExpanded] = React.useState({
    user: true,
    globalRole: true,
  });
  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [showCreateRoleDialog, setShowCreateRoleDialog] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchBrandData();
      fetchUsers();
      fetchRoles();
    }

    //eslint-disable-next-line
  }, [id]);

  const fetchBrandData = async () => {
    setLoading(true);

    axiosInstance()
      .get(`/sa-brand/${id}`)
      .then(({ data: { data } }) => {
        setCustomizedRoutes([routes.brand, { title: data.companyName }]);
        setHeadingLbl(data.companyName || '');
        handleMainPonts(data);
        setBrandData(data);

        if (brandFields.length === 0) {
          getBrandFields();
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchUsers = () => {
    setUserLoading(true);
    axiosInstance()
      .get(`/sa-user?filterById=[{"field": "brand", "term": "${id}"}]`)
      .then(({ data: { data } }) => {
        data = data.map((curUser) => {
          curUser.name =
            (curUser.firstName || '') + '  ' + (curUser.lastName || '');
          return curUser;
        });
        setUsersData(data);
        if (data && data.length) {
          setCurrentUsersData(data.slice(0, 2));
        } else if (currentUsersData.length) {
          setCurrentUsersData([]);
        }
        setUserLoading(false);
      })
      .catch(() => {
        setUserLoading(false);
      });
  };

  const fetchRoles = () => {

    axiosInstance()
      .get(`/sa-role?filterById=[{"field": "brand", "term": "${id}"}]`)
      .then(({ data: { data } }) => {
        setGloabalRoles(data);
        if (data && data.length) {
          setCurrentGloabalRoles(data.slice(0, 6));
        } else if (currentGloabalRoles.length) {
          setCurrentGloabalRoles([]);
        }
      })
      .catch((err) => {
        setToastConfig(err);
      });
  };

  const handleMainPonts = (data) => {
    let tMainPoints = { ...mainPoints };
    if (data.servicesAccess && data.servicesAccess.length) {
      let lbl = '';
      data.servicesAccess.forEach((d) => {
        lbl = lbl + d || '';
      });
      tMainPoints['Services'] = lbl;
    }

    if (data?.currency?.currencyCode) {
      tMainPoints['Currency'] = data.currency.currencyCode;
    }
    if (data?.createdAt) {
      tMainPoints['Creation Date'] = moment(data.createdAt).format(
        'MMM Do, YYYY',
      );
    }
    setMainPoints({ ...tMainPoints });
  };

  const getBrandFields = () => {
    axiosInstance()
      .get(`/sa-field?brand=${id}&resource=Brand`)
      .then(({ data: { data } }) => {
        setBrandFields(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setToastConfig(err);
      });
  };

  const handleDeleteAcc = () => {
    if (brandData?._id) {
      axiosInstance()
        .put('/sa-brand/remove', { ids: [brandData._id] })
        .then(({ data: { data } }) => {
          setToastConfig({
            message: 'Successfully Deleted Brand',
            type: 'success',
            open: true,
          });
          goBackToListing();
          setShowConfirmBox(false);
        })
        .catch((err) => {
          handleErrMes(err, true);
        });
    } else {
      setShowConfirmBox(false);
    }
  };

  const handleErrMes = (err, hideConfirmBox) => {
    let errMes = getErrorMessage(err);
    if (errMes) {
      setToastConfig(err);
    }
    if (hideConfirmBox) {
      setShowConfirmBox(false);
      if (roleDeleteRec) setRoleDeleteRec(undefined);
      if (deleteUserRec) setDeleteUserRec(undefined);
    }
  };

  const handleUpdateBrand = (values) => {
    setUpdating(true);
    const updatedData = {
      ...values,
      _id: brandData._id,
    };
    axiosInstance()
      .put('/sa-brand', updatedData)
      .then(() => {
        fetchBrandData();
        setToastConfig({
          message: 'Successfully saved',
          type: 'success',
          open: true,
        });
        setUpdating(false);
        closeUpdateDIalog();
      })
      .catch((err) => {
        setToastConfig(err);
        setUpdating(false);
      });
  };
  const goBackToListing = () => {
    history.push({
      pathname: brandListing.path,
    });
  };

  const handleDeleteUser = (rec) => {
    setDeleteUserRec(rec);
    setShowConfirmBox(true);
  };
  const DeleteUser = () => {
    if (deleteUserRec) {
      axiosInstance()
        .put('/sa-user/remove', { ids: [deleteUserRec._id] })
        .then(({ data }) => {
          setToastConfig({
            message: data.message,
            type: 'success',
            open: true,
          });
          fetchUsers();
          setShowConfirmBox(false);
          setDeleteUserRec(undefined);
        })
        .catch((err) => {
          handleErrMes(err, true);
        });
    } else {
      setShowConfirmBox(false);
      setDeleteUserRec(undefined);
    }
  };

  const handleDeleteGlobalRole = (rec) => {
    setRoleDeleteRec(rec);
    setShowConfirmBox(true);
  };

  const deleteRole = () => {
    if (roleDeleteRec) {
      axiosInstance()
        .put('/sa-role/remove', { ids: [roleDeleteRec._id] })
        .then(({ data }) => {
          setToastConfig({
            message: data.message,
            type: 'success',
            open: true,
          });
          fetchRoles();
          setShowConfirmBox(false);
          setRoleDeleteRec(undefined);
        })
        .catch((err) => {
          handleErrMes(err, true);
        });
    } else {
      setShowConfirmBox(false);
      setRoleDeleteRec(undefined);
    }
  };

  const handleCreateRole = () => {
    setShowCreateRoleDialog(true);
  };

  const handleGlobalViewAll = () => {
    history.push({
      pathname: '/global-roles',
      state: {
        id: brandData._id,
        name: brandData.companyName,
      },
    });
  };

  const handleViewAllUsers = () => {
    history.push({
      pathname: '/users',
      state: {
        id: brandData._id,
        name: brandData.companyName,
      },
    });
  };

  const handleOpneUpdateDialog = () => {
    setOpenUpdateDialog(true);
  };

  const closeUpdateDIalog = () => {
    setOpenUpdateDialog(false);
  };
  const handleCloseCreateRole = () => {
    setShowCreateRoleDialog(false);
    fetchRoles();
  };
  return (
    <>
      <Layout>
        {openUpdateDialog && (
          <UpdateDetailsDialog
            title={`Editing  ${brandData.companyName}`}
            openDialog={openUpdateDialog}
            onClose={closeUpdateDIalog}
            data={brandData}
            fields={brandFields}
            isUpdating={isUpdating}
            handleUpdate={handleUpdateBrand}
          />
        )}
        <Grid container direction='row'>
          <CustomBreadCrumbs routes={customizedRoutes} />
        </Grid>

        <div>
          {
            <CustomHeader
              loading={loading}
              heading={headingLbl}
              logo={brandData?.companyLogo ? brandData.companyLogo : undefined}
              mainPoints={{
                ...mainPoints,
                'No.Of Users': usersData.length,
              }}
              // style={{ marginTop: "150px", minHeight: "200px" }}
              showHeading={true}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleOpneUpdateDialog}>
                Edit
              </Button>
              <Box component='span' marginX={1} />
              <DeleteButton
                text='Delete'
                action={() => {
                  setShowConfirmBox(true);
                }}
              />
            </CustomHeader>
          }

          <Grid container spacing={2}>
            <Grid item sm={8} md={8} lg={8}>
              <Container styles={{ padding: '8px' }}>
                <BoxWithBorder styles={{ padding: '8px' }}>
                  {loading ? (
                    <Grid container spacing={2}>
                      <CommonSkeleton lenArray={[...Array(12).keys()]} />
                    </Grid>
                  ) : (
                    <Box>
                      <DetailsPage data={brandData} fields={brandFields} />
                    </Box>
                  )}
                </BoxWithBorder>
                <Box marginY={2} />

                <Container
                  styles={{
                    padding: '0px',
                    minHeight: 'auto',
                  }}>
                  {/* onChange={handleChange('panel1')} */}
                  <Accordion
                    square
                    expanded={expanded.user}
                    className='accordian-box'>
                    <AccordionSummary
                      className='accordian-header'
                      aria-controls='user-panel-content'
                      id='user-panel-header'>
                      <Grid container>
                        <Grid item xs={8}>
                          <Box display='flex'>
                            <Box>
                              <IconButton
                                size='small'
                                onClick={() => {
                                  setExpanded((oldState) => {
                                    return {
                                      ...oldState,
                                      user: !expanded.user,
                                    };
                                  });
                                }}>
                                {expanded.user === true ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                            </Box>
                            <Box padding='5px'>
                              <Typography variant='subtitle2'>
                                Users ({usersData.length})
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4} container justifyContent='flex-end'>
                          <IconButton
                            color='primary'
                            size='small'
                            onClick={() => setShowCreateUserDialog(true)}>
                            <ControlPointIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      {userLoading ? (
                        <CommonSkeleton lenArray={[...Array(4).keys()]} />
                      ) : (
                        <>
                          <Grid container spacing={2}>
                            <Grid item md={12} className='w-100'>
                              <UsersTab
                                data={currentUsersData}
                                brand={brandData}
                                onDeleteUser={handleDeleteUser}
                                expanded={expanded['user']}
                              />
                            </Grid>
                            {currentUsersData.length > 0 ? (
                              <Grid item md={12} sm={12} xs={12}>
                                <Button
                                  variant='contained'
                                  color='primary'
                                  size='small'
                                  // onClick={handleViewAllUser}
                                  onClick={handleViewAllUsers}
                                  fullWidth>
                                  View All
                                </Button>
                              </Grid>
                            ) : null}
                          </Grid>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Container>
              </Container>
            </Grid>

            {/* Global Roles */}
            <Grid
              item
              sm={4}
              md={4}
              lg={4}
              className='customGrid brandDetialsGlobalRolesDiv'>
              <Container styles={{ padding: '8px', minHeight: 'auto' }}>
                {/* onChange={handleChange('panel1')} */}
                <Accordion
                  square
                  expanded={expanded.globalRole}
                  className='accordian-box'>
                  <AccordionSummary
                    className='accordian-header'
                    aria-controls='global-role-panel-content'
                    id='global-role-panel-header'>
                    <Grid container>
                      <Grid item xs={8}>
                        <Box display='flex'>
                          <Box>
                            <IconButton
                              size='small'
                              onClick={() => {
                                setExpanded((oldState) => {
                                  return {
                                    ...oldState,
                                    globalRole: !expanded.globalRole,
                                  };
                                });
                              }}>
                              {expanded.globalRole === true ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </Box>
                          <Box padding='5px'>
                            <Typography variant='subtitle2'>
                              Global Roles ({gloabalRoles.length || 0})
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4} container justifyContent='flex-end'>
                        <IconButton
                          color='primary'
                          size='small'
                          onClick={handleCreateRole}>
                          <ControlPointIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {userLoading ? (
                      <CommonSkeleton lenArray={[...Array(4).keys()]} />
                    ) : (
                      <>
                        <GlobalRoles
                          // brand={brandData}
                          data={currentGloabalRoles}
                          onDeleteGlobalRole={handleDeleteGlobalRole}
                        />
                        {currentGloabalRoles.length > 0 ? (
                          <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            size='small'
                            // onClick={handleViewAllRoles}
                            onClick={handleGlobalViewAll}>
                            View All
                          </Button>
                        ) : null}
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Container>
            </Grid>
          </Grid>

          {showConfirmBox ? (
            <ConfirmationDialog
              open={showConfirmBox}
              message={
                deleteUserRec?._id
                  ? `Are you sure you want to delete this User ${deleteUserRec.name || ''
                  }`
                  : roleDeleteRec?._id
                    ? `Are you sure you want to delete this Role ${roleDeleteRec.name || ''
                    }`
                    : `Are you sure you want to delete this Brand ${brandData.companyName || ''
                    }`
              }
              onClose={() => setShowConfirmBox(false)}
              onOk={
                deleteUserRec?._id
                  ? DeleteUser
                  : roleDeleteRec?._id
                    ? deleteRole
                    : handleDeleteAcc
              }
            />
          ) : null}
          {showCreateUserDialog && (
            <CreateUser
              openDialog={showCreateUserDialog}
              brand_id={brandData._id}
              name={brandData.companyName}
              role={null}
              onClose={() => setShowCreateUserDialog(false)}
              onSuccess={() => {
                setShowCreateUserDialog(false);
                fetchUsers();
              }}
            />
          )}
          {showCreateRoleDialog ? (
            <CreateRoleDialog
              isOpen={showCreateRoleDialog}
              onClose={() => setShowCreateRoleDialog(false)}
              brandId={brandData._id}
              name={brandData.companyName}
              onOk={handleCloseCreateRole}
            />
          ) : null}
        </div>
      </Layout>
    </>
  );
};

export default BrandDetails;
