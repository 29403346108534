import { useState, useEffect, useContext } from 'react';
import { Dialog, IconButton, CircularProgress } from '@material-ui/core';
import CustomDialogHeader from './CustomDialog/CustomDialogHeader';
import CustomDialogContent from './CustomDialog/CustomDialogContent';
import CustomDialogFooter from './CustomDialog/CustomDialogFooter';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Formik, Form } from 'formik';
import RichTextEditor from 'react-rte';
import Skeleton from '@material-ui/lab/Skeleton';
import { object, string, array } from 'yup';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import emailStyles from './email.module.scss';
import { GoArrowDown } from 'react-icons/go';
import { Paper, FormControlLabel, Switch } from '@material-ui/core';
import axiosInstance from '../axios/axiosInstance';
import TinyMce from './TinyMCE';
import {
  validations,
  imageUploadMaxSize,
  dateTimeFormat,
} from '../constants/helpers';
import { CustomToastContext } from '../StateProvider/CustomToastContext/CustomToastContext';
import ImagePreview from './ImagePreview';
import ImageAttachments from './ImageAttachments';
import { fileIcons } from './FileIcons';
import { CreateNewBulkEmail } from '../axios/brand';
import { GetBrands } from '../axios/index';

const useStyles = makeStyles(() => ({
  textEditor: {
    fontFamily: 'inherit',
    border: 'none',
  },
  box: {
    border: 1,
  },
  root: {
    width: '80%',
  },
}));

const CreateEmail = ({
  emailId,
  handleCloseEmail,
  isMinimized,
  onMinimizeMaximize,
  showManimizeMaximize,
  showESign = false,
  options = [],
  generatingFile = false,
  fetchBulkEmails,
}) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [otherAttachments, setOtherAttachments] = useState([]);
  const [fileImageAttachments, setFileImageAttachments] = useState([]);
  const [imageAttachments, setImageAttachments] = useState([]);
  const [imageSource, setImageSource] = useState(null);
  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [uploadingImageOrFileProgress, setUploadingImageOrFileProgress] =
    useState(0);
  const [brandSelected, setBrandSelected] = useState(false);
  const [toSelected, setToSelected] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [, setUploading] = useState(false);
  const [brandEmails, setBrandEmails] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const toastConfig = useContext(CustomToastContext);
  const [quoteBuilderOtherAttachments, setQuoteBuilderOtherAttachments] =
    useState([]);
  const [toogle, setToogle] = useState({
    'e-Sign': false,
  });
  const classes = useStyles();

  useEffect(() => {
    let initialData = {
      subject: '',
      file: '',
      content: RichTextEditor.createEmptyValue(),
      to: [],
      cc: [],
    };
    setInitialValues(initialData);
    setFormValues(initialData);
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    try {
      let data = await GetBrands();
      let brands = data.data.map((item) => {
        return { companyName: item.companyName, id: item._id };
      });
    
      setBrandOptions(brands);
    } catch (error) {
      console.error(error);
    }
  };

  


  const checkImageUrl = (url) => {
    let extension = url.substring(url.lastIndexOf('.')).toLowerCase();
    let imageExtensions = [
      '.tif',
      '.tiff',
      '.bmp',
      '.jpg',
      '.jpeg',
      '.gif',
      '.png',
      '.eps',
      '.raw',
      '.cr2',
      '.nef',
      '.orf',
      '.sr2',
    ];
    return imageExtensions.indexOf(extension) >= 0;
  };
  const onKeyPress = (event) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };
  const EmailSchema = object().shape({
    subject: string().required('please enter subject'),
    bcc: array()
      .min(0, 'Please enter a valid email')
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(string().email(({ value }) => `${value} is not a valid email`)),
    // to: emailSchemaHelper.min(1),
    // cc: emailSchemaHelper,   //  Commented by punit
  });
  const handleChangePermissions = (e) => {
    setToogle((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  };


  const handleSave = async (values) => {
    try {
      let payload = {
        message: values.content,
        brand: values.brand,
        to: values.to,
        cc: values.cc,
        subject: values.subject,
        attachment:
          (otherAttachments.length || fileImageAttachments.length)
            ? [
                ...imageAttachments,
                ...otherAttachments,
                ...fileImageAttachments,
              ]
            : [...imageAttachments]
      };
      setSending(true);
      CreateNewBulkEmail(payload)
        .then((data) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: 'Email Sent Successfully',
          });
          setInitialValues(null);
          setSending(false);
          handleCloseEmail();
          fetchBulkEmails();
        })
        .catch((err) => {
          setSending(false);
          toastConfig.setToastConfig(err);
        });
    } catch (e) {}
  };

  const handleUploadImage = (event) => {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      if (file.size > imageUploadMaxSize.size) {
        toastConfig.setToastConfig({
          open: true,
          type: 'error',
          message: `Image must be less than ${imageUploadMaxSize.text} size`,
        });
      } else {
        getImageUrl(file);
      }
    }
  };

  const getImageUrl = (file) => {
    let formData = new FormData();
    formData.append('file', file);
    setUploading(true);
    axiosInstance()
      .post('/user/upload-public', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => {
        setImageAttachments((prevState) => [...prevState, data.fileUrl]);
        setUploading(false);
      })
      .catch((err) => {
        setUploading(false);
        toastConfig.setToastConfig(err);
      });
  };


  const handleValuesChange = (data) => {
    setFormValues((prevState) => ({
      ...prevState,
      ...data,
    }));
  };
  const getFileIconSrc = (file) => {
    let extension = file.substring(file.lastIndexOf('.')).toLowerCase();
    let data = fileIcons.find((o) => o.extensions.indexOf(extension) >= 0);
    if (data && data?.source) return data.source;
  };
  const handleDownloadFile = (file) => {
    const linkSource = `data:${file.contentType};base64,${file.base64}`;
    const link = document.createElement('a');
    link.href = linkSource;
    link.setAttribute('download', `${file.name}`);
    document.body.appendChild(link);
    link.click();
  };
  const handleDeleteAttachment = (url) => {
    setOtherAttachments(
      otherAttachments.filter((currentUrl) => currentUrl !== url),
    );
  };
  const handleDeleteImageAttachment = (url) => {
    setImageAttachments(
      imageAttachments.filter((currentUrl) => currentUrl !== url),
    );
  };
  const handleDeleteFileImageAttachment = (url) => {
    setFileImageAttachments(
      fileImageAttachments.filter((currentUrl) => currentUrl !== url),
    );
  };

  const onUploadFile = (file) => {
    if (checkImageUrl(file)) {
      setFileImageAttachments((prevState) => [...prevState, file]);
    } else {
      setOtherAttachments((prevState) => [...prevState, file]);
    }
  };

  const imageURL = (imageUrl) => {
    setImageAttachments((prevState) => [...prevState, imageUrl]);
  }




  const renderFileThumbnails = (
    <Grid container spacing={1} className={emailStyles.createEmailContainer}>
      {otherAttachments && otherAttachments.length > 0 ? (
        <>
          {otherAttachments.map((attachment, i) => {
            return (
              <>
                <Grid item key={i} sm={3} xs={3} md={3} xl={3}>
                  <Paper className={emailStyles.fileContainer}>
                    <img
                      src={getFileIconSrc(attachment)}
                      className={emailStyles.file}
                      alt='attchment'
                    />
                    <Typography noWrap variant='body2'>
                      {attachment
                        ? attachment.substring(attachment.lastIndexOf('/') + 1)
                        : 'attachment'}
                    </Typography>
                    <div className={emailStyles.fileOverlay}>
                      <Typography variant='subtitle2'>
                        {attachment
                          ? attachment.substring(
                              attachment.lastIndexOf('/') + 1,
                            )
                          : 'attachment'}
                      </Typography>
                      <div className={emailStyles.actionButton}>
                        <IconButton>
                          <GetAppIcon
                            onClick={() => handleDownloadFile(attachment)}
                          />
                        </IconButton>
                        <IconButton className={emailStyles.text}>
                          {emailId ? (
                            <a href={`${attachment} `} download={true}>
                              <GoArrowDown color='white' size={21} />
                            </a>
                          ) : (
                            <DeleteIcon
                              className={emailStyles.deleteIcon}
                              onClick={() => handleDeleteAttachment(attachment)}
                            />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </>
            );
          })}
        </>
      ) : null}
    </Grid>
  );
  return (
    <>
      <CustomDialogHeader
        title='Bulk Email'
        onClose={() => {
          handleCloseEmail();
        }}
        isMinimized={isMinimized}
        onMinimizeMaximize={onMinimizeMaximize}
        showManimizeMaximize={showManimizeMaximize}></CustomDialogHeader>
      {loading ? (
        <div className={classes.root}>
          {[...Array(10).keys()].map((i) => (
            <Typography
              style={{ marginLeft: '20px' }}
              key={`skeleton${i} `}
              variant='subtitle1'>
              <Skeleton animation='wave' />
            </Typography>
          ))}
        </div>
      ) : (
        initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={EmailSchema}
            onSubmit={handleSave}
            onKeyPress={onKeyPress}>
            {({ submitForm, touched, errors, setFieldValue, values }) => (
              <>
                <CustomDialogContent>
                  <Form autoComplete='off' autoCorrect='off' noValidate>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Box padding={1}>
                        <Grid container spacing={3}>
                          {showESign && (
                            <Grid item className='pull-right p-0' xs={12}>
                              <FormControlLabel
                                disabled={!false}
                                key={1}
                                control={
                                  <Switch
                                    checked={toogle['e-Sign']}
                                    name='e-Sign'
                                    onChange={handleChangePermissions}
                                  />
                                }
                                label='e-Sign'
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <TextField
                              autoComplete='off'
                              variant='outlined'
                              type='text'
                              label='Subject'
                              required={true}
                              name='subject'
                              fullWidth
                              margin='dense'
                              value={values['subject']}
                              error={
                                touched['subject'] && Boolean(errors['subject'])
                              }
                              helperText={
                                touched['subject'] && errors['subject']
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  'subject',
                                  e.target.value.trimStart(),
                                );
                                handleValuesChange({
                                  subject: e.target.value.trimStart(),
                                });
                              }}
                            />
                            <Autocomplete
                                 multiple
                              id='virtualize-demo'
                              options={brandOptions}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant='outlined'
                                  label='Brand'
                                  margin='dense'
                                  required={true}
                                />
                              )}
                              getOptionLabel={(option) => option.companyName}
                              value={values['brand']}
                              onChange={(e, value) => {                
                                let val =[]
                                if (value !== null) {
                                for (var brand of value) {
                                    val.push(brand.id);                       
                                }
                                  setFieldValue('brand', val);
                                  handleValuesChange({ brand: val });                               
                                }
                              }}
                            />
                            <Autocomplete
                              multiple
                              disableCloseOnSelect={true}
                              options={options.filter(
                                (option) => values.cc.indexOf(option) < 0,
                              )}
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant='outlined'
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant='outlined'
                                  label='To'
                                  margin='dense'
                                  name='to'
                                />
                              )}
                              value={values['to']}
                              onBlur={(e: any) => {
                                if (
                                  e.target.value &&
                                  e.target.value.trim() != '' &&
                                  validations.email.test(e.target.value)
                                ) {
                                  setFieldValue(
                                    'to',

                                    [...values['to'], e.target.value],
                                  );
                                  handleValuesChange({
                                    to: [...values['to'], e.target.value],
                                  });
                                }
                              }}
                              onChange={(e, value) => {
                                let emails = [];                       
                                  for (var email of value) {
                                    if (validations.email.test(email)) {
                                      emails.push(email);                       
                                    }
                                  }
                                  setFieldValue('bcc', emails);
                                  handleValuesChange({ bcc: emails });
                                } 
                              }
                            />

                            <Autocomplete
                              multiple
                              disableCloseOnSelect={true}
                              options={options.filter(
                                (option) => values.to.indexOf(option) < 0,
                              )}
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant='outlined'
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant='outlined'
                                  label='Cc'
                                  margin='dense'
                                  error={touched['cc'] && Boolean(errors['cc'])}
                                  helperText={touched['cc'] && errors['cc']}
                                  name='cc'
                                />
                              )}
                              value={values['cc']}
                              onBlur={(e: any) => {
                                if (
                                  e.target.value &&
                                  e.target.value.trim() != '' &&
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                    e.target.value,
                                  )
                                ) {
                                  setFieldValue('cc', [
                                    ...values['cc'],
                                    e.target.value,
                                  ]);
                                  handleValuesChange({ cc: e.target.value });
                                }
                              }}
                              onChange={(e, value) => {
                                let val = [];
                                for (var email of value) {
                                  if (validations.email.test(email)) {
                                    val.push(email);
                                  }
                                }
                                setFieldValue('cc', val);
                                handleValuesChange({ cc: val });
                              }}
                            />
                            <Box>
                              {renderFileThumbnails}
                              <ImageAttachments
                                imageAttachments={fileImageAttachments}
                                onImageClick={(attachment) => {
                                  setImageSource(attachment);
                                  setOpen(true);
                                }}
                                isCreateOnly={true}
                                onDelete={handleDeleteFileImageAttachment}
                                emailId={emailId}
                              />
                              <ImageAttachments
                                imageAttachments={imageAttachments}
                                onImageClick={(attachment) => {
                                  setImageSource(attachment);
                                  setOpen(true);
                                }}
                                isCreateOnly={true}
                                onDelete={handleDeleteImageAttachment}
                                emailId={emailId}
                              />
                              <TinyMce
                                onChange={(value) => {
                                  setFieldValue('content', value);
                                  handleValuesChange({ content: value });
                                }}
                                initialValue={initialValues?.content}
                                imageOrFileUploadCompletePercentage={(
                                  completePercentage,
                                ) => {
                                  setUploadingImageOrFileProgress(
                                    completePercentage,
                                  );
                                }}
                                doNotShowUploadFile={false}
                                onUploadFile={onUploadFile}
                                onUploadImage={handleUploadImage}
                                usePublicUrlforFileUpload={true}
                                isSendToCustomer={false}
                             
                                
                             
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </MuiPickersUtilsProvider>
                  </Form>
                </CustomDialogContent>
                <CustomDialogFooter>
                  <Button
                    color='primary'
                    size='small'
                    disabled={sending}
                    onClick={() => {
                      handleCloseEmail()
                    }}>
                    Cancel
                  </Button>
                  <Button
                    type='button'
                    size='small'
                    color='primary'
                    variant='contained'
                    disabled={
                      sending ||
                      uploadingImageOrFileProgress > 0 ||
                      generatingFile
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      submitForm();
                    }}>
                    {sending ? (
                      <>
                        <CircularProgress
                          color='inherit'
                          size={14}
                          style={{ marginRight: '10px' }}
                        />
                        Sending ...{' '}
                      </>
                    ) : (
                      'send'
                    )}
                  </Button>
                </CustomDialogFooter>
              </>
            )}
          </Formik>
        )
      )}
      
      {open ? (
        <ImagePreview
          open={open}
          aria-labelledby='customized-dialog-title'
          // heading="image preview"
          heading={
            imageSource
              ? imageSource.substring(imageSource.lastIndexOf('/') + 1)
              : 'image preview'
          }
          close={() => {
            setImageSource(null);
            setOpen(false);
          }}
          image={imageSource}
        />
      ) : null}
    </>
  );
};

export default CreateEmail;
