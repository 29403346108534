import React, { Component } from 'react';

class ErrorBoundry extends Component {
  state = {
    hasError: false,
  };

  componentDidMount() {
    window.onerror = (msg, url, line, col, error) => {
      let extra = !col ? '' : '\ncolumn: ' + col;
      extra += !error ? '' : '\nerror: ' + error;
      console.error(
        'Error: ' + msg + '\nurl: ' + url + '\nline: ' + line + extra,
      );
      this.setState({ hasError: true });
      return true;
    };
    window.onunhandledrejection = (e) => {
      e.preventDefault();
      console.error(e);
      throw new Error(e.reason.stack);
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.log(error, '\nerrorInfo', errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return (
        <h1 style={{ textAlign: 'center', marginTop: '200px' }}>
          Something went wrong!
        </h1>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundry;
