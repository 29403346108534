import { useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';
import { GetBrandResource } from '../../axios/formbuilder';
import { Autocomplete } from '@material-ui/lab';
import { Box, TextField, Typography, IconButton, Button } from '@material-ui/core';
import Loader from '../../components/Loader';
import DataGridCustomToolbar from './../../components/Helpers/DataGridCustomToolbar';
import CustomBreadCrumbs from './../../components/CustomBreadCrumbs';
import routes from './../../components/Helpers/Routes';
import axiosInstance from '../../axios/axiosInstance';
import ImportExport from '../../components/FormBuilder/ImportExport';
import { ExpandMore, Add, Delete } from '@material-ui/icons';
import ArrangeView from '../MasterResource/ArrangeView';

const FormBuilder = () => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandSelectList, setBrandSelectList] = useState(null);
  const [brandResource, setBrandResource] = useState([]);
  const [arrangeViewOpen, setArrangeViewOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBrandList();
  }, []);

  const fetchBrandList = async () => {
    axiosInstance()
      .get('/sa-brand?select=companyName&limit=100')
      .then(({ data: { data } }) => {
        const list = data.map((item:any) => ({
          id: item._id,
          name: item.companyName,
        }))
        setBrandSelectList(list);
      });
  };

  const fetchGetBrandResource = async (brandId) => {
    setLoading(true);
    await GetBrandResource(brandId)
      .then(({ data }) => {
        setBrandResource(data);
        setLoading(false);
      })
      .catch((err) => { });
  };

  const handleChangeBrand = (_, value:{id:string,name:string}) => {
    setSelectedBrand(value);
    fetchGetBrandResource(value.id);
  };

  const columns = [
    {
      field: 'resourceLabel',
      headerName: 'Resource Label',
      flex: 1,
      renderCell: (params) => (
        <Link
          to={
            '/form-builder/resource?brandId=' +
            selectedBrand.id +
            '&resource=' +
            params.row.resource
          }>
          {' '}
          {params.row.resourceLabel}
        </Link>
      ),
    },
    {
      field: 'resource',
      headerName: 'Resource',
      flex: 1,
    },
    {
      field: 'section',
      headerName: 'Section',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          aria-label='Delete'
          onClick={() => {
            deleteResource(params.row.resource);
          }}>
          <Delete fontSize='small' color='error' />
        </IconButton>
      ),
    },
  ];

  const deleteResource = async (resourceName) => {
    setLoading(true);
    axiosInstance().put('/master-resource/remove-brand', { resourceName: resourceName, brands: [selectedBrand.id] })
      .then(({ data }) => {
        fetchGetBrandResource(selectedBrand.id);
      })
      .catch((err) => { });
  };

  return (
    <Layout>
      <CustomBreadCrumbs routes={[routes.formBuilder]} />
      {!brandSelectList ? (
        <Loader text='' />
      ) : (
        <Box p={2} bgcolor='white'>
          <Box display='flex' justifyContent="space-between" alignItems='center'>
            <Autocomplete
              style={{width: 200}}
              value={selectedBrand}
              onChange={handleChangeBrand}
              options={brandSelectList}
              disableClearable
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin='dense'
                  label='Select Brand'
                  variant='outlined'
                />
              )}
            />
           {selectedBrand && <Button
              variant='contained'
              color='primary'
              size='small'
              onClick={() => {
                setArrangeViewOpen(true);
              }}>
              Arrange View
            </Button>}
          </Box>
          {selectedBrand ? (
            <Box marginTop={2}>
              <div
                style={{
                  height: window.innerHeight - 220,
                  width: '100%',
                }}>
                <DataGrid
                  components={{
                    Toolbar: DataGridCustomToolbar,
                  }}
                  rows={brandResource}
                  columns={columns.map((column) => ({
                    ...column,
                    disableClickEventBubbling: true,
                  }))}
                  loading={loading}
                  pageSize={10}
                  pagination
                  density='compact'
                />
              </div>
            </Box>
          ) : (
            <Box marginTop={3} minHeight={window.innerHeight - 220}>
              <Typography variant='body1'>
                Select brand to display resources.
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {arrangeViewOpen && (
        <ArrangeView
          brandId={selectedBrand.id}
          open={arrangeViewOpen}
          close={() => {
            setArrangeViewOpen(false)
            fetchGetBrandResource(selectedBrand.id)
          }}
          resourceData={brandResource.map((resource ) => ({
            ...resource,
            name: resource.resource,
            label: resource.resourceLabel,
            order: resource.id
          }))}
        />
      )}
    </Layout>
  );
};

export default FormBuilder;
