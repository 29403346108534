import { makeStyles } from '@material-ui/core/styles';
import { Grid, Chip, IconButton } from '@material-ui/core';

import { Delete } from '@material-ui/icons';
import { Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import './account.scss';
import { userType } from '../../../constants/helpers';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  actionsItems: {
    color: 'grey',
    float: 'right',
  },
}));

function DisplayData({ label, value, color }) {
  return (
    <div className='cTr'>
      <div className='td1'>
        <Typography color='textSecondary' variant='subtitle1'>
          {label}
        </Typography>
      </div>
      <div className='td2'>
        <Typography component='div' style={{ color: color ? color : '' }}>
          {value}
        </Typography>
      </div>
    </div>
  );
}

export default function UsersTab({ expanded, data, brand, onDeleteUser }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {data && data.length ? (
        <Grid container spacing={1}>
          {data.map((obj, index) => (
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              key={index}
              className='detail-box'>
              <Tooltip
                title={
                  obj?.userType !== userType.brandAdmin
                    ? `Delete User ${obj.name}`
                    : `You don't have permission to delete this User`
                }>
                <span className={classes.actionsItems}>
                  <IconButton
                    disabled={obj?.userType === userType.brandAdmin}
                    onClick={() => onDeleteUser(obj)}
                    size='small'>
                    <Delete
                      color={
                        obj?.userType === userType.brandAdmin
                          ? 'disabled'
                          : 'error'
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Link className='accountNameLink' to={`/users/${obj._id}`}>
                <Typography className='text-capitalize'>
                  {obj?.name ?? ''}
                </Typography>
              </Link>
              <DisplayData
                label='Email'
                value={obj?.email ?? ''}
                color={null}
              />
              <DisplayData
                label='Status'
                value={
                  <Chip
                    size='small'
                    label={
                      brand?.blocked
                        ? 'Inactive'
                        : obj.blocked
                          ? 'Inactive'
                          : 'Active'
                    }
                    className={
                      !brand?.blocked && !obj.blocked
                        ? 'bg-primary'
                        : 'bg-danger'
                    }
                  />
                }
                color={obj.blocked ? 'green' : 'red'}
              />
              <DisplayData
                label='Mobile No.'
                value={obj?.mobileNo ?? ''}
                color={null}
              />
            </Grid>
          ))}
        </Grid>
      ) : null}
    </div>
  );
}

// <div className={`${classes.box} pl-3 pr-3 pb-2`}>
// <div className="d-flex justify-content-space-between align-items-center">
//     <Link className="accountNameLink" to={`/users/${obj._id}`}>
//         <Typography className="text-capitalize">{obj?.name ?? ''}</Typography>
//     </Link>
//     <IconButton onClick={() => onDeleteUser(obj)}>
//         <Delete color="error" size="small" />
//     </IconButton>
// </div>
