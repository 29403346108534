import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useData } from '../StateProvider';

const ProtectedRoute = ({ children, ...rest }) => {
  const {
    state: { user },
  }: any = useData();
  const token = localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user || token ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
};

export default ProtectedRoute;
