import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CreateActivity } from '../../../axios/project';
import { object, string } from 'yup';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: 10,
  },
  boldFont: {
    fontWeight: 500,
  },
}));

const ActivitySchema = object().shape({
  name: string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long')
    .required('name is required'),
});

export const SubActivityList = ({
  activityId,
  projectId,
  type,
  status,
  openAddSub,
  setOpenAddSub,
  fetchActivityDetail,
  childActivity,
  startDate,
  dueDate,
}) => {
  const history = useHistory();

  const handleSave = (values) => {
    values.projectId = projectId;
    values.parentId = activityId;
    values.type = type;
    values.status = status;
    values.startDate = startDate;
    values.dueDate = dueDate;
    CreateActivity(values)
      .then(({ data }) => {
        setOpenAddSub(false);
        fetchActivityDetail();
      })
      .catch((err) => { });
  };

  const handleOpenActivity = (id) => {
    history.push({
      pathname: '/project/board',
      search: '?projectId=' + projectId + '&activityId=' + id,
    });
  };

  const classes = useStyles();

  return (
    <Box mt={3} mb={3}>
      {(childActivity.length > 0 || openAddSub === true) && (
        <Box mb={1}>
          <Typography variant='body2' className={classes.boldFont}>
            Child {type}
          </Typography>
        </Box>
      )}
      {childActivity.map((element, index) => (
        <Box
          key={index}
          border={1}
          onClick={() => handleOpenActivity(element._id)}
          borderColor='grey.300'
          p={1.5}
          mb={1}
          boxShadow={1}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant='body2'>{element.name}</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent='flex-end'>
              <Chip size='small' label={element.status} color='primary' />
            </Grid>
          </Grid>
        </Box>
      ))}
      {openAddSub && (
        <Box>
          <Formik
            initialValues={{ name: '' }}
            validationSchema={ActivitySchema}
            onSubmit={handleSave}>
            {({ submitForm }) => (
              <Form>
                <Field
                  component={TextField}
                  fullWidth
                  margin='dense'
                  type='text'
                  label={type + ' Name'}
                  name='name'
                  variant='outlined'
                />
                <Box mt={1}>
                  <Button
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={submitForm}>
                    Create
                  </Button>
                  <Button
                    variant='contained'
                    size='small'
                    className={classes.marginLeft}
                    disableElevation
                    onClick={() => setOpenAddSub(false)}>
                    {' '}
                    Cancel
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Box>
  );
};
