import { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';

import axiosInstance from './../../axios/axiosInstance';
import Loader from '../../components/Loader';
import CustomDialogHeader from '../CustomDialog/CustomDialogHeader';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AssignRolesDialog({
  rolesDialogOpen,
  onSuccess,
  handleCloseDialog,
  brandId,
  userIds,
  roles
}) {
  const toastConfig = useContext(CustomToastContext);
  const [brandRoles, setBrandRoles] = useState([]);
  const [loadingBrandRoles, setLoadingBrandRoles] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isAssigning, setAssigning] = useState(false);

  useEffect(() => {
    setLoadingBrandRoles(true);
    axiosInstance()
      .get(`/sa-role?filterById=[{"field": "brand", "term": "${brandId}"}]`)
      .then(({ data: { data } }) => {
        setBrandRoles(data.filter(r => !roles.some(item => item?._id === r?._id)));
        setLoadingBrandRoles(false);
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
      });
    // eslint-disable-next-line
  }, []);

  const handleAssignRoles = async () => {
    if (selectedRoles.length) {
      setAssigning(true);

      const dataObj = {
        users: userIds,
        roles: selectedRoles,
      };

      await axiosInstance()
        .put(`/sa-user/add-role`, dataObj)
        .then(() => {
          setAssigning(false);
          onSuccess();
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
        });
    }
  };

  const handleRoleSelection = (e, id) => {
    let tempSelectedRoles = [...selectedRoles];
    let curIndex = tempSelectedRoles.indexOf(id);
    if (e.target.checked) {
      if (curIndex < 0) tempSelectedRoles = [...tempSelectedRoles, id];
    } else if (curIndex >= 0) {
      tempSelectedRoles.splice(curIndex, 1);
    }
    setSelectedRoles(tempSelectedRoles);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={rolesDialogOpen}
      onClose={handleCloseDialog}
      aria-labelledby='assign-roles-dialog'>
      <CustomDialogHeader title='Assign roles'></CustomDialogHeader>

      <DialogContent
        dividers
        style={{ padding: loadingBrandRoles ? '32px' : '0px 10px' }}>
        {loadingBrandRoles ? (
          <Loader text='Loading Roles' />
        ) : brandRoles.length ? (
          <List style={{ padding: 0 }}>
            {brandRoles.map((role) => (
              <ListItem divider key={role._id}>
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    onChange={(e) => handleRoleSelection(e, role._id)}
                    checked={selectedRoles.indexOf(role._id) >= 0}
                    inputProps={{
                      'aria-labelledby': `checkbox-list-label-${role._id}`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={role.name}
                  secondary={role.description}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>All Roles has been assigned</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isAssigning}
          onClick={handleCloseDialog}
          color='primary'>
          Cancel
        </Button>
        <Button
          disabled={!selectedRoles.length || isAssigning}
          onClick={handleAssignRoles}
          color='primary'>
          {isAssigning ? <CircularProgress size={20} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
