import {
  Box,
  Typography,
  Divider,
  Button,
  CircularProgress,
} from '@material-ui/core';
import BoxWithBorder from '../../components/BoxWithBorder';
import Loader from '../../components/Loader';
import CustomToast from '../../components/Helpers/CustomToast';
import { Details } from '../../components/UserTabs';
import { useData } from '../../StateProvider';

function ServicesDash(props) {
  const {
    state: { userFormFields, userLoading },
  }: any = useData();

  const {
    open,
    closeSnackbar,
    errorMsg,
    msgType,
    handleSaveUser,
    setSelectedUser,
    selectedUser,
    isUpdating,
  } = props;

  return (
    <>
      <CustomToast
        open={open}
        close={closeSnackbar}
        message={errorMsg}
        type={msgType}
      />
      <BoxWithBorder styles={{ minHeight: '300px' }}>
        {userLoading || Object.keys(selectedUser).length === 0 ? (
          <Loader
            style={{ marginTop: 100 }}
            text='Hang on! collecting data for you'
          />
        ) : (
          <>
            <Box
              padding={1}
              bgcolor='#E6F4FF'
              display='flex'
              justifyContent='space-between'
              alignItems='center'>
              <Typography>
                {selectedUser
                  ? `${selectedUser.firstName || ''} ${
                      selectedUser.lastName || ''
                    }`
                  : ''}
              </Typography>
              <Box>
                {isUpdating ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant='text'
                    color='primary'
                    onClick={handleSaveUser}>
                    Update
                  </Button>
                )}
              </Box>
            </Box>
            <Divider style={{ marginBottom: '10px' }} />
            <Details
              user={selectedUser}
              fields={userFormFields}
              setSelectedUser={setSelectedUser}
            />
          </>
        )}
      </BoxWithBorder>
    </>
  );
}
export default ServicesDash;
