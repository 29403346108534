const FieldList = {
  SINGLELINE: {
    label: "Single Line",
    type: "singleLine",
    icon: "fas fa-file-signature",
  },
  MULTILINE: {
    label: "Multi-Line",
    type: "multiLine",
    icon: "fas fa-text-height",
  },
  EMAIL: {
    label: "Email",
    type: "email",
    icon: "fas fa-text-height",
  },
  MOBILENUMBER: {
    label: "Mobile No",
    type: "mobileNumber",
    icon: "fas fa-text-height",
  },
  DROPDOWN: {
    label: "Dropdown",
    type: "dropDown",
    icon: "far fa-calendar-check",
  },
  MULTISELECT: {
    label: "Multi-Select",
    type: "multiSelect",
    icon: "far fa-calendar-check",
  },
  DATE: {
    label: "Date",
    type: "date",
    icon: "far fa-calendar-check",
  },
  YEAR: {
    label: "Year",
    type: "year",
    icon: "far fa-calendar-check",
  },
  DATETIME: {
    label: "Date Time",
    type: "dateTime",
    icon: "far fa-calendar-check",
  },
  NUMBER: {
    label: "Number",
    type: "number",
    icon: "far fa-calendar-check",
  },
  DECIMAL: {
    label: "Decimal",
    type: "decimal",
    icon: "far fa-calendar-check",
  },
  CURRENCY: {
    label: "Currency",
    type: "currency",
    icon: "far fa-calendar-check",
  },
  CURRENCYAMOUNT: {
    label: "Currency Amount",
    type: "currencyAmount",
    icon: "far fa-calendar-check",
  },
  PERCENT: {
    label: "Percent",
    type: "percent",
    icon: "far fa-calendar-check",
  },
  FORMULA: {
    label: "Formula",
    type: "formula",
    icon: "far fa-calendar-check",
  },
  VLOOKUPDROPDOWN: {
    label: "Vlookup Dropdown",
    type: "vlookupDropdown",
    icon: "far fa-calendar-check",
  },
  CONVERTER: {
    label: "Converter",
    type: "converter",
    icon: "far fa-calendar-check",
  },
  RADIO: {
    label: "Radio",
    type: "radio",
    icon: "far fa-calendar-check",
  },
  SWITCH: {
    label: "Switch",
    type: "switch",
    icon: "far fa-calendar-check",
  },
  CHECKBOX: {
    label: "Checkbox",
    type: "checkBox",
    icon: "far fa-calendar-check",
  },
  URL: {
    label: "URL",
    type: "url",
    icon: "far fa-calendar-check",
  },
  FILEUPLOAD: {
    label: "File Upload",
    type: "fileUpload",
    icon: "far fa-calendar-check",
  },
  IMAGEUPLOAD: {
    label: "Image Upload",
    type: "imageUpload",
    icon: "far fa-calendar-check",
  },
  NAME: {
    label: "Name",
    type: "name",
    icon: "far fa-calendar-check",
  },
  LOCATION: {
    label: "Address",
    type: "location",
    icon: "far fa-calendar-check",
  },
  PROCESS: {
    label: "Process",
    type: "process",
    icon: "far fa-calendar-check",
  },
  FREESTYLEMULTISELECT: {
    label: "Free Style Multi Select",
    type: "freeStyleMultiSelect",
    icon: "far fa-calendar-check",
  },
  COLORPICKER: {
    label: "Color Picker",
    type: "colorPicker",
    icon: "far fa-calendar-check",
  },
  MULTIIMAGEUPLOAD: {
    label: "Multi Image Upload",
    type: "multiImageUpload",
    icon: "far fa-calendar-check",
  },
  RICHTEXTEDITOR: {
    label: "Rich Text Editor",
    type: "richTextEditor",
    icon: "far fa-calendar-check",
  },
};

export default FieldList;
