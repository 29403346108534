import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Avatar, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import './brandheader.scss';

const CustomHeader = (props) => {
  const {
    mainPoints,
    heading,
    children,
    showHeading,
    logo,
    loading,
    fromRole,
  } = props;
  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Grid container justifyContent='space-between' className='header-panel'>
          <Grid item key='custom-header-heading'>
            {loading ? (
              <Box display='flex' alignItems='center'>
                {!fromRole && (
                  <>
                    <Skeleton variant='circle' width={35} height={35} />
                    <Box mx={1} />
                  </>
                )}
                <Skeleton variant='text' width={150} height={40} />
              </Box>
            ) : showHeading ? (
              <Box display='flex' alignItems='center'>
                {logo ? (
                  <Box mr={1} boxShadow={2} borderRadius='50%'>
                    <Avatar
                      src={logo}
                      style={{
                        width: 34,
                        height: 34,
                      }}
                      alt='acc_logo'
                    />
                  </Box>
                ) : null}
                <Typography variant='h6' color='primary'>
                  {heading}
                </Typography>
              </Box>
            ) : null}
          </Grid>
          <Grid item key='custom-header-children'>
            {children}
          </Grid>
        </Grid>
        <Box display='flex' className='detail-content'>
          {loading ? (
            <Box display='flex' padding={1}>
              {[...Array(2).keys()].map((i) => (
                <React.Fragment key={i}>
                  <Skeleton
                    variant='rect'
                    style={{ marginRight: '10px', borderRadius: '4px' }}
                    width={160}
                    height={70}
                  />
                  <Box marginY={1} />
                </React.Fragment>
              ))}
            </Box>
          ) : mainPoints && Object.keys(mainPoints).length ? (
            Object.keys(mainPoints).map((key, i) => {
              return (
                <React.Fragment key={i}>
                  {mainPoints[key] ? (
                    <Box
                      className='header-detail-box'
                      key={key + i}
                      title={mainPoints[key]}>
                      <Typography
                        align='center'
                        variant='subtitle1'
                        className='text-capitalize header-text text-truncate'>
                        {key}
                      </Typography>
                      <Typography
                        align='center'
                        className='detail-text text-truncate'>
                        {mainPoints[key] || ''}
                      </Typography>
                    </Box>
                  ) : null}
                </React.Fragment>
              );
            })
          ) : null}
        </Box>
      </Paper>
    </React.Fragment>
  );
};

CustomHeader.propTypes = {
  total: PropTypes.any,
  active: PropTypes.any,
  inactive: PropTypes.any,
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
  loading: PropTypes.any,
  logo: PropTypes.any,
  mainPoints: PropTypes.any,
  fromRole: PropTypes.bool,
  showHeading: PropTypes.any,
};

export default CustomHeader;
