import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Badge,
} from '@material-ui/core';
import {
  Notifications,
  Menu as MenuIcon,
} from '@material-ui/icons';
import { Profile } from '../routes/auth';
import { useHistory, Link } from 'react-router-dom';
import UserProfile from './UserProfile';
import { SVG } from '../assets';
import PropTypes from 'prop-types';
import axiosInstance from '../axios/axiosInstance';
import { SET_USER } from '../StateProvider/actionTypes';
import { useData } from '../StateProvider';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    paddingTop: "8px",
    width: '120px',
    [theme.breakpoints.down('sm')]: {
      width: '110px',
      marginRight: 10,
    },
  },
  servicesButton: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sectionMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

const Header = ({ toggleDrawer }) => {
  const { dispatch } = useData();
  const classes = useStyles();
  const history = useHistory();
  const [supportAnchorEl, setSupportAnchorEl] = useState(null);
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const isSupportMenuOpen = Boolean(supportAnchorEl);
  const isServicesMenuOpen = Boolean(servicesAnchorEl);

  const supportMenuClose = () => {
    setSupportAnchorEl(null);
  };

  const serviceMenuClose = () => {
    setServicesAnchorEl(null);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = async (event, option) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (option && option.logout) {
      await axiosInstance().get('/user/logout');
      dispatch({ type: SET_USER, payload: null });
      localStorage.removeItem('token');
      history.push({
        pathname: '/login',
      });
    }

    if (option && option.profile) {
      history.push({
        pathname: Profile.path,
      });
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const supportMenuId = 'support-menu';

  const supportMenu = (
    <Menu
      anchorEl={supportAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      keepMounted
      id={supportMenuId}
      getContentAnchorEl={null}
      style={{ marginTop: '30px', marginLeft: '9px' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isSupportMenuOpen}
      onClose={supportMenuClose}>
      <MenuItem>Option 1</MenuItem>
      <MenuItem>Option 2</MenuItem>
    </Menu>
  );

  const servicesMenuId = 'actions-menu';

  const servicesMenu = (
    <Menu
      anchorEl={servicesAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      keepMounted
      id={servicesMenuId}
      getContentAnchorEl={null}
      style={{ marginTop: '30px', marginLeft: '9px' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isServicesMenuOpen}
      onClose={serviceMenuClose}>
      <MenuItem>OMS</MenuItem>
      <MenuItem>AIM</MenuItem>
      <MenuItem>VCM</MenuItem>
    </Menu>
  );

  return (
    <div>
      {/* <Slide direction='down' in={isSearch}>
        <AppBar elevation={0} position='fixed' style={{ zIndex: 10000 }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='close search'
              onClick={() => setSearch(false)}
              style={{ marginRight: 16 }}>
              <Clear />
            </IconButton>

            <GlobalSearch />
          </Toolbar>
        </AppBar>
      </Slide> */}
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <Box component='div' display='flex' alignItems='center' flexGrow={1}>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label='help'
                color='inherit'
                title='Menu'
                onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            </div>
            <Link to='/'>
              <img
                className={classes.logo}
                src={SVG('LogoNew')}
                alt='equip logo'
                title='eQuipt Home'
              />
            </Link>

            {/* <div className={classes.sectionDesktop}>
              <GlobalSearch />
            </div> */}
          </Box>

          {/* <div className={classes.sectionMobile}>
            <IconButton
              aria-label='help'
              color='inherit'
              title='Menu'
              onClick={() => setSearch(true)}>
              <Search />
            </IconButton>
          </div> */}
          <IconButton
            aria-label='settings'
            color='inherit'
            title='Notifications'>
            <Badge badgeContent={1} color='secondary'>
              <Notifications />
            </Badge>
          </IconButton>
          <UserProfile
            anchorRef={anchorRef}
            open={open}
            onToggle={handleToggle}
            onClose={handleClose}
            onListKeyDown={handleListKeyDown}
          />
        </Toolbar>
      </AppBar>

      {supportMenu}
      {servicesMenu}
    </div>
  );
};

Header.propTypes = {
  toggleDrawer: PropTypes.any,
};

export default Header;
