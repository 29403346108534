import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { TextField } from 'formik-material-ui';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { CreateActivity } from '../../../axios/project';
import { useHistory } from 'react-router-dom';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const TypeList = ['Task', 'Event', 'Case'];

const ActivitySchema = object().shape({
  name: string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long')
    .required('name is required'),
});

export const CreateNew = ({
  open,
  projectId,
  status,
  setOpen,
  fetchActivity,
}) => {
  const history = useHistory();
  const [type, setType] = React.useState('Task');

  const handleSave = (values) => {
    values.projectId = projectId;
    values.parentId = null;
    values.type = type;
    values.status = status;
    CreateActivity(values)
      .then(({ data }) => {
        setOpen(false);
        history.push({
          pathname: '/project/board',
          search: '?projectId=' + projectId + '&activityId=' + data._id,
        });
      })
      .catch((err) => { });
  };

  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={ActivitySchema}
      onSubmit={handleSave}>
      {({ submitForm }) => (
        <Form>
          <Dialog
            aria-labelledby='customized-dialog-title'
            fullWidth
            maxWidth={'xs'}
            open={open}>
            <CustomDialogHeader
              title={`Create ${type}`}
              onClose={() => setOpen(false)}></CustomDialogHeader>
            <DialogContent dividers>
              <Box padding={1}>
                <Grid container>
                  {TypeList.map((data, index) => (
                    <Box
                      style={{ cursor: 'pointer' }}
                      bgcolor={data === type ? 'primary.main' : 'white'}
                      color={data === type ? 'white' : 'black'}
                      key={index}
                      width={100}
                      onClick={() => setType(data)}
                      display='block'
                      border={1}
                      mr={2}
                      borderColor='grey.300'
                      padding={1}
                      alignSelf='center'
                      fontWeight={500}>
                      {data}
                    </Box>
                  ))}
                </Grid>
                <Box pt={2}>
                  <Field
                    component={TextField}
                    fullWidth
                    margin='dense'
                    type='text'
                    label={type + ' Name'}
                    name='name'
                    variant='outlined'
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button color='primary' variant='contained' onClick={submitForm}>
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};
