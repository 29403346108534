import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Layout from '../../../components/Layout';
import { useHistory } from 'react-router-dom';
import { GetRoadmap } from '../../../axios/project';
import Loader from '../../../components/Loader';
import queryString from 'query-string';
import moment from 'moment';

import Calander from './calander';
import ActivityList from './ActivityList';
import CalanderList from './CalanderList';

export default function Roadmap() {
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);
  const { projectId } = parsed;

  const scrollRef = React.useRef(null);
  const executeScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ inline: 'center' });
    }
  };

  const [calendarType, setCalendarType] = useState('month');
  const [activity, setActivity] = useState(null);
  const [projectName, setProjectName] = useState('');

  useEffect(() => {
    fetchActivity();
    // eslint-disable-next-line
  }, []);

  const fetchActivity = async () => {
    await GetRoadmap(projectId)
      .then(({ data }) => {
        setActivity(data.activity);
        setProjectName(data.projectName);
        executeScroll();
      })
      .catch((err) => {});
  };

  let height = window.innerHeight - 220;
  let startDate = moment('2020-01-01');
  let endDate = moment('2022-12-31');
  let totalDay = endDate.diff(startDate, 'days');

  var dayPixel = 0;
  if (calendarType === 'month') {
    dayPixel = 8.5;
  } else if (calendarType === 'week') {
    dayPixel = 35;
  } else {
    dayPixel = 3;
  }

  const handelChangeCalendarType = async (type) => {
    setCalendarType(type);
    setTimeout(() => executeScroll(), 500);
  };

  const taskScroolRef = React.useRef(null);
  const onscroll = (event) => {
    var target = event.nativeEvent.target;
    taskScroolRef.current.scrollTop = target.scrollTop;
  };

  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  return (
    <Layout>
      {activity ? (
        <Box mt={2} p={2} bgcolor='white'>
          <Box mb={1}>
            <Typography variant='h6'>{projectName + ' Roadmap'}</Typography>
          </Box>
          <Box
            border={1}
            borderColor='grey.300'
            display='flex'
            height={height}
            style={{ position: 'relative' }}>
            <Box
              display='flex'
              width='100%'
              height='100%'
              style={{ position: 'absolute' }}>
              <Box
                minWidth={300}
                border={1}
                borderColor='grey.300'
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                }}>
                <Box
                  height={54}
                  bgcolor='grey.200'
                  display='flex'
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                  }}>
                  <Box p={2}>
                    <Typography variant='body2' display='block'>
                      Roadmap
                    </Typography>
                  </Box>
                </Box>
                <div ref={taskScroolRef}>
                  <Box
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden',
                    }}>
                    <ActivityList
                      activity={activity}
                      expanded={expanded}
                      selected={selected}
                      handleToggle={handleToggle}
                      handleSelect={handleSelect}
                    />
                    <Box height={20}></Box>
                  </Box>
                </div>
              </Box>
              <Box
                onScroll={onscroll}
                border={1}
                borderColor='grey.300'
                style={{
                  position: 'relative',
                  overflow: 'auto',
                }}>
                <Calander
                  calendarType={calendarType}
                  dayPixel={dayPixel}
                  startDate={startDate}
                  endDate={endDate}
                />

                <Box
                  width='100%'
                  height='100%'
                  style={{ position: 'absolute', zIndex: 1 }}>
                  <Box
                    style={{
                      position: 'absolute',
                      width: totalDay * dayPixel,
                    }}>
                    <CalanderList
                      activity={activity}
                      expanded={expanded}
                      selected={selected}
                      handleSelect={handleSelect}
                      startDate={startDate}
                      endDate={endDate}
                      totalDay={totalDay}
                    />
                  </Box>
                </Box>
                <Box
                  width={totalDay * dayPixel}
                  height={'100%'}
                  style={{
                    position: 'sticky',
                    top: 0,
                    bottom: 0,
                  }}>
                  <div ref={scrollRef}>
                    <Box
                      height={'100%'}
                      style={{
                        position: 'absolute',
                        left:
                          (100 * moment().diff(startDate, 'days')) / totalDay +
                          '%',
                        width: dayPixel,
                      }}>
                      <Box
                        style={{ margin: 'auto' }}
                        width={3}
                        border={3}
                        borderColor='primary.main'
                        height={'100%'}></Box>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <Box>
              <ButtonGroup disableElevation color='primary'>
                <Button
                  variant={calendarType === 'week' ? 'contained' : 'outlined'}
                  onClick={() => handelChangeCalendarType('week')}>
                  Weeks
                </Button>
                <Button
                  variant={calendarType === 'month' ? 'contained' : 'outlined'}
                  onClick={() => handelChangeCalendarType('month')}>
                  Months
                </Button>
                <Button
                  variant={calendarType === 'quater' ? 'contained' : 'outlined'}
                  onClick={() => handelChangeCalendarType('quater')}>
                  Quaters
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
      ) : (
        <Loader text='' />
      )}
    </Layout>
  );
}
