import React, { useState, Fragment } from 'react';
import RichTextEditor from 'react-rte';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  marginLeft: {
    marginLeft: 10,
  },
  textEditor: {
    fontFamily: 'inherit',
  },
}));

export const RteField = ({
  referenceId,
  name,
  placeholder,
  value,
  onChange,
}) => {
  const [description, setDescription] = useState(
    value
      ? RichTextEditor.createValueFromString(value, 'html')
      : RichTextEditor.createEmptyValue(),
  );
  const [open, setOpen] = React.useState(false);

  const handleSave = () => {
    onChange(referenceId, {
      field: name,
      content:
        description.toString('html') === '<p><br></p>'
          ? ''
          : description.toString('html'),
    });
    setOpen(false);
  };

  const classes = useStyles();
  return open === true ? (
    <Fragment>
      <RichTextEditor
        className={classes.textEditor}
        value={description}
        onChange={setDescription}
      />
      <Box mt={2}>
        <Button
          variant='contained'
          color='primary'
          size='small'
          disableElevation
          onClick={handleSave}>
          {' '}
          Save
        </Button>
        <Button
          variant='contained'
          size='small'
          className={classes.marginLeft}
          disableElevation
          onClick={() => setOpen(false)}>
          {' '}
          Cancel
        </Button>
      </Box>
    </Fragment>
  ) : !value || value === '' ? (
    <Box bgcolor={'grey.200'} p={2} onClick={() => setOpen(true)}>
      <Typography variant='body2'>{placeholder}</Typography>
    </Box>
  ) : (
    <Box mt={2} onClick={() => setOpen(true)}>
      <div dangerouslySetInnerHTML={{ __html: value }} />
    </Box>
  );
};
