import React, { Fragment, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ColoumStatus from './coloumStatus';
import { UserDropdown } from './userDropdown';
import TableChartIcon from '@material-ui/icons/TableChart';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { TimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { GetActivityDetail, UpdateActivity } from '../../../axios/project';
import Chip from '@material-ui/core/Chip';
import { RteField } from './rteField';
import { SubActivityList } from './subActivityList';
import { useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import { Comment } from './comment';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  boldFont: {
    fontWeight: 500,
  },
}));

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other }: any = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const Activitydetail = ({ projectId, activityId, users }) => {
  const history = useHistory();
  const [initialValues, setValues] = useState(null);
  const [openAddSub, setOpenAddSub] = React.useState(false);

  useEffect(() => {
    setValues(null);
    fetchActivityDetail();
    // eslint-disable-next-line
  }, [activityId]);

  const fetchActivityDetail = async () => {
    await GetActivityDetail(activityId)
      .then(({ data }) => {
        setValues(data);
      })
      .catch((err) => {});
  };

  const changeField = async (data) => {
    await UpdateActivity(activityId, data)
      .then(({ data }) => {})
      .catch((err) => {});
  };

  const handleActivityClose = () => {
    history.push({
      pathname: '/project/board',
      search: '?projectId=' + projectId,
    });
  };

  const handelUpdateActivity = async (activityId, data) => {
    await UpdateActivity(activityId, data)
      .then(({ data }) => {
        fetchActivityDetail();
      })
      .catch((err) => {});
  };

  const handelBlur = async (name) => {
    await UpdateActivity(activityId, {
      field: name,
      content: initialValues[name],
    })
      .then(({ data }) => {})
      .catch((err) => {});
  };

  const handelChange = async (name, value) => {
    setValues({ ...initialValues, [name]: moment(value).format('h:mm A') });
  };

  const handelchangeField = async (name, value) => {
    setValues({ ...initialValues, [name]: value });
    await UpdateActivity(activityId, { field: name, content: value })
      .then(({ data }) => {})
      .catch((err) => {});
  };

  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby='customized-dialog-title'
      fullWidth
      maxWidth={'md'}
      open={true}
      onClose={handleActivityClose}>
      {initialValues ? (
        <Fragment>
          <DialogTitle>
            <Chip label={initialValues.type} color='primary' />
          </DialogTitle>
          <DialogContent dividers>
            <Box padding={1}>
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <TextField
                    id='standard-basic'
                    name='name'
                    variant='outlined'
                    label={initialValues.type + ' Name'}
                    margin='dense'
                    fullWidth
                    defaultValue={initialValues.name}
                    onBlur={(event) =>
                      changeField({
                        field: 'name',
                        content: event.target.value,
                      })
                    }
                  />
                  <Box mt={1}>
                    {initialValues.type !== 'Event' && (
                      <Button
                        variant='contained'
                        size='small'
                        disableElevation
                        onClick={() => setOpenAddSub(true)}
                        startIcon={<TableChartIcon />}>
                        {' '}
                        Add a child {initialValues.type.toLowerCase()}
                      </Button>
                    )}
                  </Box>
                  <Box mt={2}>
                    <Typography variant='body2' className={classes.boldFont}>
                      Description
                    </Typography>
                    <Box mt={1}>
                      <RteField
                        referenceId={activityId}
                        placeholder='Add Description'
                        name='description'
                        value={initialValues.description}
                        onChange={handelUpdateActivity}
                      />
                    </Box>
                  </Box>
                  <SubActivityList
                    projectId={initialValues.projectId}
                    activityId={activityId}
                    type={initialValues.type}
                    status={initialValues.status}
                    openAddSub={openAddSub}
                    setOpenAddSub={setOpenAddSub}
                    fetchActivityDetail={fetchActivityDetail}
                    childActivity={initialValues.childActivity}
                    startDate={initialValues.startDate}
                    dueDate={initialValues.dueDate}
                  />
                  <Divider />
                  <Box mt={2}>
                    <Typography variant='body2' className={classes.boldFont}>
                      Activity
                    </Typography>
                    <Box mt={2}>
                      <Comment referenceId={activityId} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box mt={1}>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel id='demo-simple-select-outlined-label'>
                        Status
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        margin='dense'
                        value={initialValues.status}
                        onChange={(event) =>
                          handelchangeField('status', event.target.value)
                        }
                        label='Status'>
                        {ColoumStatus.map((data, index) => (
                          <MenuItem key={index} value={data.status}>
                            {data.status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {initialValues.type !== 'Event' ? (
                      <Fragment>
                        <Box mt={1}>
                          <UserDropdown
                            referenceId={activityId}
                            name='assignee'
                            label='Assignee'
                            placeholder='Assignee'
                            value={initialValues.assignee}
                            onChange={UpdateActivity}
                            multiple={false}
                            users={users}
                          />
                        </Box>
                        <Box mt={1}>
                          <UserDropdown
                            referenceId={activityId}
                            name='reporter'
                            label='Reporter'
                            placeholder='Reporter'
                            value={initialValues.reporter}
                            onChange={UpdateActivity}
                            multiple={false}
                            users={users}
                          />
                        </Box>
                        <Box mt={1}>
                          <Fragment>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                autoOk
                                variant='inline'
                                inputVariant='outlined'
                                label='Start Date'
                                fullWidth
                                format='yyyy/MM/DD'
                                value={initialValues.startDate}
                                minDate={
                                  initialValues.parentData.startDate &&
                                  initialValues.parentData.startDate
                                }
                                onChange={(event) =>
                                  handelchangeField(
                                    'startDate',
                                    moment(event).format('YYYY-MM-DD'),
                                  )
                                }
                                margin='dense'
                              />
                            </MuiPickersUtilsProvider>
                          </Fragment>
                        </Box>
                        <Box mt={1}>
                          <Fragment>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                autoOk
                                variant='inline'
                                inputVariant='outlined'
                                label='Due Date'
                                fullWidth
                                format='yyyy/MM/DD'
                                value={initialValues.dueDate}
                                minDate={initialValues.startDate}
                                maxDate={
                                  initialValues.parentData.dueDate &&
                                  initialValues.parentData.dueDate
                                }
                                onChange={(event) =>
                                  handelchangeField(
                                    'dueDate',
                                    moment(event).format('YYYY-MM-DD'),
                                  )
                                }
                                margin='dense'
                              />
                            </MuiPickersUtilsProvider>
                          </Fragment>
                        </Box>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Box mt={1}>
                          <Fragment>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                autoOk
                                variant='inline'
                                inputVariant='outlined'
                                label='Event Date'
                                fullWidth
                                format='yyyy/MM/DD'
                                value={initialValues.eventDate}
                                onChange={(event) =>
                                  handelchangeField(
                                    'eventDate',
                                    moment(event).format('YYYY-MM-DD'),
                                  )
                                }
                                margin='dense'
                              />
                            </MuiPickersUtilsProvider>
                          </Fragment>
                        </Box>
                        <Box mt={1}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={initialValues.isAllDay}
                                onChange={(event) =>
                                  handelchangeField(
                                    'isAllDay',
                                    event.target.checked,
                                  )
                                }
                                name='checkedA'
                              />
                            }
                            label='All Day'
                          />
                        </Box>
                        {initialValues.isAllDay === false && (
                          <Box mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <TimePicker
                                    variant='inline'
                                    label='Start'
                                    margin='dense'
                                    inputVariant='outlined'
                                    autoOk
                                    value={moment(
                                      initialValues.startTime,
                                      'HH:mm a',
                                    )}
                                    onChange={(value) =>
                                      handelChange('startTime', value)
                                    }
                                    onBlur={() => handelBlur('startTime')}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <TimePicker
                                    variant='inline'
                                    label='End'
                                    margin='dense'
                                    inputVariant='outlined'
                                    value={moment(
                                      initialValues.endTime,
                                      'HH:mm a',
                                    )}
                                    onChange={(value) =>
                                      handelChange('endTime', value)
                                    }
                                    onBlur={() => handelBlur('endTime')}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                        <Box mt={2}>
                          <FormControl variant='outlined' fullWidth>
                            <InputLabel id='demo-simple-select-outlined-label'>
                              Remind me
                            </InputLabel>
                            <Select
                              labelId='demo-simple-select-outlined-label'
                              id='demo-simple-select-outlined'
                              margin='dense'
                              defaultValue={initialValues.remindMe}
                              onChange={(event) =>
                                handelchangeField(
                                  'remindMe',
                                  event.target.value,
                                )
                              }
                              label='Remind me'>
                              <MenuItem value='Never'>Never</MenuItem>
                              <MenuItem value='15min'>
                                15 minutes before
                              </MenuItem>
                              <MenuItem value='30min'>
                                30 minutes before
                              </MenuItem>
                              <MenuItem value='1hour'>1 hour before</MenuItem>
                              <MenuItem value='12hour'>12 hour before</MenuItem>
                              <MenuItem value='1day'>1 day before</MenuItem>
                              <MenuItem value='1week'>1 week before</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box mt={1}>
                          <UserDropdown
                            referenceId={activityId}
                            name='participants'
                            label='Participants'
                            placeholder='Participants'
                            value={initialValues.participants}
                            onChange={UpdateActivity}
                            multiple={true}
                            users={users}
                          />
                        </Box>
                      </Fragment>
                    )}
                    <Box mt={1} color='text.secondary'>
                      <Typography variant='body2'>
                        Created{' '}
                        {moment(initialValues.createdAt).format(
                          'MMM DD YYYY hh:mm A',
                        )}
                      </Typography>
                    </Box>
                    <Box mt={1} color='text.secondary'>
                      <Typography variant='body2'>
                        Updated{' '}
                        {moment(initialValues.updatedAt).format(
                          'MMM DD YYYY hh:mm A',
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions></DialogActions>
        </Fragment>
      ) : (
        <Fragment>
          <Skeleton height={100} />
          <Skeleton height={150} />
          <Skeleton height={100} />
        </Fragment>
      )}
    </Dialog>
  );
};
