import { useState, useEffect, useContext } from 'react';
import Layout from '../../components/Layout';
import CustomBreadCrumbs from './../../components/CustomBreadCrumbs';
import routes from './../../components/Helpers/Routes';
import {
  Box,
  Container,
  Grid,
  Paper,
  Button,
  CircularProgress,
  Card,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { GetBrands } from '../../axios/index';
import axiosInstance from '../../axios/axiosInstance';
import { DataGrid } from '@material-ui/data-grid';
import { AiOutlineUpload } from 'react-icons/all';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import { documentUploadMaxSize } from '../../constants/helpers';

const BrandBackup = () => {
  const [brandOptions, setBrandOptions] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsRestore, setRowsRestore] = useState([]);
  const toastConfig = useContext(CustomToastContext);
  const { setToastConfig } = useContext(CustomToastContext);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [uploadingImageOrFileProgress, setUploadingImageOrFileProgress] =
    useState(0);
  const [selectedBrand, setSelectedBrand] = useState<any>({});
  const [sending, setSending] = useState(false);
  const [isImgUploading, setImgUploading] = useState(false);
  const [isRestoring, setRestoring] = useState(false);
  const [fileName, setFileName] = useState('');
  const [downloading, setDownloading] = useState(false);
  const fileUploadMaxSize = { ...documentUploadMaxSize };

  useEffect(() => {
    fetchBrands();
    fetchExportHistory();
    fetchImportHistory();
  }, []);

  const handleUploadFile = async (e) => {
    let files = e.target.files[0];
    setImageUploadProgress(0);
    let formData = new FormData();
    formData.append('file', files);
    let uploadUrl = '/export/upload-file';
    setImgUploading(true);
    if (uploadingImageOrFileProgress) {
      setUploadingImageOrFileProgress(1);
    }
    axiosInstance()
      .post(uploadUrl, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (pE) => {
          const completedPercent = Math.floor((pE.loaded * 100) / pE.total);
          setImageUploadProgress(completedPercent);
          if (uploadingImageOrFileProgress) {
            setUploadingImageOrFileProgress(completedPercent);
          }
          if (completedPercent === 100) {
            setTimeout(() => {
              setImageUploadProgress(0);
              if (uploadingImageOrFileProgress) {
                setUploadingImageOrFileProgress(0);
              }
            }, 4000);
          }
        },
      })
      .then(({ data }) => {
        setImgUploading(false);
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: 'File uploaded successfully',
        });
        fetchImportHistory();
      })
      .catch((err) => {
        setImgUploading(false);
        setToastConfig(err);
        setImageUploadProgress(0);
        if (uploadingImageOrFileProgress) {
          setUploadingImageOrFileProgress(0);
        }
      });
  };

  const getFileUrl = (file, api, details) => {};

  const fetchExportHistory = async () => {
    axiosInstance()
      .get('/export/export-history')
      .then(({ data }) => {
        setRows(data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetchImportHistory = async () => {
    axiosInstance()
      .get('/export/import-history')
      .then(({ data }) => {
        setRowsRestore(data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchBrands = async () => {
    try {
      let data = await GetBrands();
      let brands = data.data.map((item) => {
        return { companyName: item.companyName, id: item._id };
      });

      setBrandOptions(brands);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      field: 'brand',
      headerName: 'Brand',
      width: 200,
      valueGetter: (params) => {
        return params?.row?.brandDetail?.optionLabel;
      },
    },
    {
      field: 'date',
      headerName: 'Date & Time',
      width: 200,
      valueGetter: (params) => {
        return (
          params.row.date.substring(0, 10) +
          ' ' +
          params.row.date.substring(11, 19)
        );
      },
    },
    {
      field: 'download',
      headerName: 'Action',
      width: 170,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => {
                handleDownloadFile(params.row.fileName);
                setFileName(params.row.fileName);
              }}
              variant='contained'
              color='primary'
              size='small'>
              {downloading && fileName === params.row.fileName ? (
                <>
                  <CircularProgress
                    color='inherit'
                    size={14}
                    style={{ marginRight: '10px' }}
                  />
                  Downloading ...{' '}
                </>
              ) : (
                '  Download'
              )}
            </Button>
          </>
        );
      },
    },
  ];

  const columns2 = [
    {
      field: 'date',
      headerName: 'Date & Time',
      width: 400,
      valueGetter: (params) => {
        return (
          params.row.date.substring(0, 10) +
          ' ' +
          params.row.date.substring(11, 19)
        );
      },
    },
    {
      field: 'download',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => {
                handleRestoreFile(params.row.fileName);
                setFileName(params.row.fileName);
              }}
              variant='contained'
              color='primary'
              size='small'>
              {isRestoring && fileName === params.row.fileName ? (
                <>
                  <CircularProgress
                    color='inherit'
                    size={14}
                    style={{ marginRight: '10px' }}
                  />
                  Restoring ...{' '}
                </>
              ) : (
                '  Restore'
              )}
            </Button>
          </>
        );
      },
    },
  ];

  const handleRestoreFile = (fileName) => {
    setRestoring(true);
    const body = {
      fileName: fileName,
    };
    axiosInstance()
      .post(`/export/import-json`, body)
      .then(({ data: { data } }) => {
        setRestoring(false);
        fetchExportHistory();
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: 'Restored  successfully',
        });
      })
      .catch((error) => {
        setRestoring(false);
        console.error(error, 'error');
        toastConfig.setToastConfig(error);
      });
  };

  const handleBackup = () => {
    setSending(true);
    axiosInstance()
      .get(`/export/export-json/${selectedBrand.id}`)
      .then(({ data: { data } }) => {
        setSending(false);
        fetchExportHistory();
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: 'Backup created successfully',
        });
      })
      .catch((error) => {
        setSending(false);
        console.error(error, 'error');
        toastConfig.setToastConfig(error);
      });
  };

  const handleDownloadFile = (fileName) => {
    setDownloading(true);
    const body = {
      fileName: fileName,
      brand: selectedBrand.id,
    };
    axiosInstance()
      .post(`/export/download-file`, body)
      .then((data) => {
        setDownloading(false);
        let fileText = data?.data?.fileData;
        fileText && download(fileName, fileText);
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: 'File downloaded successfully',
        });
      })
      .catch((error) => {
        console.error(error, 'error');
        toastConfig.setToastConfig(error);
        setDownloading(false);
      });
  };

  function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  return (
    <>
      <Layout>
        <CustomBreadCrumbs routes={[routes.brandBackup]} />
        <Box component='div'>
          <Container style={{ paddingLeft: '0rem' }}>
            <Box style={{ marginTop: '1.5rem' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} md={6}>
                  <Box>
                    <Card variant='outlined'>
                      <Box p={2}>
                        <span className='listingHeader'>Backup</span>
                      </Box>
                      <Box p={2}>
                        <Autocomplete
                          id='virtualize-demo'
                          options={brandOptions}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Brand'
                              margin='dense'
                              required={true}
                            />
                          )}
                          getOptionLabel={(option) => option.companyName}
                          onChange={(e, val) => {
                            setSelectedBrand(val);
                          }}
                        />
                      </Box>
                      <Box p={2}>
                        <Button
                          type='button'
                          size='small'
                          color='primary'
                          variant='contained'
                          onClick={handleBackup}>
                          {sending ? (
                            <>
                              <CircularProgress
                                color='inherit'
                                size={14}
                                style={{ marginRight: '10px' }}
                              />
                              Backing up ...{' '}
                            </>
                          ) : (
                            '  Backup'
                          )}
                        </Button>
                      </Box>
                      <Box p={2}>
                        <span className='listingHeader'>History</span>
                      </Box>
                      <Box p={2}>
                        <div style={{ height: 200, width: '100%' }}>
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row._id}
                          />
                        </div>
                      </Box>
                    </Card>
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6} md={6}>
                  <Box>
                    <Card variant='outlined'>
                      <Box p={2}>
                        <span className='listingHeader'>Restore</span>
                      </Box>
                      <Box p={2}>
                        {isImgUploading && (
                          <>
                            <CircularProgress
                              variant='determinate'
                              value={imageUploadProgress}
                            />
                            <Box>
                              <Typography
                                variant='caption'
                                component='div'
                                color='textSecondary'>{`${imageUploadProgress}%`}</Typography>
                            </Box>
                          </>
                        )}
                        <input
                          id={`file`}
                          name={`file`}
                          onChange={handleUploadFile}
                          style={{ display: 'none' }}
                          onClick={(e: any) => (e.target.value = null)}
                          type='file'
                          accept='.docx,.doc'
                        />
                        <label htmlFor={`file`}>
                          <Button
                            size='small'
                            variant='outlined'
                            component='span'
                            disabled={isImgUploading}
                            startIcon={<AiOutlineUpload />}>
                            Upload File
                          </Button>
                        </label>
                      </Box>
                      <Box p={2}>
                        <span className='listingHeader'>History</span>
                      </Box>
                      <Box p={2}>
                        <div style={{ height: 200, width: '100%' }}>
                          <DataGrid
                            rows={rowsRestore}
                            columns={columns2}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row._id}
                          />
                        </div>
                      </Box>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Layout>
    </>
  );
};

export default BrandBackup;
