import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  FormGroup,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import BoxWithBorder from '../../../components/BoxWithBorder';
import { PERMISSION } from '../../../constants/Roles';
import React from 'react';
import { userType } from '../../../constants/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  list: {
    width: '100%',
  },
}));

const rolePermissions = [PERMISSION.superAdmin, PERMISSION.brandAdmin];
export default function GlobalRoleList({ onUnassignRole, data, type }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormGroup row>
        <div className={classes.demo}>
          <List style={{ padding: 0 }}>
            {data && data.length
              ? data.map((obj) => (
                  <BoxWithBorder
                    key={obj._id}
                    styles={{
                      padding: '0px',
                      margin: '8px',
                    }}>
                    <ListItem className={classes.list}>
                      <ListItemText
                        primary={
                          <Link
                            className='accountNameLink'
                            to={`/global-roles/${obj._id}`}>
                            <Typography> {obj.name || ''}</Typography>
                          </Link>
                        }
                        secondary={obj.description || ''}
                      />
                      <ListItemSecondaryAction>
                      <Tooltip
                          title={
                            rolePermissions.indexOf(obj?.permission) >= 0 &&
                            type === userType.brandAdmin
                              ? `You don't have permission to remove this Role`
                              : `Remove Role ${obj.name}`
                          }>
                          <span>
                          <IconButton
                            edge='end'
                            aria-label='delete'
                            disabled={
                              rolePermissions.indexOf(obj?.permission) >= 0 &&
                              type === userType.brandAdmin
                            }
                            onClick={() => onUnassignRole(obj)}>
                            <DeleteIcon
                              color={
                                rolePermissions.indexOf(obj?.permission) >= 0 &&
                                type === userType.brandAdmin
                                  ? 'disabled'
                                  : 'error'
                              }
                            />
                          </IconButton>
                        </span>
                        </Tooltip>

                      </ListItemSecondaryAction>
                    </ListItem>
                  </BoxWithBorder>
                ))
              : null}
          </List>
        </div>
      </FormGroup>
    </div>
  );
}
