import { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  useMediaQuery,
} from '@material-ui/core';
import Loader from '../../components/Loader';
import RoleEngine from '../../components/Shared/RoleEngine';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import axiosInstance from '../../axios/axiosInstance';
import './index.scss';

const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: '25%',
    display: 'flex',
    background: theme.palette.background.default,
    padding: theme.spacing(0.8),
    marginRight: '10px',
    cursor: 'pointer',
    borderRadius: 0,
    borderBottom: `4px solid #ddd`,
    color: '#aaa',

    '&:hover': {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      background: theme.palette.background.default,
    },

    '&;last-child': {
      marginRight: 0,
    },
  },
  container: {
    maxHeight: 440,
  },
  activeTab: {
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CreateRoles = (props) => {
  const { brandId, name, isOpen, onClose, onOk } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 599px)');
  const { setToastConfig } = useContext(CustomToastContext);
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [field, setField] = useState([]);
  const [resource, setResource] = useState([]);
  const [values, setValues] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    fetchRoleData();
    // eslint-disable-next-line
  }, []);

  const fetchRoleData = () => {
    axiosInstance()
      .get(`/sa-field?brand=${brandId}&resource=Role`)
      .then(({ data: { data } }) => {
        setField(data.field);
        setResource(data.resource);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setToastConfig(err);
      });
  };


  // ******* SAVE ROLES TO DATABASE ********
  const handleSubmitRole = async () => {
    if (values.name && values.description) {
      if (
        resource.some(
          (d) => d.isCreate || d.isRead || d.isUpdate || d.isDelete,
        ) ||
        field.some((d) => d.isCreate || d.isRead || d.isUpdate || d.isDelete)
      ) {
        const rolePostData = {
          brand: brandId,
          role: {
            name: values.name,
            description: values.description,
            field,
            resource
          },
        };
        setIsSaving(true);
        axiosInstance()
          .post('/sa-role', rolePostData)
          .then(({ data }) => {
            setIsSaving(false);
            setToastConfig({
              message: 'Role has been created',
              type: 'success',
              open: true,
            });
            onOk();
          })
          .catch((err) => {
            setToastConfig(err);
            setIsSaving(false);
          });
      } else {
        setToastConfig({
          open: true,
          type: 'error',
          message: 'Please check atleast one permission',
        });
      }
    } else {
      setToastConfig({
        open: true,
        type: 'warning',
        message: 'Role name and description must not be empty',
      });
    }
  };


  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      className='role-Dialog'
      aria-labelledby='customized-dialog-title'
      onClose={onClose}
      open={isOpen}
      maxWidth='md'>
      <CustomDialogHeader title='Create Role' onClose={onClose} />
      <DialogContent dividers>
        <>
       <Typography>
            <strong>Selected Brand: </strong>
            {name}
          </Typography>
          {
            <>
              <Box display='flex' marginBottom={2} marginTop={2} gridGap={10}>
                <TextField
                  required
                  variant='outlined'
                  size='small'
                  fullWidth
                  label='Role Name'
                  value={values.name}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      name: e.target.value.trimStart(),
                    })
                  }
                />

                <TextField
                  required
                  variant='outlined'
                  multiline
                  size='small'
                  fullWidth
                  label='Role Description'
                  value={values.description}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      description: e.target.value.trimStart(),
                    })
                  }
                />
              </Box>
              <Paper>
                <TableContainer className={classes.container}>
                  <Table
                    stickyHeader
                    aria-label='roles'
                    className='roles-table'>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Loader
                              style={{
                                height: '100%',
                              }}
                              text='Loading...'
                            />
                          </TableCell>
                        </TableRow>
                      ) : (
                        field.length &&
                        resource.length && (
                          <RoleEngine
                            isDisable={isSaving}
                            field={field}
                            resource={resource}
                            setField={setField}
                            setResource={setResource}
                          />
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
           }
        </>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isSaving}
          variant='outlined'
          color='primary'
          onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={isSaving}
          variant='contained'
          color='primary'
          onClick={handleSubmitRole}>
          {isSaving ? <CircularProgress size={22} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRoles;
