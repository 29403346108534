import { useState } from 'react';
import Box from '@material-ui/core/Box';
import Layout from '../../components/Layout';
import CustomTabs from '../../components/Helpers/CustomTabs';
import TabPanel from '../../components/TabPanel';
import Grid from '@material-ui/core/Grid';
import Task from './Task';
import Event from './Event';
import Case from './Case';
import Note from './Note';

const Activity = () => {
  const [value, setValue] = useState(0);
  const tabs = ['Task', 'Event', 'Case', 'Note'];

  return (
    <Layout>
      <Grid container>
        <Grid xs={6} item>
          <Box border={1} bgcolor='white' borderColor='grey.300'>
            <CustomTabs value={value} setValue={setValue} tabs={tabs} />
            <TabPanel value={value} index={0}>
              <Task />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Event />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Case />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Note />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Activity;
