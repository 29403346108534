import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Typography,
  Paper,
  Link as MuiLink,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';
import Container from '../../components/Container';
import routes from './../../components/Helpers/Routes';

const useStyles = makeStyles((theme) => ({
  links: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  cardContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  return (
    <Layout>
      <Container maxWidth='md'>
        <Grid spacing={2} container>
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.cardContainer}>
              <Box>
                <Typography variant='h6'>Configuration</Typography>
              </Box>
              <Box className={classes.links}>
                <MuiLink component={Link} to={routes.brand.path}>
                  <Typography paragraph>Brands</Typography>
                </MuiLink>
                <MuiLink component={Link} to={routes.users.path}>
                  <Typography paragraph>Users</Typography>
                </MuiLink>

                <MuiLink component={Link} to={routes.globalRoles.path}>
                  <Typography paragraph>Roles</Typography>
                </MuiLink>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Dashboard;
