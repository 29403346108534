import Box from '@material-ui/core/Box';

const Note = () => {
  return (
    <Box>
      <h5>Note</h5>
    </Box>
  );
};

export default Note;
