import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import {
  ExpandMore,
  Add,
  Delete,
} from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment';
import { FaUsers } from 'react-icons/fa';

import Layout from '../../components/Layout';
import Container from '../../components/Container';
import BrandHeader from '../../components/BrandHeader';
import SearchBox from '../../components/Helpers/SearchBox';
import ConfirmationDialog from '../../components/Helpers/ConfirmationDialog';
import NoDataCell from '../../components/Helpers/NoDataCell';
import DataGridCustomToolbar from './../../components/Helpers/DataGridCustomToolbar';
import routes from './../../components/Helpers/Routes';
import CustomBreadCrumbs from './../../components/CustomBreadCrumbs';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import axiosInstance from '../../axios/axiosInstance';
import { getSearchQuery } from '../../services/util';
import MessageDialog from '../../components/Helpers/MessageDialog';
import './index.scss';
import { Formik, Form } from 'formik';
import InputField from '../../components/Helpers/InputField';
import {
  getObjKeys,
  removeEmptyKeys,
  yupSchema,
} from '../../constants/helpers';

let userTimeout: ReturnType<typeof setTimeout>;
const INACTIVE_STATUS = 'Inactive';
const ACTIVE_STATUS = 'Active';

const Admins = () => {
  const toastConfig = useContext(CustomToastContext);

  const [showCreateAdminDialog, setShowCreateAdminDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataRows, setDataRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [checkAllUsers, setCheckAllUsers] = useState(false);
  const [query, setQuery] = useState({ page: 0, limit: 25 });
  const [renderCount, setRenderCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRecs, setSelectedRecs] = useState([]);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [showWarningBox, setShowWarningBox] = useState(false);
  const [singleUserDelete, setSingleUserDelete] = useState({
    id: null,
    show: false,
    name: '',
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const [adminFieldsData, setAdminFieldsData] = useState({
    fields: [],
    initialVals: {},
  });

  useEffect(() => {
    let millisec = Object.keys(searchVal).length > 0 ? 600 : 5;
    if (userTimeout) {
      clearTimeout(userTimeout);
    }
    userTimeout = setTimeout(() => {
      fetchAdmins();
    }, millisec);
    // eslint-disable-next-line
  }, [searchVal]);

  useEffect(() => {
    if (renderCount > 0) {
      fetchAdmins();
    } else setRenderCount((preCount) => preCount + 1);
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    getFIeldsData();
  }, []);

  const fetchAdmins = () => {
    let searchParams: any = { ...query };
    searchParams = searchVal
      ? { ...searchParams, search: searchVal }
      : searchParams;
    setLoading(true);
    const api = getSearchQuery('/sa-user/super-admin', searchParams);
    axiosInstance()
      .get(api)
      .then(({ data: { data, count } }) => {
        setRowCount(count);
        getRows( data);
        setLoading(false);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
        setLoading(false);
      });
    // eslint-disable-next-line
  }



  // ********* GET ALL ROWS DATA FOR USERS *******
  const getRows = (user) => {
    let rows = user?.map((u) => ({
      id: u._id,
      isChecked: false,
      name: `${u.firstName} ${u.lastName}`,
      blocked: u.brand?.blocked ? true : u.blocked,
      email: u.email,
      status: u.blocked ? INACTIVE_STATUS : ACTIVE_STATUS,
      createdAt: moment(u.createdAt).format('MMM Do, YYYY'),
    }));
    setDataRows(rows);
  };

  const dialogBoxSelection = () => {
    return dataRows.some((d) => d.isChecked && d.isBrandAdmin)
      ? setShowWarningBox(true)
      : setShowConfirmBox(true);
  };

  /**
   *  ADDITIONAL STUFF FOR INITIAL CHECKBOX SELECTION IN COLUMNS
   */
  const columns = [
    {
      field: 'isChecked',
      headerName: 'Checkbox',
      renderHeader: () => (
        <Checkbox
          color='primary'
          checked={checkAllUsers}
          onChange={(ev) => {
            setCheckAllUsers(ev.target.checked);
            const gridData = dataRows;
            gridData.map((d) => {
              d.isChecked = ev.target.checked;
              return d;
            });
            setDataRows([...gridData]);

            const ids = gridData.filter((d) => d.isChecked).map((d) => d.id);
            setSelectedRecs(ids);
          }}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          color='primary'
          checked={params.value}
          onChange={(ev) => {
            const gridData = dataRows;
            const indexOfRecord = gridData.findIndex(
              (d) => d.id === params.row.id,
            );
            gridData[indexOfRecord].isChecked = ev.target.checked;

            setDataRows([...gridData]);

            const checkedRecords = gridData.filter((d) => d.isChecked === true);

            if (checkedRecords.length === gridData.length) {
              setCheckAllUsers(true);
            } else {
              setCheckAllUsers(false);
            }

            handleSelectedEntities(params.row.id, ev.target.checked);
          }}
        />
      ),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      width: 75,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 350,
      renderCell: (params) => (
        <>
          {params?.row?.name ?
            <p className='text-truncate' title={params.value}>{params.row.name}</p>
            : <NoDataCell />}
        </>
      ),
    },

    {
      field: 'status',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      headerName: 'Status',
      width: 150,
      renderCell: (params) =>
        params.value === ACTIVE_STATUS ? (
          <Chip size='small' label={ACTIVE_STATUS} className='bg-primary' />
        ) : (
          <Chip size='small' label={INACTIVE_STATUS} className='bg-danger' />
        ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 350,
      renderCell: (params) =>
        params.value ? (
          <p className='text-truncate' title={params.value}>
            {params.value}
          </p>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'createdAt',
      headerName: 'Created By',
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value ? (
          <h5 title={params?.row?.name} className='createBy'>
            {params?.row?.name}
            <span className='createdAtTime'>{params?.value}</span>
          </h5>
        ) : (
          <NoDataCell />
        ),
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          {params.row?.isBrandAdmin ? (
            <Tooltip
              className='cursor-stop'
              title='Brand Admin can not be deleted'>
              <IconButton aria-label='Delete'>
                <Delete fontSize='small' />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Delete'>
              <IconButton
                aria-label='Delete'
                onClick={() => {
                  setSingleUserDelete({
                    show: true,
                    id: params.row.id,
                    name: params.row.name,
                  });
                }}>
                <Delete fontSize='small' color='error' />
              </IconButton>
            </Tooltip>
          )}

        </>
      ),
    },
  ];

  // ***** COLUMNS STUFF ENDS  ☝☝ THERE *****

  const handleSelectedEntities = (id, isChecked) => {
    let tempSelectedRecs = [...selectedRecs],
      curRecIndex = selectedRecs.indexOf(id);
    if (isChecked && curRecIndex < 0) {
      tempSelectedRecs = [...selectedRecs, id];
    } else if (!isChecked && curRecIndex >= 0) {
      tempSelectedRecs.splice(curRecIndex, 1);
    }
    setSelectedRecs(tempSelectedRecs);
  };

  // ****** ACTIONS BUTTON STUFF *********
  const openActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActions = () => {
    setAnchorEl(null);
  };

  const handleSearch = (e) => {
    if (query.page !== 0) {
      setQuery((prevState) => ({ ...prevState, page: 0 }));
    }
    setSearchVal(e.target.value);
  };

  const handlePage = (params) => {
    if (query.page !== params.page) {
      setQuery((prevState) => ({ ...prevState, page: params.page }));
    }
  };
  const handlePageSize = (params) => {
    if (params.pageSize !== query.limit) {
      setQuery({ page: 0, limit: params.pageSize });
    }
  };
  const handleSortModelChange = (params) => {
    if (params?.sortModel && params.sortModel.length > 0) {
      let temp = { ...params.sortModel[0] };
      if (temp.field === 'name') {
        temp.field = 'firstName';
      }
      if (temp.field === 'status') {
        temp.field = 'blocked';
      }
      setQuery((prevState) => ({
        ...prevState,
        page: 0,
        sortBy: temp.field,
        orderBy: temp.sort,
      }));
    }
  };

  const onFilterChange = (params) => {
    if (
      params.filterModel.items[0].value ||
      params.filterModel.items[0].value === ''
    ) {
      let deepFilter: string;
      if (params.filterModel.items[0].columnField === 'name') {
        deepFilter = JSON.stringify([{ field: "firstName", term: params.filterModel.items[0].value }, { field: "lastName", term: params.filterModel.items[0].value }])
      }
      else {
        deepFilter = JSON.stringify([{ field: params.filterModel.items[0].columnField, term: params.filterModel.items[0].value }])
      }
      setQuery((prevState) => ({
        ...prevState,
        deepFilter
      }));
    }
    else {
      setQuery({ page: 0, limit: 25 });
    }
  }

  const handleDeleteUsers = async () => {
    setLoading(true);
    let recLen = selectedRecs.length;
    if (selectedRecs && recLen > 0) {
      try {
        let { data } = await axiosInstance().put('/sa-user/remove', {
          ids: selectedRecs,
        });
        setLoading(false);
        toastConfig.setToastConfig({
          open: true,
          message: data.message,
          type: 'success',
        });
        fetchAdmins();
      } catch (error) {
        setLoading(false);
        toastConfig.setToastConfig(error);
      }

      setSelectedRecs([]);
    }
    setShowConfirmBox(false);
  };

  const handleSingleDeleteUsers = async () => {
    setLoading(true);
    try {
      let { data } = await axiosInstance().put('/sa-user/remove', {
        ids: [singleUserDelete.id],
      });
      setSingleUserDelete({ show: false, id: null, name: '' });
      setLoading(false);
      toastConfig.setToastConfig({
        open: true,
        message: data.message,
        type: 'success',
      });
      fetchAdmins();
    } catch (error) {
      setSingleUserDelete({ show: false, id: null, name: '' });
      setLoading(false);
      toastConfig.setToastConfig(error);
    }
    setShowConfirmBox(false);
  };

  const handleCreateSuperAdmin = (values) => {
    const newData = removeEmptyKeys(values);
    setSubmitting(true);
    axiosInstance()
      .post('/sa-user/create-super-admin', newData)
      .then(({ data }) => {
        setShowCreateAdminDialog(false);
        toastConfig.setToastConfig({
          open: true,
          message: 'Successfully created new admin',
          type: 'success',
        });
        setSubmitting(false);
        fetchAdmins();
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);

        setSubmitting(false);
      });
  };

  // Get Fields For Admin
  const getFIeldsData = () => {
    axiosInstance()
      .get(`/sa-field?brand=new&resource=User`)
      .then(({ data: { data } }) => {
        setAdminFieldsData({
          fields: data,
          initialVals: getObjKeys('', data),
        });
      });
  };

  const handleSubmit = (values) => {
    handleCreateSuperAdmin(values);
  };


  return (
    <>
      <Dialog
        open={showCreateAdminDialog}
        onClose={() => setShowCreateAdminDialog(false)}
        fullWidth
        maxWidth='md'>
        <CustomDialogHeader
          title='New Admin'
          onClose={() => setShowCreateAdminDialog(false)}
        />

        <Formik
          initialValues={adminFieldsData.initialVals}
          validationSchema={yupSchema(adminFieldsData.fields)}
          validateOnMount
          onSubmit={handleSubmit}>
          {({ values, errors, touched, setFieldValue, submitForm }) => (
            <Form>
              <DialogContent dividers>
                <InputField
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  fieldsData={adminFieldsData.fields}
                  size='small'
                  fullWidth
                  disabled={isSubmitting}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  variant='outlined'
                  color='primary'
                  onClick={() => setShowCreateAdminDialog(false)}>
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant='contained'
                  color='primary'
                  onClick={submitForm}>
                  {isSubmitting ? <CircularProgress size={20} /> : 'Save'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <Layout>
        <CustomBreadCrumbs routes={[routes.admins]} />
        <Container minHeight='100%'>
          <div className='adminheader-outter-container'>
            <div className='header-panel'>
              <BrandHeader
                // className="usersHeader"
                total={rowCount}
                heading='Admins'
                // showHeading={false}
                icon={<FaUsers className='headerLogo' />}>
                <div className='adminHeaderFilter'>
                  <div className='adminsHeader-searchbar'>
                    <SearchBox
                      onSearch={handleSearch}
                      value={searchVal}
                      width='250px'
                    />
                  </div>

                  <Button
                    color='primary'
                    variant='contained'
                    size='small'
                    className='adminsheader-addbtn'
                    onClick={() => {
                      setShowCreateAdminDialog(true)
                    }}
                    startIcon={<Add />}>
                    Add
                  </Button>


                  <Button
                    disabled={selectedRecs.length > 0 ? false : true}
                    color='primary'
                    size='small'
                    variant='outlined'
                    onClick={openActions}
                    className='adminsheader-actionbtn'
                    aria-controls='action-menu'>
                    Actions <ExpandMore />
                  </Button>

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted={false}
                    id='action-menu'
                    open={Boolean(anchorEl)}
                    onClose={closeActions}>
                    <MenuItem
                      onClick={() => {
                        dialogBoxSelection();
                        closeActions();
                      }}>
                      Delete {selectedRecs.length > 1 ? 'Admins' : 'Admin'}
                    </MenuItem>
                  </Menu>
                </div>
              </BrandHeader>
            </div>
            {/* <Container> */}
            <div className='listing-grid'>
              <DataGrid
                components={{
                  Toolbar: DataGridCustomToolbar,
                }}
                rows={loading ? [] : dataRows}
                columns={columns}
                loading={loading}
                disableSelectionOnClick
                disableMultipleSelection
                paginationMode='server'
                pagination
                onPageChange={handlePage}
                onPageSizeChange={handlePageSize}
                pageSize={query.limit}
                page={query.page}
                rowCount={rowCount}
                rowsPerPageOptions={[25, 50, 75]}
                onSortModelChange={handleSortModelChange}
                density='compact'
                filterMode='server'
                onFilterModelChange={onFilterChange}
              />
            </div>
            {singleUserDelete.show ? (
              <ConfirmationDialog
                open={singleUserDelete.show}
                message={`Are you sure, you want to delete admin: ${singleUserDelete.name}`}
                onClose={() =>
                  setSingleUserDelete({
                    id: null,
                    show: false,
                    name: '',
                  })
                }
                onOk={handleSingleDeleteUsers}
              />
            ) : null}

            {showConfirmBox && (
              <ConfirmationDialog
                open={showConfirmBox}
                message={`Are you sure you want to delete ${selectedRecs.length > 1 ? ' these admins' : 'this admin'
                  }`}
                onClose={() => setShowConfirmBox(false)}
                onOk={handleDeleteUsers}
              />
            )}
            {showWarningBox && (
              <MessageDialog
                open={showWarningBox}
                message='You have Selected Brand Admin and that can not be deleted'
                onClose={() => setShowWarningBox(false)}
              />
            )}

          </div>
        </Container>
      </Layout>
    </>
  );
};

export default Admins;
