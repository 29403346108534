import axiosInstance from './axiosInstance';

export const GetBrandResource = async (brandId) => {
  const { data } = await axiosInstance().get(`/sa-formbuilder/brand/${brandId}?allResource=true`);
  return data;
};

export const GetBrandResourceData = async (brandId, resource) => {
  const { data } = await axiosInstance().get(`/sa-formbuilder/${brandId}/${resource}`);
  return data;
};

export const UpdateBrandResourceData = async (inputData) => {
  const { data } = await axiosInstance().put(`/sa-formbuilder`, inputData);
  return data;
};
