import React, { useContext, useEffect, useState } from 'react'
import {
    Button, CircularProgress, Dialog, TextField, Grid, FormControl,
    FormControlLabel, Checkbox, InputAdornment, IconButton, InputLabel, OutlinedInput, FormHelperText
} from '@material-ui/core';
import axiosInstance from '../../../axios/axiosInstance';
import CustomDialogContent from '../../../components/CustomDialog/CustomDialogContent';
import CustomDialogFooter from '../../../components/CustomDialog/CustomDialogFooter';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { CustomToastContext } from '../../../StateProvider/CustomToastContext/CustomToastContext';
import { regexPattern } from '../../../constants/helpers';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function CreatePasswordDialog({ open, email, onClose }) {

    const [isAutoGenerate, setIsAutoGenerate] = useState(true);
    const [password, setPassword] = useState({ value: "", isTouched: false, isValid: false });
    const { setToastConfig } = useContext(CustomToastContext);
    const [isCreatingPassword, setIsCreatingPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false)

    const createPassword = () => {
        setIsCreatingPassword(true);

        axiosInstance().post("/user/generate-password", {
            isAutoGenerate: isAutoGenerate,
            password: isAutoGenerate === false ? password.value : "",
            email: email
        }).then(() => {
            setToastConfig({ open: true, type: "success", message: "Password generated successfully" })
            onClose();
        }).catch((error) => {
            setToastConfig(error);
        })
    }

    useEffect(() => {
        if (!isAutoGenerate) {
            setPassword({ value: "", isTouched: false, isValid: false });
        }
    }, [isAutoGenerate])

    return (
        <Dialog
            fullWidth
            maxWidth='xs'
            open={open}
            onClose={onClose}
            aria-labelledby='assign-roles-dialog'>

            <CustomDialogHeader title="Generate Password" onClose={onClose} />

            <CustomDialogContent>
                <div className="mt-5 mb-5">

                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox defaultChecked onChange={() => {
                                setIsAutoGenerate(prevState => !prevState);
                            }} />} label="Auto generate password" />
                        </Grid>

                        {
                            !isAutoGenerate && <Grid item xs={12}>
                                <FormControl variant="outlined" size="small" error={
                                    password.isTouched && !regexPattern.password.test(
                                        password.value,
                                    )
                                }>
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput id="password" type={showPassword ? "text" : "password"} fullWidth label="Password *" value={password.value}
                                        onChange={(e) => {
                                            setPassword({
                                                value: e.target.value, isTouched: true, isValid: regexPattern.password.test(
                                                    e.target.value,
                                                )
                                            });
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setShowPassword(prevState => !prevState) }}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }

                                    />
                                    <FormHelperText id="outlined-weight-helper-text">
                                        Minimum eight characters, at least one uppercase, one lowercase, one number and one special character
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <TextField id="email" fullWidth label="Email" variant="outlined" value={email} disabled={true} size="small" />
                        </Grid>
                    </Grid>

                </div>
            </CustomDialogContent>

            <CustomDialogFooter>
                <Button
                    onClick={onClose}
                    color='primary'>
                    Cancel
                </Button>

                <Button
                    disabled={isCreatingPassword || (!isAutoGenerate && !password.isValid)}
                    onClick={createPassword}
                    variant="contained"
                    color='primary'>
                    {isCreatingPassword ? <CircularProgress size={20} /> : 'Save'}
                </Button>
            </CustomDialogFooter>

        </Dialog >
    )
}
