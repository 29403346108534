import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';

export const UserDropdown = ({
  users,
  referenceId,
  name,
  label,
  placeholder,
  value,
  onChange,
  multiple,
}) => {
  const setParticipants = (value) => {
    let content = null;
    if (multiple === true) {
      let userIdList = [];
      for (var val of value) {
        userIdList.push({ userId: val.userId });
      }
      content = userIdList;
    } else {
      content = value.userId;
    }
    onChange(referenceId, { field: name, content: content });
  };

  return users ? (
    <Autocomplete
      multiple={multiple}
      options={users}
      getOptionLabel={(option) => option.name}
      filterSelectedOptions={false}
      onChange={(e, value) => setParticipants(value)}
      defaultValue={
        multiple === true
          ? users.filter((data) =>
              _.flatMap(value, (nameObj) =>
                _.map(nameObj, (userId) => {
                  return userId;
                }),
              ).includes(data.userId),
            )
          : users.filter((data) => data.userId === value).length > 0 &&
            users.filter((data) => data.userId === value)[0]
      }
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant='outlined'
            label={option && option.name}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          label={label}
          margin='dense'
          placeholder={placeholder}
        />
      )}
    />
  ) : (
    <Skeleton animation='wave' height={52} />
  );
};
