import {
  Dialog,
  DialogActions,
  Checkbox,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import {
  Button,
  CircularProgress,
  ListItemText,
  ListItemIcon,
  DialogContent,
} from '@material-ui/core';
import Loader from '../../components/Loader';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';

function AssignUsers(props) {
  const {
    isOpen,
    onClose,
    loading,
    onUserSelection,
    onAssignRoles,
    selectedBrandUsers,
    brandUsers,
    isAssigning,
  } = props;
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={isOpen}
      onClose={onClose}
      aria-labelledby='assign-roles-dialog'>
      <CustomDialogHeader title='Assign users to selected roles'></CustomDialogHeader>

      <DialogContent
        dividers
        style={{ padding: loading ? '32px' : '0px 10px' }}>
        {loading ? (
          <Loader text='Loading Users' />
        ) : brandUsers.length ? (
          <List style={{ padding: 0 }}>
            {brandUsers.map((user) => (
              <ListItem divider key={user._id}>
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    onChange={(e) => onUserSelection(e, user._id)}
                    checked={selectedBrandUsers.indexOf(user._id) >= 0}
                    inputProps={{
                      'aria-labelledby': `checkbox-list-label-${user._id}`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${user.firstName} ${user.lastName}`}
                  secondary={user.email}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>All users has been assigned to this role</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isAssigning}
          onClick={onClose}
          color='primary'
          variant='outlined'>
          Cancel
        </Button>
        <Button
          disabled={!selectedBrandUsers.length || isAssigning}
          onClick={onAssignRoles}
          color='primary'
          variant='outlined'>
          {isAssigning ? <CircularProgress size={20} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default AssignUsers;
