import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { TextField } from 'formik-material-ui';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { PostProject } from '../../axios/project';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ProjectSchema = object().shape({
  name: string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long')
    .required('project name is required'),
});

export const CreateProject = ({ open, setOpen, fetchProjects }) => {
  const handleSave = (values) => {
    PostProject(values)
      .then(({ data }) => {
        setOpen(false);
        fetchProjects();
      })
      .catch((err) => { });
  };

  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={ProjectSchema}
      onSubmit={handleSave}>
      {({ submitForm }) => (
        <Form>
          <Dialog
            aria-labelledby='customized-dialog-title'
            fullWidth
            maxWidth={'xs'}
            open={open}>
            <CustomDialogHeader title='Project Create'></CustomDialogHeader>
            <DialogContent dividers>
              <Box padding={1}>
                <Field
                  component={TextField}
                  fullWidth
                  margin='dense'
                  type='text'
                  label='Project Name'
                  name='name'
                  variant='outlined'
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={() => setOpen(false)}>
                {' '}
                Cancel{' '}
              </Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                onClick={submitForm}>
                Save{' '}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};
