import { useEffect, useState, useContext } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AssignmentReturnedOutlined,
  ExpandMore,
  Add,
  Delete,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { FaUsers } from 'react-icons/fa';

import Layout from '../../components/Layout';
import Container from '../../components/Container';
import BrandHeader from '../../components/BrandHeader';
import Loader from '../../components/Loader';
import SearchBox from '../../components/Helpers/SearchBox';
import ConfirmationDialog from '../../components/Helpers/ConfirmationDialog';
import NoDataCell from '../../components/Helpers/NoDataCell';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CreateUser from './CreateUser';
import DataGridCustomToolbar from './../../components/Helpers/DataGridCustomToolbar';
import routes from './../../components/Helpers/Routes';
import CustomBreadCrumbs from './../../components/CustomBreadCrumbs';
import UnAssignRoleDialog from './UnAssignRoles';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import AlertDialog from '../../components/Helpers/AlertDialog';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import axiosInstance from '../../axios/axiosInstance';
import { getSearchQuery } from '../../services/util';
import { userType } from '../../constants/helpers';
import MessageDialog from '../../components/Helpers/MessageDialog';
import './index.scss';

let userTimeout: ReturnType<typeof setTimeout>;
const INACTIVE_STATUS = 'Inactive';
const ACTIVE_STATUS = 'Active';

const Users = () => {
  const toastConfig = useContext(CustomToastContext);
  const { state } = useLocation();

  const [rolesDialogOpen, setRolesDialogOpen] = useState(false);
  const [brandRoles, setBrandRoles] = useState([]);
  const [loadingBrandRoles, setLoadingBrandRoles] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isAssigning, setAssigning] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataRows, setDataRows] = useState([]);
  const [brandSelectList, setBrandSelectList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(state ? state : null);
  const [searchVal, setSearchVal] = useState('');
  const [checkAllUsers, setCheckAllUsers] = useState(false);
  const [query, setQuery] = useState({ page: 0, limit: 25 });
  const [renderCount, setRenderCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRecs, setSelectedRecs] = useState([]);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [showWarningBox, setShowWarningBox] = useState(false);
  const [unAssignRec, setUnAssignRec] = useState(undefined);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [isRoleUnassign, setIsRoleUnassign] = useState(false);
  const [singleUserDelete, setSingleUserDelete] = useState({
    id: null,
    show: false,
    name: '',
  });
  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);
  const [
    activeInactivateUsersDialogData,
    setActiveInactivateUsersDialogData,
  ] = useState<{
    message: string;
    open: boolean;
    activate?: boolean;
  }>({
    message: null,
    open: false,
    activate: false,
  });

  useEffect(() => {
    getBrandList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let millisec = Object.keys(searchVal).length > 0 ? 600 : 5;
    if (userTimeout) {
      clearTimeout(userTimeout);
    }
    userTimeout = setTimeout(() => {
      getUsers();
    }, millisec);
    // eslint-disable-next-line
  }, [searchVal, selectedBrand]);

  useEffect(() => {
    if (renderCount > 0) {
      getUsers();
    } else setRenderCount((preCount) => preCount + 1);
    // eslint-disable-next-line
  }, [query]);

  // ******** GET BRANDS LIST ********

  const getBrandList = () => {
    axiosInstance()
      .get(`/sa-brand?select=companyName&limit=100`)
      .then(({ data: { data } }) => {
        const list = data.map((item) => ({
          id: item._id,
          name: item.companyName,
        }));
        setBrandSelectList(list);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  // ******* EITHER GET ALL USERS OR BY AN BRAND ID *******
  const getUsers = (id = null) => {
    let searchParams: any = { ...query };
    searchParams = searchVal
      ? { ...searchParams, search: searchVal }
      : searchParams;
    setLoading(true);
    const api = getSearchQuery(
      selectedBrand ? `/sa-user?filterById=[{"field": "brand", "term": "${selectedBrand?.id}"}]` : `/sa-user`,
      searchParams,
    );
    axiosInstance()
      .get(api)
      .then(({ data: { data, count } }) => {
        setRowCount(count);
        getRows(data);
        setCheckAllUsers(false);
        setLoading(false);
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);

        setLoading(false);
      });
  };

  // ********* GET ALL ROWS DATA FOR USERS *******
  const getRows = (user) => {
    let rows = user?.map((u) => ({
      id: u._id,
      isChecked: false,
      name: `${u.firstName} ${u.lastName}`,
      brand: u.brand ? u.brand.optionLabel : null,
      blocked: u.brand?.blocked ? true : u.blocked,
      status: u.brandData?.blocked
        ? INACTIVE_STATUS
        : u.blocked
          ? INACTIVE_STATUS
          : ACTIVE_STATUS,
      email: u.email,
      createdBy: u.createdBy,
      updatedBy: u.updatedBy,
      isBrandAdmin: u.userType === userType.brandAdmin,
      role: u.role,
    }));
    setDataRows(rows);
  };

  const dialogBoxSelection = () => {
    return dataRows.some((d) => d.isChecked && d.isBrandAdmin)
      ? setShowWarningBox(true)
      : setShowConfirmBox(true);
  };

  /**
   *  ADDITIONAL STUFF FOR INITIAL CHECKBOX SELECTION IN COLUMNS
   */
  const columns = [
    {
      field: 'isChecked',
      headerName: 'Checkbox',
      renderHeader: () => (
        <Checkbox
          color='primary'
          checked={checkAllUsers}
          onChange={(ev) => {
            setCheckAllUsers(ev.target.checked);
            const gridData = dataRows;
            gridData.map((d) => {
              d.isChecked = ev.target.checked;
              return d;
            });
            setDataRows([...gridData]);

            const ids = gridData.filter((d) => d.isChecked).map((d) => d.id);
            setSelectedRecs(ids);
          }}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          color='primary'
          checked={params.value}
          onChange={(ev) => {
            const gridData = dataRows;
            const indexOfRecord = gridData.findIndex(
              (d) => d.id === params.row.id,
            );
            gridData[indexOfRecord].isChecked = ev.target.checked;

            setDataRows([...gridData]);

            const checkedRecords = gridData.filter((d) => d.isChecked === true);

            if (checkedRecords.length === gridData.length) {
              setCheckAllUsers(true);
            } else {
              setCheckAllUsers(false);
            }

            handleSelectedEntities(params.row.id, ev.target.checked);
          }}
        />
      ),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      width: 75,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 350,
      renderCell: (params) => (
        <>
          <Link
            className='brand-name-Link text-truncate'
            to={`/users/${params.row.id}`}
            title={params.value}>
            {params?.row?.name ? params.row.name : <NoDataCell />}
          </Link>
          {params.row.isBrandAdmin ? (
            <Tooltip title='Brand Admin'>
              <AccountCircleIcon
                color='primary'
                className='ml-2'
                fontSize='small'
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      field: 'brand',
      headerName: 'Brand Name',
      width: 250,
      renderCell: (params) =>
        params.value ? (
          <p className='text-truncate' title={params.value}>
            {params.value}
          </p>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'status',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      headerName: 'Status',
      width: 150,
      renderCell: (params) =>
        params.value === ACTIVE_STATUS ? (
          <Chip size='small' label={ACTIVE_STATUS} className='bg-primary' />
        ) : (
          <Chip size='small' label={INACTIVE_STATUS} className='bg-danger' />
        ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 350,
      renderCell: (params) =>
        params.value ? (
          <p className='text-truncate' title={params.value}>
            {params.value}
          </p>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value && params.value.user ? (
          <h5 className='createBy'>
            {params.value.user.firstName}
            <span
              className='createdAtTime'
              title={`${params.value.user.firstName} • ${moment(
                params.value.date.slice(0, 10),
              ).format('MMM Do, YYYY')}`}>
              {moment(params.value.date.slice(0, 10)).format('MMM Do, YYYY')}
            </span>
          </h5>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'update',
      headerName: 'Updated By',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value && params.value.user ? (
          <h5 className='updateBy'>
            {params.value.user.firstName}
            <span
              title={`${params.value.user.firstName} • ${params.value.date}`}
              className='updatedAtTime'>
              {moment(params.value.date.slice(0, 10)).format('MMM Do, YYYY')}
            </span>
          </h5>
        ) : (
          <NoDataCell />
        ),
    },
    // {
    //   field: "actions", headerName: "Actions", width: 150,
    //   renderCell: (params) => (
    //     <DeleteOutlined
    //       onClick={() => handleDelete(params.row)}
    //       color="action"
    //       style={{ textAlign: 'center' }}
    //       button
    //     />
    //   )
    // },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          {params.row?.isBrandAdmin ? (
            <Tooltip
              className='cursor-stop'
              title='Brand Admin can not be deleted'>
              <IconButton aria-label='Delete'>
                <Delete fontSize='small' />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Delete'>
              <IconButton
                aria-label='Delete'
                onClick={() => {
                  setSingleUserDelete({
                    show: true,
                    id: params.row.id,
                    name: params.row.name,
                  });
                }}>
                <Delete fontSize='small' color='error' />
              </IconButton>
            </Tooltip>
          )}
          {
            <Tooltip title='Un-Assign Roles'>
              <IconButton
                aria-label='Un-Assign Roles'
                onClick={() => {
                  openUnassignUserDialog(params.row)
                }}>
                <AssignmentReturnedOutlined fontSize='small' color='action' />
              </IconButton>
            </Tooltip>
          }
        </>
      ),
    },
  ];

  // ***** COLUMNS STUFF ENDS  ☝☝ THERE *****

  const handleSelectedEntities = (id, isChecked) => {
    let tempSelectedRecs = [...selectedRecs],
      curRecIndex = selectedRecs.indexOf(id);
    if (isChecked && curRecIndex < 0) {
      tempSelectedRecs = [...selectedRecs, id];
    } else if (!isChecked && curRecIndex >= 0) {
      tempSelectedRecs.splice(curRecIndex, 1);
    }
    setSelectedRecs(tempSelectedRecs);
  };

  // ****** ACTIONS BUTTON STUFF *********
  const openActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActions = () => {
    setAnchorEl(null);
  };

  // **** HANDLE BRANDS SELECTION ******
  const handleChange = (_, newVal) => {
    if (query.page !== 0) {
      setQuery((prevState) => ({ ...prevState, page: 0 }));
    }
    setSelectedBrand(newVal);
    setSelectedRecs([]);
    if (searchVal) {
      setSearchVal('');
    }
    if (checkAllUsers) {
      setCheckAllUsers(false);
    }
  };

  // const showActiveInactiveModal = (activate) => {
  //   if (activate) {
  //     const message = `You want to activate selected user(s) ?`;

  //     setActiveInactivateUsersDialogData({
  //       message: message,
  //       open: true,
  //       activate: activate,
  //     });
  //   } else {
  //     const message = `You want to in-activate selected user(s) ?`;

  //     setActiveInactivateUsersDialogData({
  //       message: message,
  //       open: true,
  //       activate: activate,
  //     });
  //   }
  //   setAnchorEl(null);
  // };

  const onActiveInactiveAction = async (activate) => {
    getUsers();
    setActiveInactivateUsersDialogData({
      message: null,
      open: false,
      activate: false,
    });
  };

  const handleSearch = (e) => {
    if (query.page !== 0) {
      setQuery((prevState) => ({ ...prevState, page: 0 }));
    }
    setSearchVal(e.target.value);
    if (selectedBrand) {
      setSelectedBrand(null);
    }
  };

  const handlePage = (params) => {
    if (query.page !== params.page) {
      setQuery((prevState) => ({ ...prevState, page: params.page }));
    }
  };
  const handlePageSize = (params) => {
    if (params.pageSize !== query.limit) {
      setQuery({ page: 0, limit: params.pageSize });
    }
  };
  const handleSortModelChange = (params) => {
    if (params?.sortModel && params.sortModel.length > 0) {
      let temp = { ...params.sortModel[0] };
      if (temp.field === 'name') {
        temp.field = 'firstName';
      }
      if (temp.field === 'status') {
        temp.field = 'blocked';
      }
      setQuery((prevState) => ({
        ...prevState,
        page: 0,
        sortBy: temp.field,
        orderBy: temp.sort,
      }));
    }
  };

  const onFilterChange = (params) => {
    if (
      params.filterModel.items[0].value ||
      params.filterModel.items[0].value === ''
    ) {
      let deepFilter: string;
      if (params.filterModel.items[0].columnField === 'name') {
        deepFilter = JSON.stringify([{ field: "firstName", term: params.filterModel.items[0].value }, { field: "lastName", term: params.filterModel.items[0].value }])
      }
      else {
        deepFilter = JSON.stringify([{ field: params.filterModel.items[0].columnField, term: params.filterModel.items[0].value }])
      }
      setQuery((prevState) => ({
        ...prevState,
        deepFilter
      }));
    }
    else {
      setQuery({ page: 0, limit: 25 });
    }
  }

  const handleDeleteUsers = async () => {
    setLoading(true);
    let recLen = selectedRecs.length;
    if (selectedRecs && recLen > 0) {
      try {
        let { data } = await axiosInstance().put('/sa-user/remove', {
          ids: selectedRecs,
        });
        setLoading(false);
        toastConfig.setToastConfig({
          open: true,
          message: data.message,
          type: 'success',
        });
        getUsers();
      } catch (error) {
        setLoading(false);
        toastConfig.setToastConfig(error);
      }

      setSelectedRecs([]);
    }
    setShowConfirmBox(false);
  };

  const handleSingleDeleteUsers = async () => {
    setLoading(true);
    try {
      let { data } = await axiosInstance().put('/sa-user/remove', {
        ids: [singleUserDelete.id],
      });
      setSingleUserDelete({ show: false, id: null, name: '' });
      setLoading(false);
      toastConfig.setToastConfig({
        open: true,
        message: data.message,
        type: 'success',
      });
      getUsers();
    } catch (error) {
      setSingleUserDelete({ show: false, id: null, name: '' });
      setLoading(false);
      toastConfig.setToastConfig(error);
    }
    setShowConfirmBox(false);
  };

  const getSelectedBrandRoles = async () => {
    if (selectedBrand) {
      setLoadingBrandRoles(true);
      try {
        const {
          data: { data },
        } = await axiosInstance().get(`/sa-role?filterById=[{"field": "brand", "term": "${selectedBrand.id}"}]`);
        setBrandRoles(selectedRecs.length === 1 ?
          data.filter(r => !dataRows.find(d => d.id === selectedRecs[0]).role.some(item => item?._id === r?._id))
          : data);
        setLoadingBrandRoles(false);
      } catch (error) {
        setLoadingBrandRoles(false);
        toastConfig.setToastConfig(error);
      }
    }
  };

  const handleRoleSelection = (e, id) => {
    let tempSelectedRoles = [...selectedRoles];
    let curIndex = tempSelectedRoles.indexOf(id);
    if (e.target.checked) {
      if (curIndex < 0) tempSelectedRoles = [...tempSelectedRoles, id];
    } else if (curIndex >= 0) {
      tempSelectedRoles.splice(curIndex, 1);
    }
    setSelectedRoles(tempSelectedRoles);
  };

  const handleOpenDialog = () => {
    getSelectedBrandRoles();
    setRolesDialogOpen(true);
    closeActions();
  };

  const handleCloseDialog = () => {
    setRolesDialogOpen(false);
  };

  const handleAssignRoles = async () => {
    if (selectedRecs.length && selectedRoles.length) {
      setAssigning(true);
      try {
        const dataObj = {
          users: selectedRecs,
          roles: selectedRoles,
        };
        await axiosInstance().put('/sa-user/add-role', dataObj);
        toastConfig.setToastConfig({
          message: 'Roles assigned successfully',
          type: 'success',
          open: true,
        });
        setAssigning(false);
        handleCloseDialog();
        getUsers();
        setSelectedRoles([]);
        setSelectedRecs([]);
      } catch (error) {
        setAssigning(false);
        toastConfig.setToastConfig(error);
      }
    }
  };

  const openUnassignUserDialog = (obj) => {
    if (typeof obj !== 'object') {
      obj = dataRows.find((o) => o.id === obj);
    }
    if (obj) {
      setIsRoleUnassign(true);
      setLoadingRoles(true);
      setUnAssignRec(obj);
      setSelectedRoles(obj.role.map((o) => ({ ...o, isChecked: true })));
      setLoadingRoles(false);
      closeActions();
    }
  };
  const handleCloseUnAssign = () => {
    unSetStateForUnassign();
  };

  const handleRoleDeSelect = (role) => {
    let data = selectedRoles.map((obj) => {
      if (role._id === obj._id) {
        role.isChecked = role.isChecked ? false : true;
      }
      return obj;
    });
    setSelectedRoles(data);
  };
  const handleUnassignRole = () => {
    let roles = [];
    selectedRoles.forEach((o) => {
      if (!o.isChecked) roles.push(o._id);
    });
    let req: any = {
      roles: roles,
    };
    if (unAssignRec?.id) {
      req.user = unAssignRec.id;
    }
    setAssigning(true);
    axiosInstance()
      .put('/sa-user/un-assign-role', req)
      .then(({ data }) => {
        unSetStateForUnassign();
        setAssigning(false);
        getUsers();
        toastConfig.setToastConfig({
          open: true,
          message: 'Roles Un Assigned successfully',
          type: 'success',
        });
      })
      .catch((err) => {
        setAssigning(false);
        toastConfig.setToastConfig(err);
      });
    if (selectedRecs.length === 1) {
      let tempDataRows = dataRows.map((o) => ({
        ...o,
        isChecked: false,
      }));
      setDataRows(tempDataRows);
      setSelectedRecs([]);
    }
  };

  const unSetStateForUnassign = () => {
    setIsRoleUnassign(false);
    setSelectedRoles([]);
    setUnAssignRec(undefined);
    setLoadingRoles(false);
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='xs'
        open={rolesDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby='assign-roles-dialog'>
        <CustomDialogHeader title='Assign roles to selected users'></CustomDialogHeader>

        <DialogContent
          dividers
          style={{ padding: loadingBrandRoles ? '32px' : '0px 10px' }}>
          {loadingBrandRoles ? (
            <Loader text='Loading Roles' />
          ) : brandRoles.length ? (
            <List style={{ padding: 0 }}>
              {brandRoles.map((role) => (
                <ListItem divider key={role._id}>
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      onChange={(e) => handleRoleSelection(e, role._id)}
                      checked={selectedRoles.indexOf(role._id) >= 0}
                      inputProps={{
                        'aria-labelledby': `checkbox-list-label-${role._id}`,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={role.name}
                    secondary={role.description}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>All Roles assigned to this user</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isAssigning}
            onClick={handleCloseDialog}
            color='primary'>
            Cancel
          </Button>
          <Button
            disabled={!selectedRoles.length || isAssigning}
            onClick={handleAssignRoles}
            color='primary'>
            {isAssigning ? <CircularProgress size={22} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {activeInactivateUsersDialogData.open && (
        <AlertDialog
          open={activeInactivateUsersDialogData.open}
          message={activeInactivateUsersDialogData.message}
          onYes={() => {
            onActiveInactiveAction(activeInactivateUsersDialogData.activate);
          }}
          onNo={() => {
            setActiveInactivateUsersDialogData({
              message: null,
              open: false,
              activate: false,
            });
          }}
        />
      )}

      <Layout>
        <CustomBreadCrumbs routes={[routes.users]} />
        <Container minHeight='100%'>
          <div className='userheader-outter-container'>
            <div className='header-panel'>
              <BrandHeader
                // className="usersHeader"
                total={rowCount}
                heading='Users'
                // showHeading={false}
                icon={<FaUsers className='headerLogo' />}>
                <div className='userHeaderFilter'>
                  <div className='usersHeader-searchbar'>
                    <SearchBox
                      onSearch={handleSearch}
                      value={searchVal}
                      width='250px'
                    />
                  </div>
                  <Autocomplete
                    value={selectedBrand}
                    onChange={handleChange}
                    options={brandSelectList}
                    size='small'
                    className='usersheader-autocomplete'
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Select Brand'
                        variant='outlined'
                      />
                    )}
                  />
                  <Tooltip
                    title={
                      selectedBrand
                        ? ""
                        : 'Please select a brand'
                    }>
                    <span>
                      <Button
                        color='primary'
                        variant='contained'
                        size='small'
                        className='usersheader-addbtn'
                        disabled={selectedBrand ? false : true}
                        onClick={() => {
                          if (selectedBrand) {
                            setShowCreateUserDialog(true);
                          } else {
                            toastConfig.setToastConfig({
                              open: true,
                              message: 'No brand selected',
                              type: 'warning',
                            });
                          }
                        }}
                        startIcon={<Add />}>
                        Add
                  </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      selectedRecs.length > 0
                        ? ""
                        : 'Please select a User'
                    }>
                    <span>
                      <Button
                        disabled={selectedRecs.length > 0 ? false : true}
                        color='primary'
                        size='small'
                        variant='outlined'
                        onClick={openActions}
                        className='usersheader-actionbtn'
                        aria-controls='action-menu'>
                        Actions <ExpandMore />
                      </Button>
                    </span>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted={false}
                    id='action-menu'
                    open={Boolean(anchorEl)}
                    onClose={closeActions}>
                    <MenuItem
                      onClick={() => {
                        dialogBoxSelection();
                        closeActions();
                      }}>
                      Delete {selectedRecs.length > 1 ? 'Users' : 'User'}
                    </MenuItem>
                    <Tooltip
                      title={
                        Boolean(!selectedBrand)
                          ? 'Please select a Brand'
                          : ""
                      }>
                      <span>
                        <MenuItem
                          disabled={Boolean(!selectedBrand)}
                          onClick={handleOpenDialog}>
                          Assign Roles
                        </MenuItem>
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={
                        selectedRecs.length === 1
                          ? ""
                          : 'Please select only one User'
                      }>
                      <span>
                        <MenuItem
                          disabled={selectedRecs.length !== 1}
                          onClick={() => openUnassignUserDialog(selectedRecs[0])}>
                          Un-Assign Role
                    </MenuItem>
                      </span>
                    </Tooltip>
                    {/* Do not delete below code - punit */}
                    {/* <MenuItem
                    disabled={
                      dataRows.filter((d) => d.isChecked && d.blocked)
                        .length === 0
                    }
                    onClick={() => {
                      showActiveInactiveModal(true);
                    }}
                  >
                    Activate User(s) &nbsp;{" "}
                    <Chip
                      size="small"
                      label={
                        dataRows.filter((d) => d.isChecked && d.blocked)
                          .length
                      }
                    />
                  </MenuItem>
                  <MenuItem
                    disabled={
                      dataRows.filter((d) => d.isChecked && !d.blocked)
                        .length === 0
                    }
                    onClick={() => {
                      showActiveInactiveModal(false);
                    }}
                  >
                    In-activate User(s) &nbsp;{" "}
                    <Chip
                      size="small"
                      label={
                        dataRows.filter((d) => d.isChecked && !d.blocked)
                          .length
                      }
                    />
                  </MenuItem> */}
                  </Menu>
                </div>
              </BrandHeader>
            </div>
            {/* <Container> */}
            <div className='listing-grid'>
              <DataGrid
                components={{
                  Toolbar: DataGridCustomToolbar,
                }}
                rows={loading ? [] : dataRows}
                columns={columns}
                loading={loading}
                disableSelectionOnClick
                disableMultipleSelection
                paginationMode='server'
                pagination
                onPageChange={handlePage}
                onPageSizeChange={handlePageSize}
                pageSize={query.limit}
                page={query.page}
                rowCount={rowCount}
                rowsPerPageOptions={[25, 50, 75]}
                onSortModelChange={handleSortModelChange}
                density='compact'
                filterMode='server'
                onFilterModelChange={onFilterChange}
              />
            </div>
            {singleUserDelete.show ? (
              <ConfirmationDialog
                open={singleUserDelete.show}
                message={`Are you sure, you want to delete account: ${singleUserDelete.name}`}
                onClose={() =>
                  setSingleUserDelete({
                    id: null,
                    show: false,
                    name: '',
                  })
                }
                onOk={handleSingleDeleteUsers}
              />
            ) : null}

            {showConfirmBox && (
              <ConfirmationDialog
                open={showConfirmBox}
                message={`Are you sure you want to delete ${selectedRecs.length > 1 ? ' these users' : 'this user'
                  }`}
                onClose={() => setShowConfirmBox(false)}
                onOk={handleDeleteUsers}
              />
            )}
            {showWarningBox && (
              <MessageDialog
                open={showWarningBox}
                message='You have Selected Brand Admin and that can not be deleted'
                onClose={() => setShowWarningBox(false)}
              />
            )}
            {showCreateUserDialog && (
              <CreateUser
                openDialog={showCreateUserDialog}
                brand_id={selectedBrand.id}
                name={selectedBrand.name}
                role={null}
                onClose={() => setShowCreateUserDialog(false)}
                onSuccess={() => {
                  setShowCreateUserDialog(false);
                  getUsers();
                  // fetchBrands();
                }}
              />
            )}
            {isRoleUnassign ? (
              <UnAssignRoleDialog
                isOpen={isRoleUnassign}
                onClose={handleCloseUnAssign}
                selectedRoles={selectedRoles}
                loading={loadingRoles}
                isUnAssigning={isAssigning}
                onRoleSelection={handleRoleDeSelect}
                onUnAssignRole={handleUnassignRole}
              />
            ) : null}
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default Users;
