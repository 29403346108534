import { useEffect, useState, useContext } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import { Autocomplete } from '@material-ui/lab';
import {
  Box,
  Button,
  IconButton,
  Input,
  TextField,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  Chip,
  Tooltip,
} from '@material-ui/core';
// import ArrangeView from './ArrangeView';
import DataGridCustomToolbar from '../../components/Helpers/DataGridCustomToolbar';
import CustomBreadCrumbs from '../../components/CustomBreadCrumbs';
import routes from '../../components/Helpers/Routes';
import axiosInstance from '../../axios/axiosInstance';
import { ExpandMore, Add, Delete } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import { IoIosArrowDropdown } from 'react-icons/io';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    ['@media (max-width: 960px)']: {
      display: 'none',
    },
  },
  menuButtonList: {
    alignItems: 'flex-start',
    padding: '1px',
  },
  links: {
    color: theme.palette.info.light, //  textDark
    fontSize: 15,
  },
  darkLinks: {
    color: theme.palette.info.dark, //  textDark
    fontSize: 15,
  },
  linkDivider: {
    backgroundColor: '#ffffff42', //  darkBg
    margin: '0 10px',
  },
  darkLinkDivider: {
    backgroundColor: 'grey', //  darkBg
    margin: '0 10px',
  },
  delBtn: {
    color: 'red',
  },
  expandIcon: {
    position: 'absolute',
    right: '0',
    color: 'white',
  },
}));

export const quoteBuilder = {
  qbResource: 'quoteBuilder',
  qbApi: '/quote-builder',
};
const MasterPdfTemplate = () => {
  const { qbApi } = quoteBuilder;

  const [resource, setResource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorAction, setAnchorAction] = useState(null);
  const [resourceIds, setResourceIds] = useState([]);
  const [arrangeViewOpen, setArrangeViewOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toastConfig = useContext(CustomToastContext);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    fetchMasterResource();
  }, []);

  const fetchMasterResource = async () => {
    setLoading(true);
    axiosInstance()
      .get('/master-pdf-template')
      .then(({ data }) => {
        setResource(data.data);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const deleteMultipleMasterResources = async () => {
    const allIds = resource
      ?.filter((item) => resourceIds.includes(item.id))
      .map((item) => {
        return item._id;
      });
    axiosInstance()
      .put('/master-pdf-template/remove', { ids: allIds })
      .then(({ data }) => {
        fetchMasterResource();
      })
      .catch((err) => {});
  };

  const deleteMasterResource = async (id) => {
    setLoading(true);
    axiosInstance()
      .put('/master-pdf-template/remove', { ids: [id] })
      .then(({ data }) => {
        fetchMasterResource();
      })
      .catch((err) => {});
  };

  const handleExportFields = () => {
    axiosInstance()
      .get('/master-pdf-template/export-pdf-templates')
      .then(({ data }) => {
        var dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(data.data));
        var dlAnchorElem = document.getElementById('downloadAnchorElem');
        dlAnchorElem.setAttribute('href', dataStr);
        dlAnchorElem.setAttribute('download', 'template_field.json');
        dlAnchorElem.click();
        toastConfig.setToastConfig({
          message: 'PDF Templates Exported Successfully',
          type: 'success',
          open: true,
        });
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const handleImportFields = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data: any = e.target.result;
      const dataObj = {
        resources: JSON.parse(data),
      };
      axiosInstance()
        .post('/master-pdf-template/import-pdf-templates', dataObj)
        .then(() => {
          toastConfig.setToastConfig({
            message: 'PDF Templates Imported Successfully',
            type: 'success',
            open: true,
          });
          fetchMasterResource();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    };
    reader.readAsBinaryString(f);
  };

  const previewPdfTemplate = (templateId) => {
    toastConfig.setToastConfig({
      hideDuration: null,
      open: true,
      type: 'info',
      message: `Downloading preview file, Please wait...`,
    });

    axiosInstance()
      .get(`${qbApi}/getdummy/${templateId}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: 'File downloaded Successfully',
        });

        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'PDF Template Name',
      flex: 8,
      renderCell: (params) => (
        <Link to={`${routes.masterPdfTemplate.path}/${params.row._id}`}>
          {params.row.name}
        </Link>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <Tooltip title='Preview'>
            <IconButton
              size='small'
              aria-label='Clone'
              className='mr-2'
              onClick={() => previewPdfTemplate(params.row._id)}>
              <VisibilityIcon color='primary' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
            <IconButton
              aria-label='Delete'
              onClick={() => {
                deleteMasterResource(params.row._id);
              }}>
              <Delete fontSize='small' color='error' />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const openResource = (id, isClone = false) => {
    if (isClone) {
      history.push(`${routes.masterPdfTemplate.path}/${id}`, {
        isClone: true,
      });
    } else {
      history.push(`${routes.masterPdfTemplate.path}/${id}`, {
        isClone: false,
      });
    }
  };

  const closeHandler = () => {
    setArrangeViewOpen(false);
    fetchMasterResource();
  };

  return (
    <Layout>
      <Grid container className='headerbox'>
        <Grid item md={5} sm={11} xs={10}>
          <CustomBreadCrumbs routes={[routes.masterPdfTemplate]} />
        </Grid>
        <Grid
          container
          justify='flex-end'
          item
          md={7}
          sm={1}
          xs={2}
          className='pr-3 pl-3'>
          <div className={classes.linksContainer}>
            <label
              htmlFor='importField'
              className='cursor-pointer'
              style={{ marginRight: '10px', marginTop: '10px' }}>
              Import
              <input
                onClick={(e: any) => (e.target.value = null)}
                id='importField'
                name='importField'
                onChange={handleImportFields}
                style={{
                  opacity: '0',
                  position: 'absolute',
                  zIndex: -1,
                }}
                type='file'
              />
            </label>
            <label
              className='cursor-pointer'
              onClick={handleExportFields}
              style={{ marginRight: '10px', marginTop: '10px' }}>
              Export
            </label>
            <a id='downloadAnchorElem' style={{ display: 'none' }}></a>
          </div>
          <Menu
            id='importField'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem>
              <label htmlFor='importField' className='cursor-pointer'>
                Import Fields
                <input
                  onClick={(e: any) => (e.target.value = null)}
                  id='importField'
                  name='importField'
                  onChange={handleImportFields}
                  style={{
                    opacity: '0',
                    position: 'absolute',
                    zIndex: -1,
                  }}
                  type='file'
                />
              </label>
            </MenuItem>
            <MenuItem onClick={handleExportFields}>Export Fields</MenuItem>
          </Menu>
          {isMobile && (
            <IconButton
              onClick={handleClick}
              className={classes.menuButtonList}>
              <IoIosArrowDropdown className={classes.expandIcon} />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Box p={2} pt={0} bgcolor='white'>
        <Box>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} container justify='flex-end'>
              <Grid style={{ display: 'flex', gap: '5px' }}>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={() => {
                    openResource('0');
                  }}>
                  Create PDF Template
                </Button>
                <Button
                  disabled={resourceIds.length ? false : true}
                  variant={'outlined'}
                  color='default'
                  size='small'
                  // className={
                  //   isMobile && !isTablet
                  //     ? 'mobile_button'
                  //     : styles.action_submit_btn
                  // }
                  onClick={(e) => setAnchorAction(e.currentTarget)}
                  aria-controls='action-menu'>
                  {'Actions'} <ExpandMore />
                </Button>
                <Menu
                  anchorEl={anchorAction}
                  keepMounted
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  id='action-menu'
                  open={Boolean(anchorAction)}
                  onClose={() => setAnchorAction(null)}>
                  {
                    <MenuItem
                      onClick={() => {
                        deleteMultipleMasterResources();
                      }}>
                      Delete PDF Template{' '}
                      <Chip size='small' label={resourceIds.length} />
                    </MenuItem>
                  }
                </Menu>
                {/* <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={() => {
                    setArrangeViewOpen(true);
                  }}>
                  Arrange View
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* {arrangeViewOpen && (
          <ArrangeView
            open={arrangeViewOpen}
            close={closeHandler}
            resourceData={resource}
          />
        )} */}
        {resource.length ? (
          <Box marginTop={2}>
            <div
              style={{
                height: window.innerHeight - 220,
                width: '100%',
              }}>
              <DataGrid
                components={{
                  Toolbar: DataGridCustomToolbar,
                }}
                rows={resource}
                columns={columns.map((column) => ({
                  ...column,
                  disableClickEventBubbling: true,
                }))}
                disableSelectionOnClick
                checkboxSelection={true}
                onSelectionModelChange={(newSelection) => {
                  setResourceIds(newSelection);
                }}
                selectionModel={resourceIds}
                loading={loading}
                pageSize={10}
                pagination
                density='compact'
              />
            </div>
          </Box>
        ) : (
          <Box marginTop={3} minHeight={window.innerHeight - 220}>
            <Typography variant='body1'>No PDF Templates to Display</Typography>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default MasterPdfTemplate;
