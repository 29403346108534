import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import {uniq} from 'lodash'
import FormTypes from './FormTypes';

type OptionsType = {optionValue:string, optionLabel:string,order:number,section:string}

const InputField = (props) => {
  const { fieldsData, errors, touched, values, setFieldValue, ...rest } = props;
  const [formsData, setFormsData] = useState([]);

  useEffect(() => {
    sortArray();
  }, [fieldsData]);
  let resourceOptions = fieldsData.filter((i) => { return i.fieldName === 'resourceAccess' })
  let sectionOptions = fieldsData.filter((i) => { return i.fieldName === 'sectionAccess' })
  const sortArray = () => {
    const sections = [];
    fieldsData.forEach((field) => {
      if (!sections.includes(field.sectionName)) {
        sections.push(field.sectionName);
      }
    });
    const customData = sections.map((name) => {
      let fields = fieldsData.filter((field) => field.sectionName === name);
      const sectionFields = fields.map((formData) => formData);
      return { name, sectionFields };
    });
    setFormsData(customData);
  };

  return (
    <React.Fragment>
      {formsData &&
        formsData.map((form, i) => (
          <div key={i} className='form-header-style'>
            <h2 className='form-header'>{form.name}</h2>
            <Box className='form-box'>
              <Grid spacing={3} container>
                {form.sectionFields.map((field) => (
                  field.fieldName === 'sectionAccess' ?
                    (
                      <Grid key={field.fieldName} item xs={12} sm={6} md={6}>
                        <FormTypes
                          {...rest}
                          values={values}
                          errors={errors}
                          touched={touched}
                          label={field.fieldLabel}
                          name={field.fieldName}
                          type={field.type}
                          options={field.option}
                          setFieldValue={setFieldValue}
                          required={field.required}
                          isTooltip={field.isTooltip}
                          tooltipMessage={field.tooltipMessage}
                          onChange={(e, value) => {
                            let section = value.map((i, index) => i.optionValue)
                            setFieldValue(field.fieldName, section);
                            let optionResource = resourceOptions.map((i) => { return i.option });
                            let resourceValue = []
                            section.map((i) => {
                              optionResource[0].filter((j) => { if (j.section === i) { return resourceValue.push(j) } })
                            })
                            let data = resourceValue.map((i) => i.optionValue)
                            setFieldValue('resourceAccess', data)
                          }}
                        />
                      </Grid>
                    ) :
                    field.fieldName === 'resourceAccess' ?
                      <Grid key={field.fieldName} item xs={12} sm={6} md={6}>
                        <FormTypes
                          {...rest}
                          values={values}
                          errors={errors}
                          touched={touched}
                          label={field.fieldLabel}
                          name={field.fieldName}
                          type={field.type}
                          options={field.option}
                          setFieldValue={setFieldValue}
                          required={field.required}
                          isTooltip={field.isTooltip}
                          tooltipMessage={field.tooltipMessage}
                          onChange={(e, value:OptionsType[]) => {
                            let resource = value.map((d) => d.optionValue)
                            setFieldValue(field.fieldName, resource);
                            const sections = value.map((d) => d.section);
                            setFieldValue('sectionAccess', uniq(sections))
                          }}
                        />
                      </Grid> :
                      <Grid key={field.fieldName} item xs={12} sm={6} md={6}>
                        <FormTypes
                          {...rest}
                          values={values}
                          errors={errors}
                          touched={touched}
                          label={field.fieldLabel}
                          name={field.fieldName}
                          type={field.type}
                          options={field.option}
                          setFieldValue={setFieldValue}
                          required={field.required}
                          isTooltip={field.isTooltip}
                          tooltipMessage={field.tooltipMessage}
                        />
                      </Grid>
                )
                )}
              </Grid>
            </Box>
          </div>
        ))}
    </React.Fragment>
  );
};


export default InputField;
