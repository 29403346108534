import api from './axios';

export const deleteBrand = async (body) => {
  const { data } = await api().delete('/sa-brand', { data: body });
  return data;
};

export const CreateNewBulkEmail = async (inputData) => {
  const { data } = await api().post("/bulk-email", inputData);
  return data;
};







