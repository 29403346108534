import { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Button,
  Box,
  LinearProgress,
  Link as MuiLink,
  IconButton,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { object, string } from 'yup';
import { Link } from 'react-router-dom';
import { useData } from '../../StateProvider';
import { SET_USER, SET_SELECTED_ENTITY } from '../../StateProvider/actionTypes';
import { SVG } from '../../assets';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import axiosInstance from '../../axios/axiosInstance';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import getAzureAcessToken from "../../components/Azure/getAzureAccessToken";
import { AzureLogin } from "../../components/Azure/Azure";
import { isEmpty } from "lodash";
import { SiMicrosoftoffice } from "react-icons/si";
import { InfoOutlined } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(() => ({
  container: {
    height: "90vh",
    width: "90vw",
    overflow: "hidden",
  },
  grid: {
    height: "100%",
  },
  formSide: {
    height: "100%",
    width: "100%",
    padding: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "150px",
    height: "100%",
  },
}));

const LoginSchema = object().shape({
  email: string().email().required(),
  password: string().required(),
});

const Login = () => {
  const toastConfig = useContext(CustomToastContext);
  const { dispatch }: any = useData();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [counter, setCounter] = useState(0);
  const [invalidAzureLogin, setInvalidAzureLogin] = useState(false);

  useEffect(() => {
    if (!isEmpty(account)) {
      (async () => {
        try {
          const graphToken = await getAzureAcessToken(instance);
          const res = await axiosInstance().post("/user/login/azure", {
            "graph-token": graphToken,
          });
          const { data } = res.data;
          localStorage.setItem("token", data.token);
          dispatch({ type: SET_USER, payload: data });
          if (data?.role?.selectedEntity?._id) {
            dispatch({
              type: SET_SELECTED_ENTITY,
              payload: data.role.selectedEntity._id,
            });
          }
        } catch (e) {
          setCounter(18);
          setInvalidAzureLogin(true);
          toastConfig.setToastConfig(e);
        }
      })();
    }
    // eslint-disable-next-line
  }, [account]);

  useEffect(() => {
    if (invalidAzureLogin) {
      if (invalidAzureLogin && counter) {
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        instance.logout();
        setInvalidAzureLogin(false);
      }
    }
    // eslint-disable-next-line
  }, [invalidAzureLogin, counter]);


  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    const data = {
      email: values.email,
      password: values.password,
    };

    axiosInstance()
      .post("/user/login", data)
      .then(({ data: response }) => {
        setIsSubmitting(false);
        const { data } = response;
        localStorage.setItem("token", data.token);
        dispatch({ type: SET_USER, payload: data });
      })
      .catch((err) => {
        setIsSubmitting(false);
        toastConfig.setToastConfig(err);
      });
  };

  return (
    <>
      <div className="login-bg">
        <Paper elevation={10} className={classes.container}>
          <Grid container className={classes.grid}>
            <Grid item sm={6} md={5} className="loginSidebar">
              <Box display={{ xs: 'none', sm: 'block', md: 'block' }}>
                <img alt="computer" className="imgLogin" src={SVG("imgComputer")}></img>
              </Box>
            </Grid>
            <Grid item sm={6} md={7} xs={12} className={classes.formSide}>
              <Box textAlign="center">
                <img
                  className={classes.logo}
                  src={SVG("LogoNew")}
                  alt="equip logo"
                  title="eQuipt Logo"
                />
                <Box my={4} />
                <Formik
                  initialValues={{
                    email: ['local'].includes(process.env.REACT_APP_ENV) ? 'gagandeep@vebholic.com' : '',
                    password: ['local'].includes(process.env.REACT_APP_ENV) ? 'T^&Yhg78ghs5%^&' : ''
                  }}
                  validationSchema={LoginSchema}
                  onSubmit={handleSubmit}>
                  {({ submitForm }) => (
                    <Form>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box mb={3}>
                          <Field
                            style={{ width: 260 }}
                            component={TextField}
                            name='email'
                            type='email'
                            label='Email'
                            size="small"
                            variant='outlined'
                          />
                        </Box>
                        <Box>
                          <Field
                            style={{ width: 260 }}
                            component={TextField}
                            type={showPassword ? 'text' : 'password'}
                            label='Password'
                            name='password'
                            size="small"
                            variant='outlined'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton className="p-0" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Box>
                        <Box width={260} mt={1}>
                          <Box textAlign="right">
                            <MuiLink to='/forget-password' component={Link}>
                              Forgot Password?
                            </MuiLink>
                          </Box>

                        </Box>
                        <Box width={260} className="mt-10">
                          {isSubmitting && <LinearProgress />}
                          <Button
                            variant="contained"
                            className="logo-bg-color"
                            fullWidth
                            disabled={isSubmitting}
                            onClick={submitForm}>
                            Login
                          </Button>
                          <Box className="mt-10">
                            <AuthenticatedTemplate>
                              {invalidAzureLogin ? (
                                <span>
                                  Not authorized loging out in {counter}
                                </span>
                              ) : (
                                <Button
                                  fullWidth
                                  variant="contained"
                                  className="logo-bg-color"
                                  startIcon={<SiMicrosoftoffice />}
                                  disabled={isSubmitting}
                                  onClick={() => instance.logoutPopup()}
                                >
                                  Office 365 Log Out
                                </Button>
                              )}
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                              <AzureLogin></AzureLogin>
                            </UnauthenticatedTemplate>
                          </Box>
                        </Box>
                        <Box textAlign="right" className="mt-4">
                          <MuiLink to='#' component={Link}>
                            Don't have an account?
                          </MuiLink>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default Login;
