import { useState, useEffect, Fragment, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from '../../components/Layout';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  IconButton,
  makeStyles,
  FormControlLabel,
} from '@material-ui/core';
import CustomBreadCrumbs from '../../components/CustomBreadCrumbs';
import routes from '../../components/Helpers/Routes';
import { camelCase } from "lodash";
import { FormBuilder } from '../../components/FormBuilder';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import axiosInstance from '../../axios/axiosInstance';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import CommonSkeleton from '../../components/Helpers/CommonSkeleton';
import { object, string } from 'yup';
import TextField from '@material-ui/core/TextField';
import { uniq, map } from 'lodash';
import { IoIosArrowDropdown } from 'react-icons/io';
import { isMobile, isTablet } from 'react-device-detect';
import Checkbox from '@material-ui/core/Checkbox';
import { Autocomplete } from '@material-ui/lab';
import AssignBrandDialog from './AssignBrandDialogue';

const schema = object().shape({
  name: string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long')
    .required('name is required'),
  label: string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long')
    .required('label is required'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    ['@media (max-width: 960px)']: {
      display: 'none',
    },
  },
  menuButtonList: {
    alignItems: 'flex-start',
    padding: '1px',
  },
  links: {
    color: theme.palette.info.light, //  textDark
    fontSize: 15,
  },
  darkLinks: {
    color: theme.palette.info.dark, //  textDark
    fontSize: 15,
  },
  linkDivider: {
    backgroundColor: '#ffffff42', //  darkBg
    margin: '0 10px',
  },
  darkLinkDivider: {
    backgroundColor: 'grey', //  darkBg
    margin: '0 10px',
  },
  delBtn: {
    color: 'red',
  },
  expandIcon: {
    position: 'absolute',
    right: '0',
    color: 'white',
  },
}));

const CreateMasterResource = () => {
  const history = useHistory();
  const { resourceId } = useParams();

  const { setToastConfig } = useContext(CustomToastContext);
  const [section, setSection] = useState([]);
  const [deleteField, setDeleteField] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [allResource, setAllResource] = useState(null);
  const [showBrandDialog, setShowBrandDialog] = useState(false);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchAllResource();
    fetchResourceData();
  }, []);

  const brandDialogClose = () => {
    setShowBrandDialog(false);
  };

  const fetchAllResource = async () => {
    await axiosInstance()
      .get('/master-resource')
      .then(({ data }) => {
        setAllResource(data.data);
      })
      .catch((err) => { });
  };

  const fetchResourceData = async () => {
    if (resourceId === '0') {
      let initialData = { name: '', label: '', section: '' };
      setInitialValues(initialData);
    } else {
      await axiosInstance()
        .get(`/master-resource/${resourceId}`)
        .then(({ data: { data } }) => {
          const _data = [];
          const _section = uniq(map(data.fields, 'sectionName'));
          _section.forEach((element: any, index: number) => {
            _data.push({
              sectionId: index,
              sectionName: element,
              field: data.fields.filter(
                (el: any) => el.sectionName === element,
              ),
            });
          });
          setSection(_data);
          const parentResource = allResource?.filter((e) => e._id === data?.parentResource);
          setInitialValues({
            name: data.name,
            section: data.section,
            label: data.label,
            isChildResource: data.isChildResource,
            parentResource: data.parentResource,
          });
        })
        .catch((err) => {
          setToastConfig(err);
        });
    }
  };

  const handleSave = (values) => {
    let data: any = {};
    let fields: any = [];
    let order = 0;
    section.forEach((_section) => {
      _section.field.forEach((_field) => {
        let _field_data = _field;
        _field_data._id = _field_data._id.toString();
        if (!isNaN(_field._id)) {
          _field_data.fieldName = camelCase(
            _field.fieldLabel.replace(/[^a-zA-Z0-9]/g, ''),
          );
        }
        _field_data.sectionName = _section.sectionName;
        _field_data.order = ++order;
        fields.push(_field_data);
      });
    });
    data.fields = fields;
    data.name = values.name;
    data.label = values.label;
    data.section = values.section;
    data.isChildResource = values.isChildResource || false;
    if (values.isChildResource) {
      data.parentResource = values?.parentResource?._id;
    }
    else {
      data.parentResource = null;
    }
    setIsUpdating(true);
    if (resourceId === '0') {
      axiosInstance()
        .post('/master-resource', data)
        .then(({ data: { data } }) => {
          setIsUpdating(false);
        })
        .catch((error) => {
          setIsUpdating(false);
        });
    } else {
      data._id = resourceId;
      axiosInstance()
        .put('/master-resource', data)
        .then(({ data: { data } }) => {
          setIsUpdating(false);
          setShowBrandDialog(true);
        })
        .catch((error) => {
          setIsUpdating(false);
        });
    }
  };

  const handleExportFields = () => {
    var dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(section));
    var dlAnchorElem = document.getElementById('downloadAnchorElem');
    dlAnchorElem.setAttribute('href', dataStr);
    dlAnchorElem.setAttribute('download', 'template_field.json');
    dlAnchorElem.click();
  };

  const handleImportFields = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data: any = e.target.result;
      setSection(JSON.parse(data));
    };
    reader.readAsBinaryString(f);
  };

  return (
    <Layout>
      {showBrandDialog && (
        <AssignBrandDialog
          handleClose={brandDialogClose}
          onSuccess={brandDialogClose}
          resourceId={resourceId}
        />
      )}
      <Fragment>
        <Grid container className='headerbox'>
          <Grid item md={5} sm={11} xs={10}>
            <CustomBreadCrumbs
              routes={[
                routes.masterResource,
                { title: resourceId === '0' ? 'New' : initialValues?.['name'] },
              ]}
            />
          </Grid>
          <Grid
            container
            justify='flex-end'
            item
            md={7}
            sm={1}
            xs={2}
            className='pr-3 pl-3'>
            <div className={classes.linksContainer}>
              <label
                htmlFor='importField'
                className='cursor-pointer'
                style={{ marginRight: '10px', marginTop: '10px' }}>
                Import Fields
                <input
                  onClick={(e: any) => (e.target.value = null)}
                  id='importField'
                  name='importField'
                  onChange={handleImportFields}
                  style={{
                    opacity: '0',
                    position: 'absolute',
                    zIndex: -1,
                  }}
                  type='file'
                />
              </label>
              <label
                className='cursor-pointer'
                onClick={handleExportFields}
                style={{ marginRight: '10px', marginTop: '10px' }}>
                Export Fields
              </label>
              <a id='downloadAnchorElem' style={{ display: 'none' }}></a>
            </div>
            <Menu
              id='importField'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem>
                <label htmlFor='importField' className='cursor-pointer'>
                  Import Fields
                  <input
                    onClick={(e: any) => (e.target.value = null)}
                    id='importField'
                    name='importField'
                    onChange={handleImportFields}
                    style={{
                      opacity: '0',
                      position: 'absolute',
                      zIndex: -1,
                    }}
                    type='file'
                  />
                </label>
              </MenuItem>
              <MenuItem onClick={handleExportFields}>Export Fields</MenuItem>
            </Menu>
            {isMobile && (
              <IconButton
                onClick={handleClick}
                className={classes.menuButtonList}>
                <IoIosArrowDropdown className={classes.expandIcon} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        {initialValues && allResource ? (
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSave}>
            {({ submitForm, touched, errors, setFieldValue, values }) => (
              <Form>
                <Box p={1} bgcolor='white'>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        variant='outlined'
                        type='text'
                        label='Resource Name'
                        required={true}
                        name='name'
                        fullWidth
                        margin='dense'
                        value={values['name']}
                        error={touched['name'] && Boolean(errors['name'])}
                        helperText={touched['name'] && errors['name']}
                        onChange={(e) => {
                          setFieldValue('name', e.target.value.trimStart());
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        variant='outlined'
                        type='text'
                        label='Resource Label'
                        required={true}
                        name='label'
                        fullWidth
                        margin='dense'
                        value={values['label']}
                        error={touched['label'] && Boolean(errors['label'])}
                        helperText={touched['label'] && errors['label']}
                        onChange={(e) => {
                          setFieldValue('label', e.target.value.trimStart());
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        variant='outlined'
                        type='text'
                        label='Resource Section'
                        required={true}
                        name='section'
                        fullWidth
                        margin='dense'
                        value={values['section']}
                        error={touched['section'] && Boolean(errors['section'])}
                        helperText={touched['section'] && errors['section']}
                        onChange={(e) => {
                          setFieldValue('section', e.target.value.trimStart());
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Box mt={0.5}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name='isChildResource'
                              checked={values['isChildResource']}
                              onChange={(e) => {
                                setFieldValue(
                                  'isChildResource',
                                  e.target.checked,
                                );
                              }}
                              color='primary'
                            />
                          }
                          label='Child Resource'
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      {values['isChildResource'] && (
                        <Autocomplete
                          options={allResource.filter(
                            (item) =>
                              item._id !== resourceId && !item.isChildResource,
                          )}
                          getOptionLabel={(option: any) =>
                            option ? option.name : ''
                          }
                          getOptionSelected={(option: any, val) =>
                            option._id === val
                          }
                          value={values['parentResource']}
                          onChange={(e, val) => {
                            setFieldValue('parentResource', val);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin='dense'
                              name='parentResource'
                              label='Parent Resource'
                              variant='outlined'
                              error={
                                touched['parentResource'] &&
                                Boolean(errors['parentResource'])
                              }
                              helperText={
                                touched['parentResource'] &&
                                errors['parentResource']
                              }
                              required={true}
                              fullWidth
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2} container justify='flex-end'>
                      <Box>
                        <Button
                          color='primary'
                          size='small'
                          onClick={() => {
                            submitForm();
                          }}
                          variant='contained'>
                          Save{isUpdating && <CircularProgress size={24} />}
                        </Button>
                      </Box>
                      <Box ml={1}>
                        <Button
                          color='primary'
                          variant='contained'
                          size='small'
                          onClick={() => {
                            history.push(routes.masterResource.path);
                          }}>
                          Close
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <FormBuilder
                    section={section}
                    setSection={setSection}
                    deleteField={deleteField}
                    setDeleteField={setDeleteField}
                    isCustomField={true}
                    extraFields={[]}
                    module='form-builder-master'
                    resource=''
                  />
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <Box p={2} height={500} bgcolor='white'>
            <CommonSkeleton lenArray={[...Array(10).keys()]} />
          </Box>
        )}
      </Fragment>
    </Layout>
  );
};

export default CreateMasterResource;
