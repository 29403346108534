export const SERVICES = {
    path: '/services',
  },
  USERS = {
    path: '/users',
  },
  BRANDS = {
    path: '/brand',
  },
  ROLES = {
    path: '/global-roles',
  },
  ENTITIES = {
    path: '/entities',
  },
  ENTITIES_NEW = {
    path: '/entities/new',
  },
  NEW_USER = {
    path: '/users/new',
  },
  NEW_BRAND = {
    path: '/brand/new',
  };
