import { GetFields } from '../axios';
import { string, array, boolean, object } from 'yup';
import React from 'react'
import { TransitionProps } from '@material-ui/core/transitions';
import { orderBy } from 'lodash';
import { Slide } from "@material-ui/core";
export const userType = {
  brandAdmin: 2,
};

export const getObjKeys = (val: string | boolean = '', arr: any[]) => {
  const obj = {};
  for (const key of arr) {
    if (key.type === 'dropDown') {
      const option = key.option?.find((data) => data.default === true);
      obj[key.fieldName] = val ? val : option.optionValue;
    } else if (key.type === 'multiSelect') {
      const defaultOptions = key.option?.filter(
        (item) => item.default === true,
      );
      const options = defaultOptions?.map((data) => data.optionValue);
      obj[key.fieldName] = val ? val : options;
    } else if (key.type === 'switch' || key.type === 'checkBox') {
      obj[key.fieldName] = val ? val : false;
    } else {
      obj[key.fieldName] = val;
    }
  }

  return obj;
};

export const getObjKeysWithValues = (dataObj, arr) => {
  const obj = {};
  for (const key of arr) {
    if (key.type === 'switch' || key.type === 'checkBox') {
      obj[key.fieldName] = dataObj[key.fieldName]
        ? dataObj[key.fieldName]
        : false;
    } else if (key.type === 'mutliSelect') {
      obj[key.fieldName] = dataObj[key.fieldName] ? dataObj[key.fieldName] : [];
    } else {
      obj[key.fieldName] = dataObj[key.fieldName] ? dataObj[key.fieldName] : '';
    }
  }
  return obj;
};

export const CHILD_RESOURCE = {
  rentalManagementProduct: 'Rental Management Product',
  rentalManagementCost: 'Rental Management Cost',
  purchaseOrderProduct: 'Purchase Order Product',
  purchaseOrderService: 'Purchase Order Service',
  repairJobAsset: 'Repair Job Asset',
  salesOrderProduct: 'Sales Order Product',
  salesOrderCost: 'Sales Order Cost',
};

export const removeEmptyKeys = (obj) => {
  Object.keys(obj).forEach((k) =>
    k === 'mobileNo' && obj[k].length <= 4
      ? delete obj[k]
      : obj[k] !== false &&
      obj[k] === '' &&
      obj[k] !== undefined &&
      delete obj[k],
  );
  return obj;
};

export const removeObjKey = (obj, key) => {
  delete obj[key];
  return obj;
};

export const getRolesData = async () => {
  const { data } = await GetFields('Role', 'new');
  return data;
};

export const checkOrUncheckFields = (val, arr) => {
  const newArr = arr.map((r) => ({
    ...r,
    isRead: val,
    isCreate: val,
    isUpdate: val,
  }));

  return newArr;
};

export const checkOrUncheckResources = (val, arr) => {
  const newArr = arr.map((r) => ({
    ...r,
    isRead: val,
    isCreate: val,
    isUpdate: val,
    isDelete: val,
  }));

  return newArr;
};

/**
 * @param {Array} fields
 */
export const yupSchema = (fields, validEmail = true) => {
  const schema = {};
  fields.forEach((input) => {
    if (input.type === 'singleLine') {
      schema[input.fieldName] = input.required
        ? string().required(`${input.fieldLabel} is required`)
        : string();
    } else if (input.type === 'name') {
      schema[input.fieldName] = input.required
        ? string()
          .matches(/^([^0-9]*)$/, 'Enter valid name')
          .required(`${input.fieldLabel} is required`)
        : string().matches(/^([^0-9]*)$/, 'Enter valid name');
    } else if (input.type === 'mobileNumber') {
      schema[input.fieldName] = input.required
        ? string()
          .min(10, 'Mobile number is too short')
          .required(`${input.fieldLabel} is required`)
        : string();
    } else if (input.type === 'url') {
      schema[input.name] = input.required
        ? string()
          .url('Enter valid url eg. https://www.hostname.com')
          .required(`${input.label} is required`)
        : string().url('Enter valid url eg. https://www.hostname.com');
    } else if (input.type === 'multiSelect') {
      schema[input.fieldName] = input.required
        ? array()
          .required(`${input.fieldLabel} is required`)
        // .length(1, 'Select at least one service access')
        : array();
    } else if (input.type === 'email') {
      schema[input.fieldName] =
        input.required && validEmail
          ? string().email().required(`${input.fieldLabel} is required`)
          : string().email();
    } else if (input.type === 'switch' || input.type === 'checkBox') {
      schema[input.fieldName] = input.required
        ? boolean().required(`${input.fieldLabel} is required`)
        : boolean();
    } else {
      schema[input.fieldName] = input.required
        ? string().required(`${input.fieldLabel} is required`)
        : string();
    }
  });

  return object().shape(schema);
};
export const validations = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
};
export const camelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const UnCamelCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const simplifyValues = (fields, obj) => {
  const newObj = {};

  if (obj) {
    for (const field of fields) {
      if (field.type === 'multiSelect') {
        if (
          Array.isArray(obj[field.fieldName] && obj[field.fieldName].length > 0)
        ) {
          newObj[field.fieldName] = obj[field.fieldName].map(
            (item) => item.optionValue,
          );
        }
      }
      // else if (
      //   field.type === "switch" ||
      //   field.type === "checkBox"
      // ) {
      //   newObj[field.fieldName] = obj[field.fieldName]
      //     ? "Active"
      //     : "Inactive";
      // }
      else if (field.type === 'dropDown') {
        if (obj[field.fieldName]) {
          newObj[field.fieldName] = obj[field.fieldName].optionValue;
        }
      }
      // else if (field.type === "currency") {
      //   const _val = obj[field.fieldName]
      //     ? `${obj[field.fieldName].currencyCode} - ${obj[field.fieldName].name
      //     }`
      //     : "";

      //   newObj[field.fieldName] = _val ? _val : "";
      // }
      else if (obj[field.fieldName]) {
        newObj[field.fieldName] = obj[field.fieldName];
      }
    }
  }
  return newObj;
};
export const dateTimeFormat = localStorage.getItem("dateTimeFormat") ?? "MM/DD/YYYY hh:mm A";
export const imageUploadMaxSize = { size: 1048576 * 2, text: '2 MB' };
export const documentUploadMaxSize = { size: 1048576 * 10, text: '10 MB' };
export const CustomDialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//  Don't use this for details screen as the model being passed is different
export const setFieldsInAscendingOrder = (fieldsToOrder) => {
  const sections = [];
  const fieldsInAscendingOrder = orderBy(fieldsToOrder, ["order", "asc"]);

  fieldsInAscendingOrder.forEach((field) => {
    if (!sections.includes(field.sectionName)) {
      sections.push(field.sectionName);
    }
  });

  const customData = sections.map((name) => {
    let fields = fieldsInAscendingOrder.filter(
      (field) => field.sectionName === name
    );

    const sectionFields = fields.map((formData) => formData);
    return { name, sectionFields };
  });

  return customData;
};

export const regexPattern = {
  password: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
}