import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Chip } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import './brandheader.scss';

const BrandHeader = (props) => {
  const {
    total,
    active,
    inactive,
    heading,
    children,
    options,
    onTypeChange,
    icon,
  } = props;
  const [filter, setFilter] = useState('All');

  const handleFilter = (event, newFilter) => {
    if (newFilter != null) {
      setFilter(newFilter);
      onTypeChange(options.find((d) => d.key === newFilter).value);
    }
  };

  return (
    <React.Fragment>
      <Grid container justifyContent='space-between' alignContent='center'>
        <Box className='brandHeader ml-2'>
          {icon}{' '}
          <span className='listingHeader'>
            {heading}
            {options ? '' : <span className='pl-2'> ({total}) </span>}{' '}
          </span>
          {options && (
            <ToggleButtonGroup
              size='small'
              className='ml-8'
              value={filter}
              exclusive
              onChange={handleFilter}>
              {options.map((k, index) => {
                return (
                  <ToggleButton value={k.key} key={index}>
                    {k.key}
                    <Chip
                      size='small'
                      color='primary'
                      className='ml-8'
                      label={
                        k.key === 'All'
                          ? active + inactive
                          : k.key === 'Active'
                            ? active
                            : inactive
                      }
                    />
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          )}
        </Box>
        <Grid item className='mobile-header'>
          <Grid container alignItems='center' style={{ height: '100%' }}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

BrandHeader.propTypes = {
  total: PropTypes.any,
  active: PropTypes.any,
  inactive: PropTypes.any,
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
  selectedType: PropTypes.any,
  onTypeChange: PropTypes.any,
  options: PropTypes.any,
  icon: PropTypes.any,
};

export default BrandHeader;
