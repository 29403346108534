import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Tooltip, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import BoxWithBorder from '../../../components/BoxWithBorder';
import { PERMISSION } from '../../../constants/Roles';
import './account.scss';
import React from 'react';

const rolePermissions = [PERMISSION.superAdmin, PERMISSION.brandAdmin];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  list: {
    width: '100%',
  },
}));

export default function GlobalRoleList({ onDeleteGlobalRole, data }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormGroup row>
        <div className={classes.demo}>
          <List style={{ padding: '0' }}>
            {data && data.length
              ? data.map((obj) => {
                return (
                  <BoxWithBorder
                    styles={{
                      padding: '0px',
                      marginBottom: '8px',
                    }}
                    key={obj?._id}>
                    <ListItem className={classes.list}>
                      <ListItemText
                        primary={
                          <Link
                            className='accountNameLink'
                            to={`/global-roles/${obj._id}`}>
                            <Typography> {obj.name || ''}</Typography>
                          </Link>
                        }
                        secondary={obj.description || ''}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip
                          title={
                            rolePermissions.indexOf(obj?.permission) !== 1
                              ? `Delete Role ${obj.name}`
                              : `You don't have permission to delete this Role`
                          }>
                          <span>
                            <IconButton
                              edge='end'
                              disabled={
                                rolePermissions.indexOf(obj?.permission) >= 0
                              }
                              aria-label='delete'
                              onClick={() => onDeleteGlobalRole(obj)}>
                              <DeleteIcon
                                color={
                                  rolePermissions.indexOf(obj?.permission) >= 0
                                    ? 'disabled'
                                    : 'error'
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>

                      </ListItemSecondaryAction>
                    </ListItem>
                  </BoxWithBorder>
                );
              })
              : null}
          </List>
        </div>
      </FormGroup>
    </div>
  );
}
