import api from './axios';

export const GetProjects = async () => {
  const { data } = await api().get('/project');
  return data;
};

export const PostProject = async (inputData) => {
  const { data } = await api().post('/project', inputData);
  return data;
};

export const ToggleFavouriteProject = async (inputData) => {
  const { data } = await api().put('/project/favourite', inputData);
  return data;
};

export const DeleteProject = async (id) => {
  const { data } = await api().put(`/project/${id}`);
  return data;
};

export const GetActivity = async (projectId, status) => {
  const { data } = await api().get(
    `/activity?status=${status}&projectId=${projectId}`,
  );
  return data;
};

export const GetActivityDetail = async (activityId) => {
  const { data } = await api().get(`/activity/${activityId}`);
  return data;
};

export const UpdateActivity = async (activityId, inputData) => {
  const { data } = await api().put(`/activity/field/${activityId}`, inputData);
  return data;
};

export const CreateActivity = async (inputData) => {
  const { data } = await api().post('/activity', inputData);
  return data;
};

export const DeleteActivity = async (id) => {
  const { data } = await api().put(`/activity/${id}`);
  return data;
};

export const GetComment = async (referenceId) => {
  const { data } = await api().get(`/comment/${referenceId}`);
  return data;
};

export const PostComment = async (inputData) => {
  const { data } = await api().post('/comment', inputData);
  return data;
};

export const GetRoadmap = async (projectId) => {
  const { data } = await api().get(`/roadmap?projectId=${projectId}`);
  return data;
};
