import { useEffect, useState } from 'react';
import ProfileUI from './ProfileUI';
import { UpdateUser } from '../../axios';
import { useData } from '../../StateProvider';
import { Box } from '@material-ui/core';
import Layout from '../../components/Layout';
import BrandHeader from '../../components/BrandHeader';
import Container from '../../components/Container';

function UserProfile() {
  const {
    state: { user },
  }: any = useData();

  const [open, setOpen] = useState(false);
  const [errorMsg, setErroMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    if (user?.user) {
      setSelectedUser(user.user);
    }
  }, [user]);

  const openSnackbar = (msg, type) => {
    setErroMsg(msg);
    setMsgType(type);
    setOpen(true);
  };

  const handleSaveUser = () => {
    setUpdating(true);
    let filteredObj = { ...selectedUser };
    delete filteredObj['brand'];
    delete filteredObj['createdAt'];
    delete filteredObj['confirmed'];
    delete filteredObj['blocked'];

    UpdateUser(filteredObj)
      .then((data) => {
        openSnackbar('Successfully saved', 'success');
        setUpdating(false);
      })
      .catch((err) => {
        setUpdating(false);
        openSnackbar('Something went wrong', 'error');
      });
  };

  const closeSnackbar = () => setOpen(false);

  return (
    <Layout>
      <Box component='div'>
        <BrandHeader
          // showHeading={true}
          heading='Profile'></BrandHeader>
      </Box>
      <Container>
        <ProfileUI
          open={open}
          closeSnackbar={closeSnackbar}
          message={errorMsg}
          msgType={msgType}
          loadingUser={undefined}
          selectedUser={selectedUser}
          handleSaveUser={handleSaveUser}
          setSelectedUser={setSelectedUser}
          isUpdating={isUpdating}
        />
      </Container>
    </Layout>
  );
}
export default UserProfile;
