import { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Skeleton } from '@material-ui/lab';

import Container from '../../../components/Container';
import Layout from '../../../components/Layout';
import CustomHeader from '../../../components/DetailsPageHeader';
import BoxWithBorder from '../../../components/BoxWithBorder';
import ConfirmationDialog from '../../../components/Helpers/ConfirmationDialog';
import DetailsPage from '../../../components/Shared/DetailsPage';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { getErrorMessage } from '../../../services/util';
import CommonSkeleton from '../../../components/Helpers/CommonSkeleton';
import GlobalRoles from './UserRoles';
import RoleEngine from '../../../components/Shared/RoleEngine';
import UpdateDetailsDialog from '../../../components/Shared/UpdateDetailsDialog';
import routes from '../../../components/Helpers/Routes';
import CustomBreadCrumbs from '../../../components/CustomBreadCrumbs';
import AssignRolesDialog from '../../../components/AssignRolesDialog/AssignRolesDialog';
import DeleteButton from '../../../components/Helpers/DeleteButton';
import axiosInstance from '../../../axios/axiosInstance';
import { CustomToastContext } from '../../../StateProvider/CustomToastContext/CustomToastContext';
import { userType } from '../../../constants/helpers';
import CreatePasswordDialog from './CreatePasswordDialog';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
}));

const UserDetailsPage = () => {
  const toastConfig = useContext(CustomToastContext);
  const history = useHistory();
  const classes = useStyles();
  const [headingLbl, setHeadingLbl] = useState('');
  const [gloabalRoles, setGloabalRoles] = useState([]);
  const [isUpdating, setUpdating] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [userFields, setUserFields] = useState([]);
  const [mainPoints, setMainPoints] = useState({});
  const [deleteUserRec, setDeleteUserRec] = useState(undefined);
  const [roleDeleteRec, setRoleDeleteRec] = useState(undefined);
  const [unionRoleData, setUnionRoleData] = useState(null);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [customizedRoutes, setCustomizedRoutes] = useState([]);
  const [showAssignRoleDialog, setShowAssignRoleDialog] = useState(false);
  const [showCreatePasswordDialog, setShowCreatePasswordDialog] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchUserData();
      getRoleUnion();
    }

    return () => {
      setGloabalRoles([]);
      setUnionRoleData(null);
    };
    // eslint-disable-next-line
  }, [id]);

  const fetchUserData = async () => {
    setLoading(true);
    axiosInstance()
      .get(`/sa-user/${id}`)
      .then(({ data: { data } }) => {
        const title = `${data.firstName} ${data.lastName}`;
        setCustomizedRoutes([routes.users, { title: title }]);
        setHeadingLbl(title);
        handleMainPonts(data);
        setUserData(data);
        setGloabalRoles(data?.role)
        setUserPermissions(data.permissions);
        if (userFields.length === 0) {
          getUserFields(data?.brand._id);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleMainPonts = (data) => {
    let tMainPoints = { ...mainPoints };
    if (data.email) {
      tMainPoints['Email'] = data.email;
    }
    if (data?.mobileNo) {
      tMainPoints['Mobile Number'] = data.mobileNo;
    }
    if (data?.emplyeeNumber) {
      tMainPoints['Employee Number'] = data.emplyeeNumber;
    }
    setMainPoints({ ...tMainPoints });
  };

  const getUserFields = (brandId) => {
    axiosInstance()
      .get(`/sa-field?brand=${brandId}&resource=User`)
      .then(({ data: { data } }) => {
        setUserFields(data);
        setLoading(false);
      });
  };

  const handleDeleteUser = (id) => {
    setDeleteUserRec(id);
    setShowConfirmBox(true);
  };

  const DeleteUser = () => {
    if (deleteUserRec) {
      axiosInstance()
        .put(`/sa-user/remove`, { ids: [deleteUserRec] })
        .then(({ data }) => {
          toastConfig.setToastConfig({
            message: data.message,
            type: 'success',
            open: true,
          });
          setShowConfirmBox(false);
          history.push('/users');
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    } else {
      setShowConfirmBox(false);
    }
  };

  const handleErrMes = (err, hideConfirmBox) => {
    let errMes = getErrorMessage(err);
    if (errMes) {
      toastConfig.setToastConfig(err);
    }
    if (hideConfirmBox) {
      setShowConfirmBox(false);
      if (roleDeleteRec) setRoleDeleteRec(undefined);
      if (deleteUserRec) setDeleteUserRec(undefined);
    }
  };

  const handleUpdateUser = (values) => {
    setUpdating(true);
    const updatedData = {
      ...values,
      _id: userData._id,
    };
    axiosInstance()
      .put('/sa-user', updatedData)
      .then(() => {
        fetchUserData();
        toastConfig.setToastConfig({
          message: 'Successfully saved',
          type: 'success',
          open: true,
        });
        setUpdating(false);
        closeUpdateDIalog();
      })
      .catch((err) => {
        setUpdating(false);
        toastConfig.setToastConfig(err);
      });
  };

  const handleUnassignRole = (rec) => {
    setRoleDeleteRec(rec);
    setShowConfirmBox(true);
  };

  const unassignUserRole = () => {
    if (roleDeleteRec?._id) {
      const data = {
        user: id,
        roles: [roleDeleteRec?._id],
      };
      axiosInstance()
        .put('/sa-user/un-assign-role', data)
        .then(() => {
          setShowConfirmBox(false);
          fetchUserData();
          setUnionRoleData(null);
          getRoleUnion();
          toastConfig.setToastConfig({
            message: 'Successfully unassigned role',
            type: 'success',
            open: true,
          });
        })
        .catch((err) => {
          handleErrMes(err, true);
        });
    }
  };

  const getRoleUnion = () => {
    axiosInstance()
      .get(`/sa-user/union-role/${id}`)
      .then(({ data: { data } }) => {
        setUnionRoleData(data);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const handleOpneUpdateDialog = () => {
    setOpenUpdateDialog(true);
  };

  const closeUpdateDIalog = () => {
    setOpenUpdateDialog(false);
  };

  const handleChangePermissions = (e) => {
    setUserPermissions({
      ...userPermissions,
      [e.target.name]: e.target.checked,
    });
    const newData = {
      _id: id,
      ...userPermissions,
      [e.target.name]: e.target.checked,
    };
    // setIsChangingPermission(true);
    axiosInstance()
      .post('/sa-user/permission-setup', newData)
      .then(({ data }) => {
        // setIsChangingPermission(false);
        toastConfig.setToastConfig({
          message: 'Permission changed successfully',
          type: 'success',
          open: true,
        });
      })
      .catch((err) => {
        // setIsChangingPermission(false);
        toastConfig.setToastConfig(err);
      });
  };

  return (
    <>
      {openUpdateDialog && (
        <UpdateDetailsDialog
          title={`Editing  ${userData.firstName} ${userData.lastName}`}
          openDialog={openUpdateDialog}
          onClose={closeUpdateDIalog}
          data={userData}
          fields={userFields}
          isUpdating={isUpdating}
          handleUpdate={handleUpdateUser}
        />
      )}
      <Layout>
        <Grid container direction='row'>
          <Grid item xs={12} className='pl-2'>
            <CustomBreadCrumbs routes={customizedRoutes} />
          </Grid>
        </Grid>
        <div>
          {
            <CustomHeader
              loading={loading}
              heading={headingLbl}
              logo={userData?.avatar ? userData.avatar : undefined}
              mainPoints={mainPoints}
              // style={{ marginTop: "150px", minHeight: "200px" }}
              showHeading={true}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setShowCreatePasswordDialog(true)}>
                Create Password
              </Button>

              <Box component='span' marginX={1} />

              <Button
                variant='contained'
                color='primary'
                onClick={handleOpneUpdateDialog}>
                Edit
              </Button>

              <Box component='span' marginX={1} />

              {userData?.userType !== userType.brandAdmin && (
                <DeleteButton
                  text='Delete'
                  action={() => {
                    handleDeleteUser(id);
                  }}
                />
              )}
            </CustomHeader>
          }

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={8} lg={9}>
              <Container styles={{ padding: '8px', minHeight: '100%' }}>
                <BoxWithBorder styles={{ padding: '8px' }}>
                  {loading ? (
                    <Grid container spacing={2} style={{ padding: '8px' }}>
                      <CommonSkeleton lenArray={[...Array(7).keys()]} />
                    </Grid>
                  ) : (
                    <DetailsPage data={userData} fields={userFields} />
                  )}
                </BoxWithBorder>
              </Container>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Container styles={{ padding: '8px', minHeight: '100%' }}>
                <BoxWithBorder
                  styles={{
                    padding: '0px',
                    minHeight: '450px',
                  }}>
                  <Box width='100%' padding={1} bgcolor='grey.200'>
                    <Typography color='primary'>Approval Process</Typography>
                  </Box>

                  <Box padding={1}>
                    <FormControl component='fieldset' fullWidth>
                      <FormGroup>
                        {loading ? (
                          [1, 2, 3, 4].map((i) => (
                            <Box
                              padding={1}
                              marginBottom={2}
                              display='flex'
                              key={i}>
                              <Skeleton
                                style={{
                                  borderRadius: 16,
                                }}
                                width='30px'
                                height='30px'
                              />
                              <Box marginX={1} />
                              <Skeleton
                                variant='text'
                                width='80%'
                                height='30px'
                              />
                            </Box>
                          ))
                        ) : userPermissions ? (
                          Object.keys(userPermissions).map((key) => (
                            <FormControlLabel
                              key={key}
                              control={
                                <Switch
                                  checked={userPermissions[key]}
                                  name={key}
                                  onChange={handleChangePermissions}
                                />
                              }
                              label={
                                key === 'doaSetup'
                                  ? 'DOA Setup'
                                  : _.startCase(key)
                              }
                            />
                          ))
                        ) : (
                          <Typography>There are no permissions</Typography>
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                </BoxWithBorder>
              </Container>
            </Grid>
          </Grid>
          <Box marginY={1} />
          <Container styles={{ padding: '8px', minHeight: '100%' }}>
            <BoxWithBorder styles={{ padding: '0px', minHeight: '300px' }}>
              <Box display='flex' padding={1} bgcolor='grey.200'>
                <Grid container>
                  <Grid item xs={8}>
                    <Box display='flex'>
                      <Box padding='5px'>
                        <Typography variant='subtitle2'>
                          Assigned Global Roles(
                          {gloabalRoles.length || 0})
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4} container justifyContent='flex-end'>
                    <IconButton
                      color='primary'
                      size='small'
                      onClick={() => {
                        setShowAssignRoleDialog(true);
                      }}>
                      <ControlPointIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>

              <Grid container style={{ padding: '10px' }} spacing={1}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <BoxWithBorder
                    styles={{
                      padding: '0px',
                      height: '352px',
                    }}>
                    {!gloabalRoles.length ? (
                      <Box textAlign='center' marginTop={2}>
                        <Typography variant='body2'>
                          User doesn't have any roles
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        style={{
                          width: '100%',
                          height: '100%',
                          overflowY: 'auto',
                        }}>
                        {userData && (
                          <GlobalRoles
                            type={userData?.userType}
                            data={gloabalRoles}
                            onUnassignRole={handleUnassignRole}
                          />
                        )}
                      </Box>
                    )}
                  </BoxWithBorder>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <BoxWithBorder
                    styles={{
                      padding: '0px',
                      height: '352px',
                    }}>
                    <TableContainer className={classes.container}>
                      <Table
                        stickyHeader
                        aria-label='roles'
                        className='roles-table'>
                        <TableBody>
                          <RoleEngine
                            field={unionRoleData ? unionRoleData.field : []}
                            resource={
                              unionRoleData ? unionRoleData.resource : []
                            }
                            isDisable={true}
                          />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BoxWithBorder>
                </Grid>
              </Grid>
            </BoxWithBorder>
          </Container>
          {showConfirmBox ? (
            <ConfirmationDialog
              open={showConfirmBox}
              message={
                deleteUserRec
                  ? `Are you sure you want to delete this User ${userData.firstName} ${userData.lastName}`
                  : roleDeleteRec
                    ? `Are you sure you want to unassign ${roleDeleteRec?.name} role from ${userData.firstName} ${userData.lastName}`
                    : ''
              }
              onClose={() => setShowConfirmBox(false)}
              onOk={
                deleteUserRec
                  ? DeleteUser
                  : roleDeleteRec
                    ? unassignUserRole
                    : null
              }
            />
          ) : null}

          {showAssignRoleDialog ? (
            <AssignRolesDialog
              rolesDialogOpen={showAssignRoleDialog}
              handleCloseDialog={() => setShowAssignRoleDialog(false)}
              brandId={userData?.brand?._id}
              userIds={[userData._id]}
              roles={gloabalRoles}
              onSuccess={() => {
                setShowAssignRoleDialog(false);
                fetchUserData();
                getRoleUnion();
              }}
            />
          ) : null}

          {
            showCreatePasswordDialog && <CreatePasswordDialog open={showCreatePasswordDialog} email={userData?.email} onClose={() => {
              setShowCreatePasswordDialog(false)
            }} />
          }

        </div>
      </Layout>
    </>
  );
};

export default UserDetailsPage;
