const routes = {
  login: {
    title: 'Login',
    path: '/login',
    data: {},
  },
  createPassword: {
    title: 'Create Password',
    path: '/create-password',
    data: {},
  },
  forgetPassword: {
    title: 'Forget Password',
    path: '/forget-password',
    data: {},
  },
  resetPassword: {
    title: 'Reset Password',
    path: '/reset-password',
    data: {},
  },
  brand: {
    title: 'Brand',
    path: '/brand',
    data: {},
  },
  brandDetail: {
    title: 'Brand',
    path: '/brand-detail',
    data: {},
  },
  users: {
    title: 'Users',
    path: '/users',
    data: {},
  },
  globalRoles: {
    title: 'Global Roles',
    path: '/global-roles',
    data: {},
  },
  admins: {
    title: 'Admins',
    path: '/admins',
    data: {},
  },
  formBuilder: {
    title: 'Form Builder',
    path: '/form-builder',
    data: {},
  },
  formBuilderResource: {
    title: 'Resource',
    path: '/resource',
    data: {},
  },
  masterResource: {
    title: 'Master Resource',
    path: '/master-resource',
    data: {},
  },
  masterPdfTemplate: {
    title: 'Master PDF Template',
    path: '/master-pdf-template',
    data: {},
  },
  masterResourceResources: {
    title: 'Resource',
    path: '/resource-data',
    data: {},
  },
  project: {
    title: 'Project',
    path: '/project',
    data: {},
  },
  projectBoard: {
    title: 'Board',
    path: '/board',
    data: {},
  },
  projectRoadmap: {
    title: 'Roadmap',
    path: '/roadmap',
    data: {},
  },
  entities: {
    title: 'Entities',
    path: '/entities',
    data: {},
  },
  activity: {
    title: 'Activity',
    path: '/activity',
    data: {},
  },
  orgStructure: {
    title: 'Org Structure',
    path: '/org-structure',
    data: {},
  },
  orgchart: {
    title: 'Orgchart',
    path: '/orgchart',
    data: {},
  },
  ecommerceSetup: {
    title: 'Ecommerce Setup',
    path: '/ecommerce-setup',
    data: {},
  },
  bulkEmail: {
    title: 'Bulk Email',
    path: '/bulk-email',
    data: {},
  },
  brandBackup: {
    title: 'Brand Backup',
    path: '/brand-backup',
    data: {},
  },
};

export default routes;
