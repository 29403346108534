import {
  Dialog,
  DialogActions,
  Checkbox,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import {
  Button,
  CircularProgress,
  ListItemText,
  ListItemIcon,
  DialogContent,
} from '@material-ui/core';
import Loader from '../../components/Loader';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';

function UnAssignRoles(props) {
  const {
    isOpen,
    onClose,
    loading,
    onRoleSelection,
    onUnAssignRole,
    isUnAssigning,
    selectedRoles,
  } = props;

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={isOpen}
      onClose={onClose}
      aria-labelledby='assign-roles-dialog'>
      <CustomDialogHeader title='Un-Assign roles to selected users'></CustomDialogHeader>

      <DialogContent
        dividers
        style={{ padding: loading ? '40px' : '0px 10px' }}>
        {loading ? (
          <Loader text='Loading Roles' />
        ) : selectedRoles.length ? (
          <List style={{ padding: 0 }}>
            {selectedRoles.map((role) => (
              <ListItem divider key={role._id}>
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    onChange={() => onRoleSelection(role)}
                    checked={role.isChecked}
                    inputProps={{
                      'aria-labelledby': `checkbox-list-label-${role._id}`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={role.name}
                  secondary={role.description}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No Roles is assigned</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isUnAssigning} onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button
          disabled={!selectedRoles.length || isUnAssigning}
          onClick={onUnAssignRole}
          color='primary'>
          {isUnAssigning ? <CircularProgress size={20} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default UnAssignRoles;
