import { useEffect, useState, useContext } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import { Autocomplete } from '@material-ui/lab';
import {
  Box,
  Button,
  IconButton,
  Input,
  TextField,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  Chip,
} from '@material-ui/core';
import ArrangeView from './ArrangeView';
import DataGridCustomToolbar from '../../components/Helpers/DataGridCustomToolbar';
import CustomBreadCrumbs from '../../components/CustomBreadCrumbs';
import routes from '../../components/Helpers/Routes';
import axiosInstance from '../../axios/axiosInstance';
import { ExpandMore, Add, Delete } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import { IoIosArrowDropdown } from 'react-icons/io';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import AssignBrandDialog from './AssignBrandDialogue';
import SearchBox from '../../components/Helpers/SearchBox';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    ['@media (max-width: 960px)']: {
      display: 'none',
    },
  },
  menuButtonList: {
    alignItems: 'flex-start',
    padding: '1px',
  },
  links: {
    color: theme.palette.info.light, //  textDark
    fontSize: 15,
  },
  darkLinks: {
    color: theme.palette.info.dark, //  textDark
    fontSize: 15,
  },
  linkDivider: {
    backgroundColor: '#ffffff42', //  darkBg
    margin: '0 10px',
  },
  darkLinkDivider: {
    backgroundColor: 'grey', //  darkBg
    margin: '0 10px',
  },
  delBtn: {
    color: 'red',
  },
  expandIcon: {
    position: 'absolute',
    right: '0',
    color: 'white',
  },
}));

const MasterResource = () => {
  const [resourceMain, setResourceMain] = useState([]);
  const [resource, setResource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorAction, setAnchorAction] = useState(null);
  const [resourceIds, setResourceIds] = useState([]);
  const [arrangeViewOpen, setArrangeViewOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toastConfig = useContext(CustomToastContext);
  const history = useHistory();
  const classes = useStyles();
  const [showBrandDialog, setShowBrandDialog] = useState(false);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    fetchMasterResource();
  }, []);

  const fetchMasterResource = async () => {
    setLoading(true);
    axiosInstance()
      .get('/master-resource')
      .then(({ data }) => {
        setResourceMain(data.data);
        setResource(data.data);
        setLoading(false);
      })
      .catch((err) => { });
  };

  const deleteMultipleMasterResources = async () => {
    const allIds = resource
      ?.filter((item) => resourceIds.includes(item.id))
      .map((item) => {
        return item._id;
      });
    axiosInstance()
      .put('/master-resource/remove', { ids: allIds })
      .then(({ data }) => {
        fetchMasterResource();
      })
      .catch((err) => { });
  };

  const deleteMasterResource = async (id) => {
    setLoading(true);
    axiosInstance()
      .put('/master-resource/remove', { ids: [id] })
      .then(({ data }) => {
        fetchMasterResource();
      })
      .catch((err) => { });
  };

  const handleExportFields = () => {
    axiosInstance()
      .get('/master-resource/export-resources')
      .then(({ data }) => {
        var dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(data.data));
        var dlAnchorElem = document.getElementById('downloadAnchorElem');
        dlAnchorElem.setAttribute('href', dataStr);
        dlAnchorElem.setAttribute('download', 'template_field.json');
        dlAnchorElem.click();
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const handleImportFields = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data: any = e.target.result;
      const dataObj = {
        resources: JSON.parse(data),
      };
      axiosInstance()
        .post('/master-resource/import-resources', dataObj)
        .then(() => {
          toastConfig.setToastConfig({
            message: 'Resource Imported Successfully',
            type: 'success',
            open: true,
          });
          fetchMasterResource();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    };
    reader.readAsBinaryString(f);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Resource Name',
      flex: 8,
      renderCell: (params) => (
        <Link to={`${routes.masterResource.path}/${params.row._id}`}>
          {params.row.name}
        </Link>
      ),
    },
    {
      field: 'label',
      headerName: 'Resource Label',
      flex: 8,
    },
    {
      field: 'section',
      headerName: 'Section',
      flex: 8,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          aria-label='Delete'
          onClick={() => {
            deleteMasterResource(params.row._id);
          }}>
          <Delete fontSize='small' color='error' />
        </IconButton>
      ),
    },
  ];

  const openResource = (id) => {
    history.push(`${routes.masterResource.path}/${id}`);
  };

  const closeHandler = () => {
    setArrangeViewOpen(false);
    fetchMasterResource();
  };


  const brandDialogClose = () => {
    setShowBrandDialog(false);
  };

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
    if (searchVal && searchVal !== '') {
      let tempData = resourceMain.filter(d => JSON.stringify(d).toLocaleLowerCase().includes((e.target.value).toLocaleLowerCase()))
      setResource(tempData)
    }
  }

  return (
    <Layout>
      <Grid container className='headerbox'>
        <Grid item md={5} sm={11} xs={10}>
          <CustomBreadCrumbs routes={[routes.masterResource]} />
        </Grid>
        <Grid
          container
          justify='flex-end'
          item
          md={7}
          sm={1}
          xs={2}
          className='pr-3 pl-3'>
          <div className={classes.linksContainer}>
            <label
              htmlFor='importField'
              className='cursor-pointer'
              style={{ marginRight: '10px', marginTop: '10px' }}>
              Import Fields
              <input
                onClick={(e: any) => (e.target.value = null)}
                id='importField'
                name='importField'
                onChange={handleImportFields}
                style={{
                  opacity: '0',
                  position: 'absolute',
                  zIndex: -1,
                }}
                type='file'
              />
            </label>
            <label
              className='cursor-pointer'
              onClick={handleExportFields}
              style={{ marginRight: '10px', marginTop: '10px' }}>
              Export Fields
            </label>
            <a id='downloadAnchorElem' style={{ display: 'none' }}></a>
          </div>
          <Menu
            id='importField'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem>
              <label htmlFor='importField' className='cursor-pointer'>
                Import Fields
                <input
                  onClick={(e: any) => (e.target.value = null)}
                  id='importField'
                  name='importField'
                  onChange={handleImportFields}
                  style={{
                    opacity: '0',
                    position: 'absolute',
                    zIndex: -1,
                  }}
                  type='file'
                />
              </label>
            </MenuItem>
            <MenuItem onClick={handleExportFields}>Export Fields</MenuItem>
          </Menu>
          {isMobile && (
            <IconButton
              onClick={handleClick}
              className={classes.menuButtonList}>
              <IoIosArrowDropdown className={classes.expandIcon} />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Box p={2} pt={0} bgcolor='white'>
        <Box>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} container justify='flex-end'>
              <Grid style={{ display: 'flex', gap: '5px' }}>
                <SearchBox
                  onSearch={handleSearch}
                  value={searchVal}
                  width='250px'
                />
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={() => {
                    openResource('0');
                  }}>
                  Create Resource
                </Button>
                <Button
                  disabled={resourceIds.length ? false : true}
                  variant={'outlined'}
                  color='default'
                  size='small'
                  // className={
                  //   isMobile && !isTablet
                  //     ? 'mobile_button'
                  //     : styles.action_submit_btn
                  // }
                  onClick={(e) => setAnchorAction(e.currentTarget)}
                  aria-controls='action-menu'>
                  {'Actions'} <ExpandMore />
                </Button>
                <Menu
                  anchorEl={anchorAction}
                  keepMounted
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  id='action-menu'
                  open={Boolean(anchorAction)}
                  onClose={() => setAnchorAction(null)}>
                  <>
                    <MenuItem
                      onClick={() => {
                        deleteMultipleMasterResources();
                      }}>
                      Delete Resources{' '}
                      <Chip size='small' label={resourceIds.length} />
                    </MenuItem>
                    <MenuItem
                      disabled={resourceIds.length !== 1}
                      onClick={() => {
                        setShowBrandDialog(true)
                      }}>
                      Applied resource changes to brands
                    </MenuItem>
                  </>
                </Menu>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={() => {
                    setArrangeViewOpen(true);
                  }}>
                  Arrange View
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {arrangeViewOpen && (
          <ArrangeView
            open={arrangeViewOpen}
            close={closeHandler}
            resourceData={resource}
          />
        )}
        {resource.length ? (
          <Box marginTop={2}>
            <div
              style={{
                height: window.innerHeight - 220,
                width: '100%',
              }}>
              <DataGrid
                components={{
                  Toolbar: DataGridCustomToolbar,
                }}
                rows={resource}
                columns={columns.map((column) => ({
                  ...column,
                  disableClickEventBubbling: true,
                }))}
                disableSelectionOnClick
                checkboxSelection={true}
                onSelectionModelChange={(newSelection) => {
                  setResourceIds(newSelection);
                }}
                selectionModel={resourceIds}
                loading={loading}
                pageSize={10}
                pagination
                density='compact'
              />
            </div>
          </Box>
        ) : (
          <Box marginTop={3} minHeight={window.innerHeight - 220}>
            <Typography variant='body1'>No Resources to Display</Typography>
          </Box>
        )}
      </Box>

      {showBrandDialog && (
        <AssignBrandDialog
          handleClose={brandDialogClose}
          onSuccess={brandDialogClose}
          resourceId={resourceIds[0]}
        />
      )}
    </Layout>
  );
};

export default MasterResource;
