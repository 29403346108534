import { Fragment, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Chip,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import ControlPointIcon from '@material-ui/icons/ControlPoint';

import ConfirmationDialog from '../../components/Helpers/ConfirmationDialog';
import Container from '../../components/Container';
import RoleEngine from '../../components/Shared/RoleEngine';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CustomHeader from '../../components/DetailsPageHeader';
import Layout from '../../components/Layout';
import BoxWithBorder from '../../components/BoxWithBorder';
import CreateUser from '../Users/CreateUser';
import NoDataCell from '../../components/Helpers/NoDataCell';
import DataGridCustomToolbar from './../../components/Helpers/DataGridCustomToolbar';
import routes from './../../components/Helpers/Routes';
import CustomBreadCrumbs from './../../components/CustomBreadCrumbs';
import DeleteButton from './../../components/Helpers/DeleteButton';
import axiosInstance from '../../axios/axiosInstance';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import { PERMISSION } from '../../constants/Roles';
import { getSearchQuery } from '../../services/util';
import { userType } from '../../constants/helpers';
const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: '200px',
    display: 'flex',
    background: theme.palette.background.default,
    padding: theme.spacing(0.8, 1.2),
    marginRight: '10px',
    cursor: 'pointer',
    borderRadius: 0,
    borderBottom: `3px solid #ddd`,
    color: '#aaa',

    '&:hover': {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      background: theme.palette.background.default,
    },

    '&;last-child': {
      marginRight: 0,
    },
  },
  activeTab: {
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
  boxHeader: {
    bgcolor: 'rgb(250 250 250 / 51%)',
    border: '1px solid #80808052',
    borderRadius: '3px',
  },
  boxContainer: {
    marginLeft: '7px',
    marginRight: '124px',
    padding: '8px',
  },
  fieldBox: {
    width: '75%',
    justifyContent: 'space-between',
  },
  textFields: {
    width: '42%',
    marginLeft: '4%',
  },
  roleHeadContainer: {
    marginBottom: '10px',
  },
  headingGrid: {
    display: 'inline-flex',
  },
  heading: {
    marginLeft: '1%',
  },
  info: {
    color: 'gray',
  },
  container: {
    maxHeight: 450,
  },
}));
const INACTIVE_STATUS = 'Inactive';
const ACTIVE_STATUS = 'Active';
const Roles = () => {
  const toastConfig = useContext(CustomToastContext);

  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const searchQuery = queryString.parse(history?.location?.search);
  const [query, setQuery] = useState({ page: 0, limit: 25 });

  const [isUpdating, setUpdating] = useState(false);
  const [rolesUserRow, setRolesUserRow] = useState([]);
  const [loadingRolesData, setLoadingRolesData] = useState(true);
  const [field, setField] = useState([]);
  const [resource, setResource] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [values, setValues] = useState({
    name: '',
    description: '',
  });
  const [deleteRec, setDeleteRec] = useState(undefined);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);
  const [permission, setPermission] = useState(null);

  useEffect(() => {
    if (id) {
      getRoleById(id);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchQuery?.id && searchQuery?.name) {
      setSelectedBrand({ name: searchQuery.name, id: searchQuery.id });
    }
    // eslint-disable-next-line
  }, [searchQuery]);

  useEffect(() => {
    if (id) {
      getRoleUsers()
    }
    // eslint-disable-next-line
  }, [query]);
  // ******** FOR ROLES USERS *********
  const rolesUserCols = [
    {
      field: 'name',
      headerName: 'Name',
      width: 350,
      renderCell: (params) => (
        <>
          <Link
            className='brand-name-Link text-truncate'
            to={`/users/${params.row.id}`}
            title={params.value}>
            {params?.row?.name ? params.row.name : <NoDataCell />}
          </Link>
          {params.row.isBrandAdmin ? (
            <Tooltip title='Brand Admin'>
              <AccountCircleIcon
                color='primary'
                className='ml-2'
                fontSize='small'
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      field: 'status',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      headerName: 'Status',
      width: 150,
      renderCell: (params) =>
        params.value === ACTIVE_STATUS ? (
          <Chip size='small' label={ACTIVE_STATUS} className='bg-primary' />
        ) : (
          <Chip size='small' label={INACTIVE_STATUS} className='bg-danger' />
        ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 350,
      renderCell: (params) =>
        params.value ? (
          <p className='text-truncate' title={params.value}>
            {params.value}
          </p>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value && params.value.user ? (
          <h5 className='createBy'>
            {params.value.user.firstName}
            <span
              className='createdAtTime'
              title={`${params.value.user.firstName} • ${moment(
                params.value.date.slice(0, 10),
              ).format('MMM Do, YYYY')}`}>
              {moment(params.value.date.slice(0, 10)).format('MMM Do, YYYY')}
            </span>
          </h5>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'update',
      headerName: 'Updated By',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value && params.value.user ? (
          <h5 className='updateBy'>
            {params.value.user.firstName}
            <span
              title={`${params.value.user.firstName} • ${params.value.date}`}
              className='updatedAtTime'>
              {moment(params.value.date.slice(0, 10)).format('MMM Do, YYYY')}
            </span>
          </h5>
        ) : (
          <NoDataCell />
        ),
    },
  ];

  const handlePage = (params) => {
    if (query.page !== params.page) {
      setQuery((prevState) => ({ ...prevState, page: params.page }));
    }
  };
  const handlePageSize = (params) => {
    if (params.pageSize !== query.limit) {
      setQuery({ page: 0, limit: params.pageSize });
    }
  };
  const handleSortModelChange = (params) => {
    if (params?.sortModel && params.sortModel.length > 0) {
      let temp = { ...params.sortModel[0] };
      if (temp.field === 'name') {
        temp.field = 'firstName';
      }
      if (temp.field === 'status') {
        temp.field = 'blocked';
      }
      setQuery((prevState) => ({
        ...prevState,
        page: 0,
        sortBy: temp.field,
        orderBy: temp.sort,
      }));
    }
  };

  const onFilterChange = (params) => {
    if (
      params.filterModel.items[0].value ||
      params.filterModel.items[0].value === ''
    ) {
      let deepFilter: string;
      if (params.filterModel.items[0].columnField === 'name') {
        deepFilter = JSON.stringify([{ field: "firstName", term: params.filterModel.items[0].value }, { field: "lastName", term: params.filterModel.items[0].value }])
      }
      else {
        deepFilter = JSON.stringify([{ field: params.filterModel.items[0].columnField, term: params.filterModel.items[0].value }])
      }
      setQuery((prevState) => ({
        ...prevState,
        deepFilter
      }));
    }
    else {
      setQuery({ page: 0, limit: 25 });
    }
  }

  // ****** CREATE ROWS FOR ROLES USERS ********
  const getRolesUsersRow = (data) => {
    let rows = data?.map((u) => ({
      id: u._id,
      name: `${u.firstName} ${u.lastName}`,
      email: u.email,
      status: u.brandData?.blocked
        ? INACTIVE_STATUS
        : u.blocked
          ? INACTIVE_STATUS
          : ACTIVE_STATUS,
      createdBy: u.createdBy,
      updatedBy: u.updatedBy,
      isBrandAdmin: u.userType === userType.brandAdmin,
    }));
    setRolesUserRow(rows);
  };


  // ******** GET ALL USERS FOR SELECTED BRAND ********
  const getRoleUsers = () => {
    let searchParams: any = { ...query };
    const api = getSearchQuery(
      `/sa-user?filterById=[{"field": "role", "term": "${id}"}]`,
      searchParams,
    );
    axiosInstance()
      .get(api)
      .then(({ data: { data } }) => {
        getRolesUsersRow(data);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  // ****** GET SINGLE ROLE *********
  const getRoleById = (id) => {
    setLoadingRolesData(true);
    axiosInstance()
      .get(`/sa-role/${id}`)
      .then(({ data: { data } }) => {
        setValues({
          name: data.name,
          description: data.description,
        });
        setPermission(data?.permission);
        setField(data.field);
        setSelectedBrand({
          name: data?.brand?.companyName,
          id: data.brand._id,
        });
        setResource(data.resource);
        setLoadingRolesData(false);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
        setLoadingRolesData(false);
      });
  };





  // ******* HANDLE CLICK UPDATE ROLE **********

  const updateRole = () => {
    setUpdating(true);
    const updatedData = {
      _id: id,
      name: values.name,
      description: values.description,
      field,
      resource,
    };
    axiosInstance()
      .put('/sa-role', updatedData)
      .then(({ data }) => {
        toastConfig.setToastConfig({
          message: 'Successfully updated',
          type: 'success',
          open: true,
        });
        setUpdating(false);
      })
      .catch((err) => {
        setUpdating(false);
        toastConfig.setToastConfig(err);
      });
  };

  const handleDeleteRole = async () => {
    try {
      await axiosInstance().put('/sa-role/remove', {
        ids: [id],
      });

      history.push({
        pathname: '/global-roles',
      });
    } catch (error) {
      toastConfig.setToastConfig(error);
    }
    setShowConfirmBox(false);
  };

  const isEditDeleteDisable =
    [PERMISSION.superAdmin, PERMISSION.brandAdmin].indexOf(permission) >= 0;

  return (
    <>
      <Layout>
        <CustomBreadCrumbs
          routes={[routes.globalRoles, { title: values.name }]}
        />
        <CustomHeader
          fromRole={true}
          loading={loadingRolesData}
          heading={values?.name ?? ''}
          mainPoints={{ 'Brand Name': selectedBrand?.name ?? '' }}
          showHeading={true}>
          <DeleteButton
            text='Delete'
            disabled={isEditDeleteDisable || loadingRolesData}
            action={() => {
              setShowConfirmBox(true);
            }}
          />
        </CustomHeader>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Container>
              <BoxWithBorder styles={{ padding: '16px' }}>
                {loadingRolesData ? (
                  <div style={{ width: '90%' }}>
                    <Box display='flex' mb={2}>
                      <Skeleton width='50%' height={50} />
                      <Box mx={2} />
                      <Skeleton width='50%' height={50} />
                    </Box>
                    {[...Array(10).keys()].map((i) => (
                      <Fragment key={i}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                          <Box flex={0.6}>
                            <Skeleton variant='rect' width='50%' height={20} />
                          </Box>
                          <Box
                            flex={0.4}
                            display='flex'
                            justifyContent='space-between'>
                            <Skeleton variant='rect' width={20} height={20} />
                            <Skeleton variant='rect' width={20} height={20} />
                            <Skeleton variant='rect' width={20} height={20} />
                            <Skeleton variant='rect' width={20} height={20} />
                          </Box>
                        </div>
                        <Box mb={2} />
                      </Fragment>
                    ))}
                  </div>
                ) : (
                  <>
                    <Grid
                      container
                      spacing={1}
                      className={classes.roleHeadContainer}>
                      <Grid
                        item
                        md={6}
                        xs={12}
                        sm={12}
                        className={classes.headingGrid}>
                        <Tooltip title='Role Name' color='action'>
                          <InfoOutlined className={classes.info} />
                        </Tooltip>
                        <Typography className={classes.heading}>
                          Role Name*
                        </Typography>
                        <TextField
                          size='small'
                          required
                          fullWidth
                          className={classes.textFields}
                          placeholder='Role Name'
                          value={values.name}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              name: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                        sm={12}
                        className={classes.headingGrid}>
                        <Tooltip title='Role Name'>
                          <InfoOutlined className={classes.info} />
                        </Tooltip>
                        <Typography className={classes.heading}>
                          Role Description*
                        </Typography>
                        <TextField
                          size='small'
                          required
                          fullWidth
                          className={classes.textFields}
                          placeholder='Role Description'
                          value={values.description}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              description: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                    <Paper style={{ width: '100%' }}>
                      <TableContainer className={classes.container}>
                        <Table
                          stickyHeader
                          aria-label='roles'
                          className='roles-table'>


                          <TableBody>
                            {!field.length && !resource.length ? (
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Box
                                    textAlign='center'
                                    component='p'
                                    marginTop={1}>
                                    No Data
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ) : (
                              <RoleEngine
                                field={field}
                                resource={resource}
                                setField={setField}
                                setResource={setResource}
                                isDisable={isEditDeleteDisable ? true : false}
                              />
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>

                    <Box marginTop={2} display='flex' justifyContent='flex-end'>
                      {!isUpdating ? (
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={updateRole}
                          disabled={
                            isEditDeleteDisable
                              ? true
                              : values.name.trim() === '' ||
                              values.description.trim() === ''
                          }>
                          Update
                        </Button>
                      ) : (
                        <Box display='flex' alignItems='center'>
                          <CircularProgress />
                          <Box marginX={1} />
                          Updating role...
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </BoxWithBorder>
              <Box marginY={2} />
              <BoxWithBorder styles={{ padding: '10px', minHeight: '300px' }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Box display='flex'>
                      <Box padding='5px'>
                        <Typography variant='h6' color='primary'>
                          Users ({rolesUserRow.length})
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4} container justifyContent='flex-end'>
                    <IconButton
                      color='primary'
                      size='small'
                      onClick={() => setShowCreateUserDialog(true)}>
                      <ControlPointIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <div style={{ height: '300px' }}>
                  <DataGrid
                    components={{
                      Toolbar: DataGridCustomToolbar,
                    }}
                    loading={loadingRolesData}
                    columns={rolesUserCols}
                    rows={rolesUserRow}
                    disableSelectionOnClick
                    disableMultipleSelection
                    density='compact'
                    paginationMode='server'
                    pagination
                    onPageChange={handlePage}
                    onPageSizeChange={handlePageSize}
                    pageSize={query.limit}
                    page={query.page}
                    rowCount={rolesUserRow.length}
                    rowsPerPageOptions={[25, 50, 75]}
                    onSortModelChange={handleSortModelChange}
                    filterMode='server'
                    onFilterModelChange={onFilterChange}
                  />
                </div>
              </BoxWithBorder>
              {showConfirmBox ? (
                <ConfirmationDialog
                  open={showConfirmBox}
                  message={'Are you sure you want to delete role'}
                  onClose={() => {
                    setShowConfirmBox(false);
                    if (deleteRec?.id) setDeleteRec(undefined);
                  }}
                  onOk={handleDeleteRole}
                />
              ) : null}

              {showCreateUserDialog ? (
                <CreateUser
                  openDialog={showCreateUserDialog}
                  brand_id={selectedBrand?.id}
                  name={selectedBrand?.name}
                  role={id}
                  onClose={() => setShowCreateUserDialog(false)}
                  onSuccess={() => {
                    setShowCreateUserDialog(false);
                    getRoleUsers();
                  }}
                />
              ) : null}
            </Container>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default Roles;
