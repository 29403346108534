import { Button } from "@material-ui/core";
import {
  useAccount,
  useMsal
} from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { SiMicrosoftoffice } from "react-icons/si";

const LogIn = (props) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  return (
    <>
      <AuthenticatedTemplate>
        <p>{account?.name}</p>
        <Button
          fullWidth
          startIcon={<SiMicrosoftoffice />}
          variant="contained"
          className="logo-bg-color"
          onClick={() => instance.logout()}
        >
          Log Out
        </Button>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button
          startIcon={<SiMicrosoftoffice />}
          fullWidth
          variant="contained"
          className="logo-bg-color"
          onClick={() => instance.loginPopup()}
        >
          Office 365 Login
        </Button>
      </UnauthenticatedTemplate>
    </>
  );
};

export default LogIn;
