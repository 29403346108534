import { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';
import { Formik, Form } from 'formik';

import Loader from '../../components/Loader';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';

import {
  getObjKeys,
  getObjKeysWithValues,
  yupSchema,
} from '../../constants/helpers';
import InputField from '../../components/Helpers/InputField';
import axiosInstance from '../../axios/axiosInstance';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  container: {
    position: 'relative',
  },

  footer: {
    position: 'sticky',
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    zIndex: 100000,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CreateBrand({ openDialog, onClose, onSuccess }) {
  const toastConfig = useContext(CustomToastContext);
  const classes = useStyles();
  const elemRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [initialData, setInitialData] = useState({
    fields: [],
    values: {},
  });

  useEffect(() => {
    getAllFields();
    // eslint-disable-next-line
  }, []);

  const getAllFields = async () => {
    setLoading(true);
    try {
      const { data: brand } = await axiosInstance().get(
        `/sa-field?brand=new&resource=Brand`,
      );
      const { data: user } = await axiosInstance().get(
        `/sa-field?brand=new&resource=User`,
      );
      setBrandData(brand.data);
      setUserData(user.data);

      const initialFieldsData = [...brand.data, ...user.data];
      const initialValuesData = {
        ...getObjKeys('', brand.data),
        ...getObjKeys('', user.data),
      };

      setInitialData({
        fields: initialFieldsData,
        values: initialValuesData,
      });
      setLoading(false);
    } catch (error) {
      toastConfig.setToastConfig(error);
      setLoading(false);
    }
  };

  const handleSubmit = (values) => {
    setSubmitting(true);
    const brandValues = getObjKeysWithValues(values, brandData);
    const adminValues = getObjKeysWithValues(values, userData);

    const saBrand = {
      brandAdmin: adminValues,
      brand: brandValues,
    };

    axiosInstance()
      .post('/sa-brand', saBrand)
      .then(() => {
        setSubmitting(false);
        onSuccess();
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
        setSubmitting(false);
      });
  };

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      aria-labelledby='customized-dialog-title'
      onClose={onClose}
      open={openDialog}
      className={classes.container}>
      <CustomDialogHeader title='Create Brand' onClose={onClose} />
      {loading ? (
        <DialogContent
          style={{
            minHeight: '300px',
          }}>
          <Loader text='' style={{ marginTop: '100px' }} />
        </DialogContent>
      ) : (
        <Formik
          initialValues={initialData.values}
          validationSchema={yupSchema(initialData.fields)}
          onSubmit={handleSubmit}
          validateOnMount>
          {({ values, errors, setFieldValue, touched, submitForm }) => (
            <>
              <DialogContent ref={elemRef}>
                <Form autoComplete='off' autoCorrect='off' noValidate>
                  <p className='block-divider'>Brand Information</p>
                  <InputField
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    fieldsData={brandData}
                    size='small'
                    fullWidth
                  />
                  <p className='block-divider'>Admin Information</p>
                  <InputField
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    fieldsData={userData}
                    size='small'
                    fullWidth
                  />
                </Form>
              </DialogContent>

              <MuiDialogActions className={classes.footer}>
                <Button
                  disabled={isSubmitting}
                  variant='outlined'
                  color='primary'
                  onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    const err = Object.keys(errors);
                    if (err.length) {
                      const input = document.querySelector(
                        `input[name=${err[0]}]`,
                      );

                      input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start',
                      });
                    }
                    submitForm();
                  }}>
                  {isSubmitting ? <CircularProgress size={22} /> : 'Submit'}
                </Button>
              </MuiDialogActions>
            </>
          )}
        </Formik>
      )}
    </Dialog>
  );
}
