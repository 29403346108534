import React, { memo } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
function SearchBox({
  onSearch,
  value,
  size,
  width,
  placeholder,
  style,
  searchbox,
}) {
  return (
    <TextField
      style={{ width: width || '200px', ...style }}
      variant='outlined'
      placeholder={placeholder || 'Search'}
      type='search'
      size={size || 'small'}
      value={value}
      className={searchbox}
      onChange={onSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Search color='disabled' />
          </InputAdornment>
        ),
      }}
    />
  );
}

SearchBox.propTypes = {
  onSearch: PropTypes.func,
  value: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.any,
  placeholder: PropTypes.any,
  style: PropTypes.any,
  searchbox: PropTypes.any,
};

export default memo(SearchBox);
