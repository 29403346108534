import React from 'react';
import { Box } from '@material-ui/core';
import Layout from '../../components/Layout';
import BrandHeader from '../../components/BrandHeader';
function ServiceUiContainer(props) {
  return (
    <Layout>
      <Box component='div'>
        <BrandHeader
          total={3}
          active={3}
          heading='Services'
          // showHeading={false}
        ></BrandHeader>
      </Box>
    </Layout>
  );
}
export default ServiceUiContainer;
