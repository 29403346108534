import { createContext, useContext, useReducer, useEffect } from 'react';
import reducer, { initialState } from './reducer';
import { GetMe } from '../axios';
import {
  SET_USER,
  USER_LOADING,
  SET_SEARCHED_DATA,
  SET_SEARCH_VAL,
  SET_LOADING,
} from './actionTypes';

const StateContext = createContext(null);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      dispatch({ type: USER_LOADING, payload: true });
      GetMe()
        .then(({ data }) => {
          dispatch({ type: SET_USER, payload: data });
          dispatch({ type: USER_LOADING, payload: false });
        })
        .catch((err) => {
          localStorage.setItem('token', '');
          dispatch({ type: USER_LOADING, payload: false });
        });
    }
  }, []);

  const setSearchedData = (data) => {
    dispatch({
      type: SET_SEARCHED_DATA,
      payload: { ...data },
    });
  };
  const setGlobalSearchVal = (str) => {
    dispatch({
      type: SET_SEARCH_VAL,
      payload: str,
    });
  };
  const setLoadingState = (data) => {
    dispatch({ type: SET_LOADING, payload: data });
  };
  return (
    <StateContext.Provider
      value={{
        state,
        dispatch,
        setLoadingState,
        setSearchedData,
        setGlobalSearchVal,
      }}>
      {children}
    </StateContext.Provider>
  );
};

export const useData = () => useContext(StateContext);
