import axios from 'axios';
import { getSearchQuery } from '../services/util';

const BASE_URL =
  process?.env?.REACT_APP_API_URL || 'https://oms-backend.vebholic.com';

const api = () => {
  const token = localStorage.getItem('token');

  return axios.create({
    baseURL: BASE_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const UserLogin = async (inputData) => {
  const { data } = await axios.post(`${BASE_URL}/user/login`, inputData);
  return data;
};

export const GetMe = async () => {
  const { data } = await api().get('/user/me');
  return data;
};

export const GetBrands = async (params = {}) => {
  // let url = id ? `/sa-brand/${id}` : "/sa-brand";
  let url = '/sa-brand';
  url = getSearchQuery(url, params);
  const { data } = await api().get(url);
  return data;
};

export const GetEntityCount = async () => {
  const url = '/sa-entity/count';
  const { data } = await api().get(url);
  return data;
};

export const GetBrandById = async (id) => {
  const { data } = await api().get(`/sa-brand/${id}`);
  return data;
};

export const GetFields = async (url, id) => {
  const { data } = await api().get(`/sa-field?brand=${id}&resource=${url}`);
  return data;
};

export const GetAllRoles = async (id, params = {}) => {
  let url = id ? `/sa-role?brand=${id}` : `/sa-role`;
  url = getSearchQuery(url, params);
  const { data } = await api().get(url);
  return data;
};

export const GetAllRolesByType = async (type, id) => {
  let url = `/sa-role?${type}=${id}`;
  const { data } = await api().get(url);
  return data;
};

export const GetUserRoleUnion = async (id) => {
  let url = `/sa-user/union-role/${id}`;
  const { data } = await api().get(url);
  return data;
};

export const GetRoleById = async (id) => {
  const { data } = await api().get(`/sa-role/${id}`);
  return data;
};

export const GetEntities = async (id, params = {}) => {
  let url = id ? `/sa-entity?brand=${id}` : `/sa-entity`;
  url = getSearchQuery(url, params);
  const { data } = await api().get(url);
  return data;
};

export const GetEntityById = async (id) => {
  const { data } = await api().get(`/sa-entity/${id}`);
  return data;
};

export const GetUserById = async (id) => {
  const { data } = await api().get(`/sa-user/${id}`);
  return data;
};

export const GetUsers = async (id = null, params = {}) => {
  let url = id ? `/sa-user?brand=${id}` : `/sa-user`;
  url = getSearchQuery(url, params);
  const { data } = await api().get(url);
  return data;
};

export const GetUsersData = async (url) => {
  const { data } = await api().get(`/sa-user/${url}`);
  return data;
};

export const PostRoles = async (inputData) => {
  const { data } = await api().post('/sa-role', inputData);
  return data;
};

export const PostBrand = async (inputData) => {
  const { data } = await api().post('/sa-brand', inputData);
  return data;
};

export const PostEntity = async (inputData) => {
  const { data } = await api().post('/sa-entity', inputData);
  return data;
};

export const PostUsers = async (inputData) => {
  const { data } = await api().post('/sa-user', inputData);
  return data;
};

export const checkEmailExist = async (email) => {
  const { data } = await api().get(`/user/emailExist/${email}`);
  return data;
};

export const UpdateEntity = async (inputData) => {
  const { data } = await api().put('/sa-entity', inputData);
  return data;
};

export const UpdateBrand = async (inputData) => {
  const { data } = await api().put('/sa-brand', inputData);
  return data;
};

export const UpdateUser = async (inputData) => {
  const { data } = await api().put('/sa-user', inputData);
  return data;
};

export const UpdateRole = async (inputData) => {
  const { data } = await api().put('/sa-role', inputData);
  return data;
};

export const BlockUnblockBrands = async (inputData) => {
  const { data } = await api().put('/sa-brand/block', inputData);
  return data;
};

export const GetOrgCharts = async (brandId = null) => {
  if (!brandId) {
    brandId = '';
  }
  try {
    const { data } = await api().get(`/orgchart?brand=${brandId}`);
    return data;
  } catch (e) {
    return e;
  }
};
export const GetAllUsers = async (brandId) => {
  try {
    const { data } = await api().get(`${BASE_URL}/sa-user?brand=${brandId}`);
    return data;
  } catch (error) {
    return error;
  }
};
export const getSingleOrg = async (id) => {
  if (!id) {
    return;
  }
  try {
    let data = await api().get(`/orgchart/${id}`);
    return data;
  } catch (e) {
    return e;
  }
};
export const saveOrgChart = async (reqBody) => {
  try {
    let { data } = await api().post('/orgchart', reqBody);
    return data;
  } catch (error) {
    return error;
  }
};
