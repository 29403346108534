import { useState, useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import { getObjKeysWithValues } from '../../constants/helpers';
import FormTypes from '../Helpers/FormTypes';
import { object, string } from 'yup';

const useStyles = makeStyles(() => ({
  fieldText: {
    width: '100%',
    padding: 10,
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ededed',
    },
  },
}));

const UserSchema = object().shape({
  firstName: string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long')
    .required('First name is required'),
  lastName: string()
    .min(2, 'Too Short')
    .max(50, 'Too Long')
    .required('Last name is required'),
  email: string().email().required(),
});

const Details = (props) => {
  const classes = useStyles();
  const { user, fields, initialVals, setValues } = props;
  const [edit, setEdit] = useState(null);

  useEffect(() => {
    const vals = getObjKeysWithValues(user, fields);
    setValues(vals);

    return () => setValues(null);
    // eslint-disable-next-line
  }, []);

  const handleSave = (values) => {
    setValues(values);
    setEdit(null);
  };

  return (
    <>
      {initialVals && (
        <Formik
          initialValues={initialVals}
          validationSchema={UserSchema}
          validateOnChange={false}
          onSubmit={() => { }}>
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                {fields.map((field, i) => (
                  <Grid key={i} item xs={12} sm={6} md={6}>
                    <h4>{field.fieldLabel}</h4>
                    <Box display='flex' alignItems='center'>
                      {edit === field.fieldName ? (
                        <FormTypes
                          size='small'
                          fullWidth
                          values={values}
                          errors={errors}
                          touched={touched}
                          name={field.fieldName}
                          options={field.option}
                          type={field.type}
                          setFieldValue={setFieldValue}
                        />
                      ) : field.type === 'switch' ||
                        field.type === 'checkbox' ? (
                        <Box marginLeft={1}>
                          <FormTypes
                            size='small'
                            fullWidth
                            values={values}
                            errors={errors}
                            touched={touched}
                            name={field.fieldName}
                            options={field.option}
                            type={field.type}
                            onChange={(e) => {
                              setFieldValue(field.fieldName, e.target.checked);
                              setValues({
                                ...initialVals,
                                [field.fieldName]: e.target.checked,
                              });
                            }}
                          />
                        </Box>
                      ) : (
                        <Typography
                          className={classes.fieldText}
                          onClick={() => setEdit(field.fieldName)}
                          variant='body2'>
                          {values[field.fieldName]
                            ? values[field.fieldName]
                            : 'None'}
                        </Typography>
                      )}
                      {edit === field.fieldName ? (
                        <IconButton onClick={() => handleSave(values)}>
                          <Check />
                        </IconButton>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Details;
