import React, { useState, useCallback, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Boardbox } from './boardbox';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { CreateNew } from './createnew';

export const Boardlist = ({
  projectId,
  status,
  activity,
  fetchActivity,
  handleChangeStatus,
}) => {
  const ref = React.useRef(null);
  const [subActivity, setSubActivity] = useState([]);
  const [newOpen, setNewOpen] = React.useState(false);

  useEffect(() => {
    setSubActivity(activity);
  }, [activity]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = subActivity[dragIndex];
      setSubActivity(
        update(subActivity, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [subActivity],
  );

  const handelCreateNew = (status) => {
    setNewOpen(true);
  };

  // eslint-disable-next-line
  const [{}, drop] = useDrop({
    accept: ['move'],
    drop: (data: any) => {
      handleChangeStatus(data.id, status);
    },
  });
  drop(ref);

  return (
    <div ref={ref}>
      <Box height='100%'>
        {subActivity.map((element, index) => (
          <Boardbox
            projectId={projectId}
            data={element}
            key={element._id}
            id={element._id}
            index={index}
            moveCard={moveCard}
            fetchActivity={fetchActivity}
          />
        ))}
        <Box p={1}>
          <Button
            variant='outlined'
            size='small'
            color='primary'
            fullWidth
            startIcon={<AddIcon />}
            onClick={() => handelCreateNew(status)}>
            Create
          </Button>
        </Box>
        {newOpen && (
          <CreateNew
            open={newOpen}
            projectId={projectId}
            fetchActivity={fetchActivity}
            status={status}
            setOpen={setNewOpen}
          />
        )}
      </Box>
    </div>
  );
};
