import React, { useState, useEffect } from 'react';
import ColoumStatus from './coloumStatus';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { isMobile, isTablet } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Boardlist } from './boardlist';
import Layout from '../../../components/Layout';
import { GetActivity, UpdateActivity } from '../../../axios/project';
import { useHistory } from 'react-router-dom';
import { Activitydetail } from './activitydetail';
import Loader from '../../../components/Loader';
import { GetUsers } from '../../../axios';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import queryString from 'query-string';

export default function Board() {
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);
  const { projectId, activityId } = parsed;

  const [activity, setActivity] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [users, setUsers] = React.useState(null);

  useEffect(() => {
    fetchActivity();
    if (!users) {
      fetchUsers();
    }
    // eslint-disable-next-line
  }, [activityId]);

  const fetchActivity = async () => {
    await GetActivity(projectId, 'All')
      .then(({ data }) => {
        setActivity(data.activity);
        setProjectName(data.projectName);
      })
      .catch((err) => {});
  };

  const fetchUsers = async () => {
    await GetUsers()
      .then(({ data }) => {
        let userData = [];
        for (var value of data) {
          userData.push({
            userId: value._id,
            name: value.firstName + ' ' + value.lastName,
          });
        }
        setUsers(userData);
      })
      .catch((err) => {});
  };

  const handleChangeStatus = (activityId, status) => {
    const updatedState = _.map(activity, (stateItem) => {
      if (stateItem._id === activityId) {
        stateItem.status = status;
      }
      return stateItem;
    });
    setActivity(updatedState);
    UpdateActivity(activityId, { field: 'status', content: status })
      .then(({ data }) => {})
      .catch((err) => {});
  };

  const handelOpenRoadmap = () => {
    history.push({
      pathname: '/project/roadmap',
      search: '?projectId=' + projectId,
    });
  };

  return (
    <Layout>
      {activity ? (
        <Box mt={2} p={2} bgcolor='white'>
          <Box mb={1}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant='h6'>{projectName + ' Board'} </Typography>
              </Grid>
              <Grid xs={6} container justifyContent='flex-end'>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handelOpenRoadmap}>
                  Roadmap
                </Button>
              </Grid>
            </Grid>
          </Box>
          <DndProvider
            backend={isMobile || isTablet ? TouchBackend : HTML5Backend}>
            <Grid container>
              {ColoumStatus.map((data, index) => (
                <Box
                  key={index}
                  width={300}
                  height={window.innerHeight - 200}
                  mr={2}
                  display='block'
                  border={1}
                  borderColor='grey.300'
                  bgcolor='grey.200'>
                  <Box p={2}>
                    <Typography variant='subtitle2'>
                      {data.status.toUpperCase()}{' '}
                      {' (' +
                        activity.filter(function (o) {
                          return o.status === data.status;
                        }).length +
                        ')'}
                    </Typography>
                  </Box>
                  <Boardlist
                    status={data.status}
                    projectId={projectId}
                    activity={activity.filter(function (o) {
                      return o.status === data.status;
                    })}
                    fetchActivity={fetchActivity}
                    handleChangeStatus={handleChangeStatus}
                  />
                </Box>
              ))}
            </Grid>
          </DndProvider>
          {activityId !== undefined && (
            <Activitydetail
              users={users}
              projectId={projectId}
              activityId={activityId}
            />
          )}
        </Box>
      ) : (
        <Loader text='' />
      )}
    </Layout>
  );
}
