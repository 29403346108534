import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, CssBaseline } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
    // marginTop: theme.spacing(1),
    // minHeight: "calc(100vh - 65px)",
  },
}));

const Layout = (props) => {
  const classes = useStyles();
  const { children, styles, maxWidth, minHeight } = props;

  return (
    <>
      <CssBaseline />

      {!maxWidth ? (
        <Paper elevation={0} className={classes.root} style={{ ...styles }}>
          {children}
        </Paper>
      ) : (
        <Container
          className={classes.root}
          maxWidth={maxWidth}
          style={{
            minHeight: minHeight ? '100%' : 'calc(100vh - 50px)',
            ...styles,
          }}>
          {children}
        </Container>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.any,
};

export default Layout;
