import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { ExpandMore, Add, Delete } from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';

import Layout from '../../components/Layout';
import Container from '../../components/Container';
import BrandHeader from '../../components/BrandHeader';
import AlertDialog from '../../components/Helpers/AlertDialog';
import SearchBox from '../../components/Helpers/SearchBox';
import ConfirmationDialog from '../../components/Helpers/ConfirmationDialog';
import CreateBrand from './CreateBrand';
import NoDataCell from '../../components/Helpers/NoDataCell';
import { brandDetailPage } from '../../routes/brand';
import DataGridCustomToolbar from './../../components/Helpers/DataGridCustomToolbar';
import routes from './../../components/Helpers/Routes';
import CustomBreadCrumbs from './../../components/CustomBreadCrumbs';
import CreateUser from '../../Pages/Users/CreateUser';
import CreateRoleDialog from '../../Pages/Roles/CreateRoles';
import { SiBrandfolder } from 'react-icons/si';
import moment from 'moment';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import axiosInstance from '../../axios/axiosInstance';
import { getSearchQuery } from '../../services/util';
import './index.scss';

const INACTIVE_STATUS = 'Inactive';
const ACTIVE_STATUS = 'Active';

const BrandTypes = [
  {
    key: 'All',
    value: 1,
  },
  {
    key: 'Active',
    value: 2,
  },
  {
    key: 'Inactive',
    value: 3,
  },
];

let brandTimeout;
const Brands = () => {
  const toastConfig = useContext(CustomToastContext);
  const { state } = useLocation();
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [activeBrands, setActiveBrands] = useState(0);
  const [inactiveBrands, setInactiveBrands] = useState(0);
  const [allBrands, setAllBrands] = useState(0);
  const [dataRows, setDataRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedType, setSelectedType] = useState(1);

  const [checkAllBrands, setCheckAllBrands] = useState(false);
  const [query, setQuery] = useState({ page: 0, limit: 25 });
  const [renderCount, setRenderCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [
    activeInactiveBranchesDialogData,
    setActiveInactiveBranchesDialogData,
  ] = useState<{
    message: string;
    open: boolean;
    activate?: boolean;
  }>({
    message: null,
    open: false,
    activate: false,
  });
  const [searchVal, setSearchVal] = useState('');
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [singleBrandDelete, setSingleBrandDelete] = useState({
    id: null,
    show: false,
    companyName: '',
  });

  const [showCreateBrandDialog, setShowCreateBrandDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);
  const [showCreateRoleDialog, setShowCreateRoleDialog] = useState(false);

  useEffect(() => {
    let millisec = Object.keys(searchVal).length > 0 ? 600 : 5;
    if (brandTimeout) {
      clearTimeout(brandTimeout);
    }
    brandTimeout = setTimeout(() => {
      fetchBrands();
    }, millisec);
    // eslint-disable-next-line
  }, [searchVal]);

  useEffect(() => {
    if (renderCount > 0) {
      fetchBrands();
    } else setRenderCount((preCount) => preCount + 1);
    // eslint-disable-next-line
  }, [query, selectedType]);
  // ****** GET ALL BRANDS *******
  const fetchBrands = async () => {
    let searchParams: any = { ...query, filterBrands: selectedType };
    searchParams = searchVal
      ? { ...searchParams, search: searchVal }
      : searchParams;
    const url = getSearchQuery('/sa-brand', searchParams);
    setLoadingBrands(true);
    await axiosInstance()
      .get(url)
      .then(({ data: { data, count } }) => {
        getRowsData(_.cloneDeep(data));
        setRowCount(count)
        setLoadingBrands(false);
        setCheckAllBrands(false);
      })
      .catch((err) => {
        setLoadingBrands(false);
      });
  };

  useEffect(() => {
    fetchBrandsCount()
    // eslint-disable-next-line
  }, [rowCount]);

  const fetchBrandsCount = async () => {
    setLoadingBrands(true);
    await axiosInstance()
      .get(`/sa-brand/count`)
      .then(({ data: { activeBrand, inActiveBrand, count } }) => {
        setAllBrands(count);
        setActiveBrands(activeBrand);
        setInactiveBrands(inActiveBrand);
        setLoadingBrands(false);
      })
      .catch((err) => {
        setLoadingBrands(false);
      });
  };
  // ********* GET ALL ROWS DATA FOR BRANDS *******
  const getRowsData = (data) => {
    let rows = [];
    if (data) {
      rows = data.map((brand) => ({
        id: brand._id,
        isChecked:
          (state && state.brand._id === brand._id) ||
            (selectedBrand && selectedBrand._id === brand._id)
            ? true
            : false,
        companyName: brand.companyName,
        numOfUsers: brand.numOfUsers,
        numOfEntities: brand.numOfEntities,
        servicesAccess: brand.servicesAccess.join(', '),
        address: brand.address,
        status: brand.blocked ? INACTIVE_STATUS : ACTIVE_STATUS,
        createdBy: brand.createdBy,
        blocked: brand.blocked ? brand.blocked : false,
        updatedBy: brand.updatedBy,
      }));
    }
    setDataRows(rows);
  };

  /**
   * ADDITIONAL STUFF FOR INITIAL CHECKBOX SELECTION IN COLUMNs
   */

  const columns = [
    {
      field: 'isChecked',
      headerName: 'Checkbox',
      renderHeader: () => (
        <Checkbox
          color='primary'
          checked={checkAllBrands}
          onChange={(ev) => {
            setSelectedBrand(null);
            setCheckAllBrands(ev.target.checked);
            const gridData = dataRows;
            gridData.map((d) => {
              d.isChecked = ev.target.checked;
              return d;
            });
            setDataRows([...gridData]);
          }}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          color='primary'
          checked={params.value}
          onChange={(ev) => {
            const gridData = dataRows;
            const indexOfRecord = gridData.findIndex(
              (d) => d.id === params.row.id,
            );
            gridData[indexOfRecord].isChecked = ev.target.checked;

            setDataRows([...gridData]);

            const checkedRecords = gridData.filter((d) => d.isChecked === true);

            if (checkedRecords.length === gridData.length) {
              setCheckAllBrands(true);
            } else {
              setCheckAllBrands(false);
            }
          }}
        />
      ),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      width: 75,
    },
    {
      field: 'companyName',
      headerName: 'Brand Name',
      width: 250,
      renderCell: (params) => (
        <Link
          className='brand-name-Link text-truncate'
          to={`${brandDetailPage.path}/${params.row.id}`}
          title={params?.row?.companyName}>
          {params?.row?.companyName ? params.row.companyName : <NoDataCell />}
        </Link>
      ),
    },
    {
      field: 'numOfUsers',
      headerName: 'No. of Users',
      type: 'number',
      hide: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => <>{params?.value ?? <NoDataCell />}</>,
      width: 150,
    },
    {
      field: 'numOfEntities',
      headerName: 'No. of Entities',
      type: 'number',
      width: 150,
      hide: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => <>{params?.value ?? <NoDataCell />}</>,
    },
    {
      field: 'servicesAccess',
      headerName: 'Services',
      width: 150,
      renderCell: (params) => <>{params?.value ?? <NoDataCell />}</>,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value === ACTIVE_STATUS ? (
          <Chip size='small' label={ACTIVE_STATUS} className='bg-primary' />
        ) : (
          <Chip size='small' label={INACTIVE_STATUS} className='bg-danger' />
        ),
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 450,
      renderCell: (params) =>
        params.value && params.value ? (
          <p title={params.value} className='text-truncate'>
            {params.value}
          </p>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value && params.value.user ? (
          <h5 className='createBy'>
            {params.value.user.firstName}
            <span
              className='createdAtTime'
              title={`${params.value.user.firstName} • ${moment(
                params.value.date.slice(0, 10),
              ).format('MMM Do, YYYY')}`}>
              {moment(params.value.date.slice(0, 10)).format('MMM Do, YYYY')}
            </span>
          </h5>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value.user ? (
          <h5 className='updateBy'>
            {params.value.user.firstName}
            <span title={params.value.date} className='updatedAtTime'>
              {moment(params.value.date.slice(0, 10)).format('MMM Do, YYYY')}
            </span>
          </h5>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Tooltip title='Delete'>
          <IconButton
            aria-label='Delete'
            onClick={() => {
              setSingleBrandDelete({
                show: true,
                id: params.row.id,
                companyName: params.row.companyName,
              });
            }}>
            <Delete fontSize='small' color='error' />
          </IconButton>
        </Tooltip>
      ),
    },
  ];
  // ***** COLUMNS STUFF ENDS  ☝☝ THERE *****

  // ****** ACTIONS BUTTON STUFF *********
  const openActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActions = () => {
    setAnchorEl(null);
  };

  const activateInActivateBrands = (activate) => {
    const checkedRecordsBrandNames = dataRows
      .filter((d) => d.isChecked && d.blocked === activate)
      .map((m) => {
        return m.companyName;
      })
      .join(', ');

    if (activate) {
      const message = `You want to activate brands: ${checkedRecordsBrandNames} ?`;

      setActiveInactiveBranchesDialogData({
        message: message,
        open: true,
        activate: activate,
      });
    } else {
      const message = `You want to in-activate brands: ${checkedRecordsBrandNames} ?`;

      setActiveInactiveBranchesDialogData({
        message: message,
        open: true,
        activate: activate,
      });
    }
    setAnchorEl(null);
  };

  const onActiveInactiveAction = async (activate) => {
    // if (action) {
    let dataToSend = {};

    if (activate) {
      dataToSend = {
        brand: dataRows
          .filter((d) => d.isChecked && d.blocked === activate)
          .map((m) => {
            return m.id;
          }),
        blocked: false,
      };
    } else {
      dataToSend = {
        brand: dataRows
          .filter((d) => d.isChecked && d.blocked === activate)
          .map((m) => {
            return m.id;
          }),
        blocked: true,
      };
    }

    await axiosInstance()
      .put('/sa-brand/block', dataToSend)
      .then(() => {
        fetchBrands().then(() => {
          setActiveInactiveBranchesDialogData({
            message: null,
            open: false,
          });
        });
      })
      .catch((err) => {
        setActiveInactiveBranchesDialogData({
          message: null,
          open: false,
        });

      });
  };

  const handleSearch = (e) => {
    if (query.page !== 0) {
      setQuery((prevState) => ({ ...prevState, page: 0 }));
    }
    setSearchVal(e.target.value);
  };

  const handlePage = (params) => {
    if (query.page !== params.page) {
      setQuery((prevState) => ({ ...prevState, page: params.page }));
    }
  };
  const handlePageSize = (params) => {
    if (params.pageSize !== query.limit) {
      setQuery({ page: 0, limit: params.pageSize });
    }
  };
  const handleSortModelChange = (params) => {
    if (params?.sortModel && params.sortModel.length > 0) {
      let temp = { ...params.sortModel[0] };
      if (temp.field === 'status') {
        temp.field = 'blocked';
      }
      setQuery((prevState) => ({
        ...prevState,
        page: 0,
        sortBy: temp.field,
        orderBy: temp.sort,
      }));
    }
  };

  const handleDeleteBrand = async () => {
    setLoadingBrands(true);
    try {
      let { data } = await axiosInstance().put('/sa-brand/remove', {
        ids: dataRows.filter((d) => d.isChecked).map((m) => m.id),
      });
      setSelectedBrand(null);
      setLoadingBrands(false);
      toastConfig.setToastConfig({
        message: data.message,
        type: 'success',
        open: true,
      });
      fetchBrands();
      fetchBrandsCount();
    } catch (error) {
      setLoadingBrands(false);
      toastConfig.setToastConfig(error);
    }
    setShowConfirmBox(false);
  };

  const handleSingleDeleteBrand = async () => {
    setIsDeleting(true);
    setLoadingBrands(true);
    try {
      let { data } = await axiosInstance().put('/sa-brand/remove', {
        ids: [singleBrandDelete.id],
      });
      setSingleBrandDelete({ show: false, id: null, companyName: '' });
      setSelectedBrand(null);
      setLoadingBrands(false);
      setIsDeleting(false);
      toastConfig.setToastConfig({
        message: data.message,
        type: 'success',
        open: true,
      });
      fetchBrands();
      fetchBrandsCount()

    } catch (error) {
      setIsDeleting(false);
      setSingleBrandDelete({ show: false, id: null, companyName: '' });
      setLoadingBrands(false);
      toastConfig.setToastConfig(error);
    }
    setShowConfirmBox(false);
  };

  const onFilterChange = React.useCallback((params) => {
    if (
      params.filterModel.items[0].value ||
      params.filterModel.items[0].value === ''
    ) {
      const deepFilter = JSON.stringify([{ field: params.filterModel.items[0].columnField, term: params.filterModel.items[0].value }])
      setQuery((prevState) => ({
        ...prevState,
        deepFilter
      }));
    }
    else {
      setQuery({ page: 0, limit: 25 });
    }
  }, []);

  const handleBrandTypeSel = (filteredValue) => {
    setQuery((prevState) => ({ ...prevState, page: 0 }));
    setSelectedType(filteredValue);
  };
  const handleCloseCreateRole = () => {
    setShowCreateRoleDialog(false);
    fetchBrands();
  };
  return (
    <>
      {activeInactiveBranchesDialogData.open && (
        <AlertDialog
          open={activeInactiveBranchesDialogData.open}
          message={activeInactiveBranchesDialogData.message}
          onYes={() => {
            onActiveInactiveAction(activeInactiveBranchesDialogData.activate);
          }}
          onNo={() => {
            setActiveInactiveBranchesDialogData({
              message: null,
              open: false,
              activate: false,
            });
          }}
        />
      )}
      <Layout>
        <CustomBreadCrumbs routes={[routes.brand]} />

        <Box component='div'>
          <Container>
            <div className='header-panel'>
              <BrandHeader
                selectedType={selectedType}
                onTypeChange={handleBrandTypeSel}
                options={BrandTypes}
                total={allBrands}
                active={activeBrands}
                inactive={inactiveBrands}
                heading='Brands'
                //showHeading={false}
                icon={<SiBrandfolder className='headerLogo' />}>
                <div className='brandHeader'>
                  <SearchBox onSearch={handleSearch} value={searchVal} />
                  <div className='brandHeaderAddBtnActionBtnGroup'>
                    <Button
                      variant='contained'
                      className='brandHeaderAddBtn'
                      color='primary'
                      size='small'
                      onClick={() => {
                        setShowCreateBrandDialog(true);
                      }}
                      startIcon={<Add />}>
                      Add
                    </Button>

                    <Tooltip title={!dataRows.some((d) => d.isChecked) ? "Please select a brand" : ""}>
                      <span>
                        <Button
                          disabled={
                            !dataRows.some((d) => d.isChecked)
                          }
                          variant='outlined'
                          color='default'
                          size='small'
                          onClick={openActions}
                          className='brandHeaderActionBtn'
                          aria-controls='action-menu'>
                          Actions <ExpandMore />
                        </Button>
                      </span>
                    </Tooltip>

                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      id='action-menu'
                      open={Boolean(anchorEl)}
                      onClose={closeActions}>
                      <MenuItem
                        onClick={() => {
                          closeActions();
                          setShowConfirmBox(true);
                        }}>
                        Delete {dataRows.filter((d) => d.isChecked).length}{' '}
                        Brand(s)
                      </MenuItem>
                      <Tooltip title={dataRows.filter((d) => d.isChecked).length !== 1 ? "Please select only one brand" : ""}>
                        <span>
                          <MenuItem
                            disabled={
                              dataRows.filter((d) => d.isChecked).length !== 1
                            }
                            onClick={() => {
                              closeActions();
                              setShowCreateRoleDialog(true);
                            }}>
                            Create Role
                      </MenuItem>
                        </span>
                      </Tooltip>
                      <Tooltip title={dataRows.filter((d) => d.isChecked).length !== 1 ? "Please select only one brand" : ""}>
                        <span>
                      <MenuItem
                        disabled={
                          dataRows.filter((d) => d.isChecked).length !== 1
                        }
                        onClick={() => {
                          closeActions();
                          setShowCreateUserDialog(true);
                        }}>
                        Create User
                      </MenuItem>
                      </span>
                      </Tooltip>
                      <Tooltip title={!dataRows.some((d) => d.isChecked && d.blocked) ? "Please select a inactive brand" : ""}>
                        <span>
                      <MenuItem
                        disabled={
                          !dataRows.some((d) => d.isChecked && d.blocked)
                            
                        }
                        onClick={() => {
                          closeActions();
                          activateInActivateBrands(true);
                        }}>
                        Activate Brands &nbsp;{' '}
                        <Chip
                          size='small'
                          label={
                            dataRows.filter((d) => d.isChecked && d.blocked)
                              .length
                          }
                        />
                      </MenuItem>
                      </span>
                      </Tooltip>
                      <Tooltip title={!dataRows.some((d) => d.isChecked && !d.blocked) ? "Please select a active brand" : ""}>
                        <span>
                      <MenuItem
                        disabled={
                          !dataRows.some((d) => d.isChecked && !d.blocked)
                        }
                        onClick={() => {
                          closeActions();
                          activateInActivateBrands(false);
                        }}>
                        In-activate Brands &nbsp;{' '}
                        <Chip
                          size='small'
                          label={
                            dataRows.filter((d) => d.isChecked && !d.blocked)
                              .length
                          }
                        />
                      </MenuItem>
                      </span>
                      </Tooltip>
                    </Menu>
                  </div>
                </div>
              </BrandHeader>
            </div>

            <div className='listing-grid'>
              <DataGrid
                components={{
                  Toolbar: DataGridCustomToolbar,
                }}
                columnBuffer={2}
                loading={loadingBrands}
                rows={loadingBrands ? [] : dataRows}
                columns={columns}
                disableSelectionOnClick
                disableMultipleSelection
                paginationMode='server'
                pagination
                onPageChange={handlePage}
                onPageSizeChange={handlePageSize}
                pageSize={query.limit}
                page={query.page}
                rowCount={rowCount}
                rowsPerPageOptions={[25, 50, 75]}
                onSortModelChange={handleSortModelChange}
                density='compact'
                filterMode='server'
                onFilterModelChange={onFilterChange}
              />
            </div>
            {singleBrandDelete.show ? (
              <ConfirmationDialog
                open={singleBrandDelete.show}
                isSetDeleting={isDeleting}
                message={`Are you sure you want to delete brand(s): ${singleBrandDelete.companyName} `}
                onClose={() =>
                  setSingleBrandDelete({
                    id: null,
                    show: false,
                    companyName: '',
                  })
                }
                onOk={handleSingleDeleteBrand}
              />
            ) : null}
            {showConfirmBox ? (
              <ConfirmationDialog
                open={showConfirmBox}
                message={`Are you sure you want to delete selected brand(s)`}
                onClose={() => setShowConfirmBox(false)}
                onOk={handleDeleteBrand}
              />
            ) : null}
            {showCreateUserDialog && (
              <CreateUser
                openDialog={showCreateUserDialog}
                brand_id={dataRows.find((d) => d.isChecked).id}
                name={dataRows.find((d) => d.isChecked).companyName}
                role={null}
                onClose={() => setShowCreateUserDialog(false)}
                onSuccess={() => {
                  setShowCreateUserDialog(false);
                  fetchBrands();
                }}
              />
            )}
            {showCreateRoleDialog ? (
              <CreateRoleDialog
                isOpen={showCreateRoleDialog}
                onClose={() => setShowCreateRoleDialog(false)}
                brandId={dataRows.find((d) => d.isChecked).id}
                name={dataRows.find((d) => d.isChecked).companyName}
                onOk={handleCloseCreateRole}
              />
            ) : null}
          </Container>
        </Box>

        {showCreateBrandDialog && (
          <CreateBrand
            openDialog={showCreateBrandDialog}
            onClose={() => setShowCreateBrandDialog(false)}
            onSuccess={() => {
              setShowCreateBrandDialog(false);
              fetchBrands();
              fetchBrandsCount();
            }}
          />
        )}
      </Layout>
    </>
  );
};

export default Brands;
