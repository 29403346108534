import {
  SET_USER,
  USER_LOADING,
  SET_SEARCHED_DATA,
  SET_SEARCH_VAL,
  SET_LOADING,
} from './actionTypes';

export const initialState = {
  user: null,
  userLoading: false,
  globalSearchData: {},
  globalSearchVal: '',
  loadingState: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case USER_LOADING:
      return { ...state, userLoading: action.payload };
    case SET_SEARCHED_DATA:
      return { ...state, globalSearchData: action.payload };
    case SET_SEARCH_VAL:
      return { ...state, globalSearchVal: action.payload };
    case SET_LOADING:
      return { ...state, loadingState: action.payload };
    default:
      return state;
  }
};

export default reducer;
