import Box from '@material-ui/core/Box';

const Event = () => {
  return (
    <Box>
      <h5>Event</h5>
    </Box>
  );
};

export default Event;
