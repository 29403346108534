import { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  LinearProgress,
  Link as MuiLink,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { object, string } from 'yup';
import { Link } from 'react-router-dom';
import demoImg from '../../assets/clip-hardworking-man.png';
import { SVG } from '../../assets';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import axiosInstance from '../../axios/axiosInstance';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 55px)',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(10),
    },
  },
  formContainer: {
    textAlign: 'center',
    padding: theme.spacing(10, 5),
  },
  form: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
  },

  image: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      placeItems: 'center',
    },
  },
  fields: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
    background: theme.palette.secondary.main,
    color: '#fff',

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  bottomLinks: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  footer: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  logo: {
    width: "150px",
    height: "100%",
  },
}));

const LoginSchema = object().shape({
  email: string().email().required(),
});

const ForgetPassword = () => {
  const toastConfig = useContext(CustomToastContext);
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    axiosInstance()
      .post(`/user/forget-password`, {
        email: values.email,
      })
      .then(({ data }) => {
        setIsSubmitting(false);
        toastConfig.setToastConfig({
          message: data.message,
          type: 'success',
          open: true,
        });
      })
      .catch((err) => {
        setIsSubmitting(false);
        toastConfig.setToastConfig(err);
      });
  };

  return (
    <>
      <CssBaseline />
      <AppBar position='static'>
        <Toolbar>
          <Typography component='div' className={classes.title}>
            <img src={SVG('LogoNew')} className={classes.logo} width={160} alt='eQuit-T' />
          </Typography>
        </Toolbar>
      </AppBar>
      <Box className={classes.root}>
        <Container maxWidth='md'>
          <Paper elevation={1} className={classes.container}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.formContainer}>
                <h2>Enter Your Email</h2>
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={LoginSchema}
                  onSubmit={handleSubmit}>
                  {({ submitForm }) => (
                    <Form className={classes.form}>
                      <Field
                        className={classes.fields}
                        component={TextField}
                        name='email'
                        type='email'
                        label='Email'
                        variant='outlined'
                      />

                      {isSubmitting && <LinearProgress />}

                      <Button
                        variant='contained'
                        color='primary'
                        disabled={isSubmitting}
                        onClick={submitForm}>
                        Submit
                      </Button>
                    </Form>
                  )}
                </Formik>
                <Box className={classes.bottomLinks}>
                  <MuiLink to='/login' component={Link}>
                    Go To Login
                  </MuiLink>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className={classes.image}>
                <img
                  src={demoImg}
                  alt='illustration'
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <Box className={classes.footer}>
          <Typography variant='subtitle2' color='textSecondary'>
            Equipt &copy; 2022
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ForgetPassword;
