import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Layout from '../../components/Layout';
import { FaMailBulk } from 'react-icons/fa';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Tooltip,
  Input,
  TextField,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  Chip,
} from '@material-ui/core';
import ConfirmationDialog from '../../components/Helpers/ConfirmationDialog';
import { DataGrid } from '@material-ui/data-grid';
import DataGridCustomToolbar from '../../components/Helpers/DataGridCustomToolbar';
import CustomBreadCrumbs from '../../components/CustomBreadCrumbs';
import routes from '../../components/Helpers/Routes';
import axiosInstance from '../../axios/axiosInstance';
import moment from 'moment';
import { ExpandMore, Add, Delete } from '@material-ui/icons';
import { CustomDialogTransition } from '../../constants/helpers';
import { IoIosArrowDropdown } from 'react-icons/io';
import NoDataCell from '../../components/Helpers/NoDataCell';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';
import { getSearchQuery } from '../../services/util';
import { Dialog } from '@material-ui/core';
import { isMobile, isTablet } from 'react-device-detect';
import CreateEmail from '../../components/CreateEmail';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    ['@media (max-width: 960px)']: {
      display: 'none',
    },
  },
  menuButtonList: {
    alignItems: 'flex-start',
    padding: '1px',
  },
  links: {
    color: theme.palette.info.light, //  textDark
    fontSize: 15,
  },
  darkLinks: {
    color: theme.palette.info.dark, //  textDark
    fontSize: 15,
  },
  linkDivider: {
    backgroundColor: '#ffffff42', //  darkBg
    margin: '0 10px',
  },
  darkLinkDivider: {
    backgroundColor: 'grey', //  darkBg
    margin: '0 10px',
  },
  delBtn: {
    color: 'red',
  },
  expandIcon: {
    position: 'absolute',
    right: '0',
    color: 'white',
  },
}));
const BulkEmail = () => {
  const toastConfig = useContext(CustomToastContext);
  const { state } = useLocation();
  const [loadingMails, setLoadingMails] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [activeBrands, setActiveBrands] = useState(0);
  const [inactiveBrands, setInactiveBrands] = useState(0);
  const [allBrands, setAllBrands] = useState(0);
  const [dataRows, setDataRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedType, setSelectedType] = useState(1);
  const [checkAllBrands, setCheckAllBrands] = useState(false);
  const [query, setQuery] = useState({ page: 0, limit: 25 });
  const [renderCount, setRenderCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [
    activeInactiveBranchesDialogData,
    setActiveInactiveBranchesDialogData,
  ] = useState<{
    message: string;
    open: boolean;
    activate?: boolean;
  }>({
    message: null,
    open: false,
    activate: false,
  });
  const [searchVal, setSearchVal] = useState('');
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [singleMailDelete, setSingleMailDelete] = useState({
    id: null,
    show: false,
    subject: '',
  });
  const [selectedMails, setSelectedMails] = useState(null);
  const [checkAllMails, setCheckAllMails] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [fullScreen, setFullScreen] = useState(isMobile || isTablet);
  const [showCreateBrandDialog, setShowCreateBrandDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchBulkEmails();
  }, []);
  // ****** GET ALL BRANDS *******
  const fetchBulkEmails = async () => {
    const url = '/bulk-email';
    setLoadingMails(true);
    await axiosInstance()
      .get(url)
      .then(({ data: { data, count } }) => {
        getRowsData(data);
        setRowCount(count);
        setLoadingMails(false);
        setCheckAllMails(false);
      })
      .catch((err) => {
        setLoadingMails(false);
      });
  };
  const getRowsData = (data) => {
    let rows = [];
    if (data) {
      rows = data.map((item) => ({
        id: item._id,
        isChecked:
          (state && state.item._id === item._id) ||
          (selectedMails && selectedMails._id === item._id)
            ? true
            : false,
        subject: item.subject,
        brand: item.brand,
        to: item.to,
        cc: item.cc,
        message: item.message,
        attachments: item.attachments,
        createdBy: item.createdBy,
      }));
    }
    setDataRows(rows);
  };

  const handleCloseEmail = () => {
    setEmailOpen(false);
  };

  const handleCreateEmail = () => {
    setEmailOpen(true);
  };
  /**
   * ADDITIONAL STUFF FOR INITIAL CHECKBOX SELECTION IN COLUMNs
   */

  const columns = [
    {
      field: 'isChecked',
      headerName: 'Checkbox',
      renderHeader: () => (
        <Checkbox
          color='primary'
          checked={checkAllMails}
          onChange={(ev) => {
            setSelectedMails(null);
            setCheckAllMails(ev.target.checked);
            const gridData = dataRows;
            gridData.map((d) => {
              d.isChecked = ev.target.checked;
              return d;
            });
            setDataRows([...gridData]);
          }}
        />
      ),
      renderCell: (params) => {
        return (
          <Checkbox
            color='primary'
            checked={params.value}
            onChange={(ev) => {
              const gridData = dataRows;
              const indexOfRecord = gridData.findIndex(
                (d) => d.id === params.row.id,
              );
              gridData[indexOfRecord].isChecked = ev.target.checked;
              setDataRows([...gridData]);
              const checkedRecords = gridData.filter(
                (d) => d.isChecked === true,
              );
              if (checkedRecords.length === gridData.length) {
                setCheckAllMails(true);
              } else {
                setCheckAllMails(false);
              }
            }}
          />
        );
      },
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      width: 75,
    },
    {
      field: 'subject',
      headerName: 'Subject',
      renderCell: (params) =>
        params.value && params.value ? (
          <p title={params.value} className='text-truncate'>
            {params.value}
          </p>
        ) : (
          <NoDataCell />
        ),
      width: 150,
    },
    {
      field: 'brand',
      headerName: 'Brand',
      width: 200,
      renderCell: (params) => {
        let brandName = params.value.map((i) => i.optionLabel);
        return (
          <>
            {params.value && params.value ? (
              <p title={params.value} className='text-truncate'>
                {brandName.join(', ')}
              </p>
            ) : (
              <NoDataCell />
            )}
          </>
        );
      },
    },
    {
      field: 'to',
      headerName: 'To',
      width: 250,
      renderCell: (params) => {
        return (
          <>
            {params.value && params.value ? (
              <p title={params.value} className='text-truncate'>
                {params.value.join(', ')}
              </p>
            ) : (
              <NoDataCell />
            )}
            ,
          </>
        );
      },
    },
    {
      field: 'cc',
      headerName: 'Cc',
      width: 250,
      renderCell: (params) =>
        params.value && params.value ? (
          <p title={params.value} className='text-truncate'>
            {params.value.join(', ')}
          </p>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'message',
      headerName: 'Message',
      width: 250,
      renderCell: (params) =>
        params.value && params.value ? (
          <p title={params.value} className='text-truncate'>
            {params.value}
          </p>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 150,
      renderCell: (params) => {
        var date = new Date(params.value.date);
        var fdate =
          date.getFullYear() +
          '/' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + date.getDate()).slice(-2);

        return (
          <>
            {params.value && params.value.user ? (
              <h5 className='createBy'>
                {params.value.user.concatedName}
                <span className='createdAtTime' title={fdate}>
                  {fdate}
                </span>
              </h5>
            ) : (
              <NoDataCell />
            )}
          </>
        );
      },
    },
    {
      field: 'attachments',
      headerName: 'Attachments',
      width: 150,
      renderCell: (params) =>
        params.value && params.value ? (
          <p title={params.value} className='text-truncate'>
            {params.value}
          </p>
        ) : (
          <NoDataCell />
        ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Tooltip title='Delete'>
          <IconButton
            aria-label='Delete'
            onClick={() => {
              setSingleMailDelete({
                show: true,
                id: params.row.id,
                subject: params.row.subject,
              });
            }}>
            <Delete fontSize='small' color='error' />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const handlePage = (params) => {
    if (query.page !== params.page) {
      setQuery((prevState) => ({ ...prevState, page: params.page }));
    }
  };
  const handlePageSize = (params) => {
    if (params.pageSize !== query.limit) {
      setQuery({ page: 0, limit: params.pageSize });
    }
  };
  const handleSortModelChange = (params) => {
    if (params?.sortModel && params.sortModel.length > 0) {
      let temp = { ...params.sortModel[0] };
      if (temp.field === 'status') {
        temp.field = 'blocked';
      }
      setQuery((prevState) => ({
        ...prevState,
        page: 0,
        sortBy: temp.field,
        orderBy: temp.sort,
      }));
    }
  };
  const closeActions = () => {
    setAnchorEl(null);
  };
  const openActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSingleDeleteMail = async () => {
   setIsDeleting(true);
   setLoadingMails(true);
   try{
     let {data} = await axiosInstance().put('/bulk-email/remove', {
        ids: [singleMailDelete.id],
     })
     setSingleMailDelete({show:false,id:null, subject:''})
     setSelectedMails(null);
     setLoadingMails(false);
     setIsDeleting(false);
     toastConfig.setToastConfig({
       message:data.message,
       type:'success',
       open:true,
     });
     fetchBulkEmails()
   }catch(error){
     setIsDeleting(false);
     setSingleMailDelete({show:false, id: null , subject:''})
     setLoadingMails(false);
     toastConfig.setToastConfig(error);
   }
   setShowConfirmBox(false);
  };

  const handleDeleteMails =  async() => {
   setLoadingMails(true);
   try{
    let {data} = await axiosInstance().put('/bulk-email/remove', {
      ids:dataRows.filter((d)=> d.isChecked).map((m)=> m.id),
    });
    setSelectedMails(null);
    setLoadingMails(false);
    toastConfig.setToastConfig({
      message:data.message,
      type:'success',
      open:true,
    })
    fetchBulkEmails();
   }catch(error){
    setLoadingMails(false);
    toastConfig.setToastConfig(error);
   }
   setShowConfirmBox(false);
  }

  const onFilterChange = React.useCallback((params) => {
    if (
      params.filterModel.items[0].value ||
      params.filterModel.items[0].value === ''
    ) {
      const deepFilter = JSON.stringify([
        {
          field: params.filterModel.items[0].columnField,
          term: params.filterModel.items[0].value,
        },
      ]);
      setQuery((prevState) => ({
        ...prevState,
        deepFilter,
      }));
    } else {
      setQuery({ page: 0, limit: 25 });
    }
  }, []);

  return (
    <>
      <Layout>
        <Grid container className='headerbox'>
          <Grid item md={5} sm={11} xs={10}>
            <CustomBreadCrumbs routes={[routes.bulkEmail]} />
          </Grid>
        </Grid>
        <Box p={2} pt={0} bgcolor='white'>
          <Box>
            <Grid
              style={{ marginTop: '10px' }}
              container
              justifyContent='space-between'>
              <Box className='brandHeader ml-2'>
                <FaMailBulk className='headerLogo' />
                <span className='listingHeader'>Bulk Email</span>
              </Box>
              <Grid style={{ display: 'flex', gap: '5px' }}>
                <Tooltip
                  title={
                    !dataRows.some((d) => d.isChecked)
                      ? 'Please select a mail to delete'
                      : ''
                  }>
                  <span>
                    <Button
                      disabled={!dataRows.some((d) => d.isChecked)}
                      variant='outlined'
                      color='default'
                      size='small'
                      onClick={openActions}
                      className='mailHeaderActionBtn'
                      aria-controls='action-menu'>
                      Actions <ExpandMore />
                    </Button>
                  </span>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  id='action-menu'
                  open={Boolean(anchorEl)}
                  onClose={closeActions}>
                  <MenuItem
                    onClick={() => {
                      closeActions();
                      setShowConfirmBox(true);
                    }}>
                    Delete {dataRows.filter((d) => d.isChecked).length} Mail(s)
                  </MenuItem>
                </Menu>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={() => {
                    setEmailOpen(true);
                  }}>
                  Send
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <div className='listing-grid'>
          <DataGrid
            components={{
              Toolbar: DataGridCustomToolbar,
            }}
            columnBuffer={2}
            loading={loadingMails}
            rows={loadingMails ? [] : dataRows}
            columns={columns}
            disableSelectionOnClick
            disableMultipleSelection
            paginationMode='server'
            pagination
            onPageChange={handlePage}
            onPageSizeChange={handlePageSize}
            pageSize={query.limit}
            page={query.page}
            rowCount={rowCount}
            rowsPerPageOptions={[25, 50, 75]}
            onSortModelChange={handleSortModelChange}
            density='compact'
            filterMode='server'
            onFilterModelChange={onFilterChange}
          />
        </div>
        {singleMailDelete.show ? (
          <ConfirmationDialog
            open={singleMailDelete.show}
            isSetDeleting={isDeleting}
            message={`Are you sure you want to delete brand(s): ${singleMailDelete.subject} `}
            onClose={() =>
              setSingleMailDelete({
                id: null,
                show: false,
                subject: '',
              })
            }
            onOk={handleSingleDeleteMail}
          />
        ) : null}
        {showConfirmBox ? (
          <ConfirmationDialog
            open={showConfirmBox}
            message={`Are you sure you want to delete selected brand(s)`}
            onClose={() => setShowConfirmBox(false)}
            onOk={handleDeleteMails}
          />
        ) : null}
        <Dialog
          fullScreen={isMobile || isTablet}
          open={emailOpen}
          TransitionComponent={CustomDialogTransition}
          aria-labelledby='customized-dialog-title'
          maxWidth={'md'}
          onClose={() => handleCloseEmail()}
          fullWidth>
          {emailOpen ? (
            <CreateEmail
              handleCloseEmail={handleCloseEmail}
              isMinimized={!fullScreen}
              onMinimizeMaximize={() => {
                setFullScreen((prevState) => !prevState);
              }}
              showManimizeMaximize={true}
              emailId={null}
              fetchBulkEmails={fetchBulkEmails}
            />
          ) : null}
        </Dialog>
      </Layout>
    </>
  );
};

export default BulkEmail;
