import { useState, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Dialog,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form } from 'formik';

import { GetFields } from '../../axios';
import {
  getObjKeys,
  removeEmptyKeys,
  yupSchema,
} from '../../constants/helpers';
import InputField from '../../components/Helpers/InputField';
import Loader from '../../components/Loader';
import axiosInstance from '../../axios/axiosInstance';
import { CustomToastContext } from '../../StateProvider/CustomToastContext/CustomToastContext';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CreateUser({
  brand_id,
  name,
  role,
  openDialog,
  onClose,
  onSuccess,
}) {
  const toastConfig = useContext(CustomToastContext);
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useState(false);
  const [initialData, setInitialData] = useState({ fields: [], values: {} });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetFields('User', brand_id).then(({ data }) => {
      const newFields = [];
      data.map((_f) => newFields.push(_f.fieldData));

      setInitialData({
        fields: newFields,
        values: getObjKeys('', newFields),
      });
      setLoading(false);
    });
  }, [brand_id]);

  const handleSubmit = (values) => {
    setSubmitting(true);
    const newValues = {
      brand: brand_id,
      user: removeEmptyKeys(values),
    };
    if (role){
      newValues.user.role = [role]
    }
    axiosInstance()
      .post('/sa-user', newValues)
      .then(({ data }) => {
        setSubmitting(false);
        onSuccess();
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
        setSubmitting(false);
      });
  };

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      aria-labelledby='customized-dialog-title'
      onClose={onClose}
      open={openDialog}>
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant='h6'>Create User</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>

      {loading ? (
        <DialogContent
          style={{
            minHeight: '300px',
          }}>
          <Loader text='' style={{ marginTop: '100px' }} />
        </DialogContent>
      ) : (
        <Formik
          initialValues={initialData.values}
          validationSchema={yupSchema(initialData.fields)}
          onSubmit={handleSubmit}>
          {({ values, errors, setFieldValue, touched, submitForm }) => (
            <>
              <DialogContent
                dividers
                style={{
                  padding: '10px 20px',
                  minWidth: '50%',
                  minHeight: '300px',
                }}>
                <Typography>
                  <strong>Selected Brand: </strong>
                  {name}
                </Typography>
                <Form autoComplete='off' autoCorrect='off' noValidate>
                  <InputField
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    fieldsData={initialData.fields}
                    size='small'
                    fullWidth
                  />
                </Form>
              </DialogContent>

              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  variant='outlined'
                  color='primary'
                  onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant='contained'
                  color='primary'
                  onClick={submitForm}>
                  {isSubmitting ? <CircularProgress size={22} /> : 'Submit'}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      )}
    </Dialog>
  );
}
